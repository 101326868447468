import React from 'react';
import { createPortal } from 'react-dom';




// THIS IS A UNIVERSAL FUNCTION USED TO RENDER MODALS/POPUPS IN REACT USING 
// REACT'S 'createPortal' API saves the day allowing components to render 
// outside the root DOM or anywhere inside the root.
// THIS FUNCTION IS A HELPER OF 'Modal.jsx' and meant to be used with it.
function createWrapperAndAppendToBody(
  wrapperId, 
  appendToElID = null, 
  appendOrder = "after"
) {
  const wrapperElement = document.createElement("div");
  const appendCreatedEl = (appendOrder, elToAppendTo="body") => { 
    // If 'appendOrder' is before, append before all children
    if (String(appendOrder).toLowerCase() === "before") 
      document.querySelector(`${elToAppendTo}`).prepend(wrapperElement);
    // If 'appendOrder' is after, append after all children
    else if (String(appendOrder).toLowerCase() === "after") 
      document.querySelector(`${elToAppendTo}`).append(wrapperElement);
    // default to appending element after children
    else document.querySelector(`${elToAppendTo}`).append(wrapperElement);
  };

  if (wrapperId && typeof wrapperId === "string") wrapperElement.setAttribute("id", wrapperId);
  
  // If an element is named append to it based on order
  if (appendToElID || typeof appendToElID === "string") appendCreatedEl(appendOrder, appendToElID)
  // If no element is named append to, just append to the Body
  else appendCreatedEl(appendOrder, "body"); 
  
  return wrapperElement;
};


function ReactPortal({ 
  children, 
  appendToElID = null, 
  appendOrder="after", 
  id = "react-portal-wrapper" 
}) {
  const [wrapperElement, setWrapperElement] = React.useState(null);

  React.useLayoutEffect(() => {
    let element = document.getElementById(id);
    let systemCreated = false;
    // if element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(id, appendToElID, appendOrder);
    };
    // Keep track of element in dom by setting it to state
    setWrapperElement(element);

    return () => {
      // delete the programatically created element
      if (systemCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [id]);

  // wrapperElement state will be null on the very first render.
  if (wrapperElement === null) return null;
  
  return createPortal(children, wrapperElement);
};

export { 
  ReactPortal 
};
