import React from "react";
import { Link } from "react-router-dom";
import { handleDropdown, handleMobileDropdown } from "../../common/navbar";
import SVG_LOGO from "../Logo/svg-logo";
import NavNodes from "../../containers/Sections/Navigation/Menu/renderMenuNode";
import site_config from "../../../../config/site.config";




const orderOfTabs = site_config.orderOfNavTabs;

const Navbar = ({ nr, lr, pages, theme }) => {
  return (
    <nav
      ref={nr}
      className={`navbar change`}
      style={{ marginRight: "auto", marginLeft: "auto", display: "flex" }}
    >
      <div className="container navbar-inner mx-auto" data-bs-theme={theme}>
        <Link to="/" className="logo" style={{textDecoration: "none"}}>
          {/* <Xero0neSVG theme={theme}/> */} 
          <SVG_LOGO theme={theme} lightColor={"black"} darkColor={"white"} />
        </Link>

        <NavNodes 
          menuData={pages} 
          orderOfTabs={orderOfTabs}
          handleNavbarDropdown={handleDropdown}
        />
      </div>
    </nav>
  );
};

export default Navbar;
