import React from "react";
import "../../../../../assets/js/leaflet/leaflet.css";
import "../../../../../assets/js/leaflet/leaflet";




const LeafletMap = ({
    location = [40.892, -73.972],
    zoom = 12.15,
    scrollWheelZoom = false,
    options,
    regionCircleOptions
}) => {
    React.useEffect(() => {
        const map = L.map("map", {
            center: location,
            zoom: zoom,
            scrollWheelZoom: scrollWheelZoom,
            ...options
        });
        L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 19,
            attribution: "&copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
        }).addTo(map);
        const circle = L.circle(location, {
            ...regionCircleOptions
        }).addTo(map);
    }, [location, zoom, scrollWheelZoom, options, regionCircleOptions]);

    return (
       <div id="map" className="map"></div> 
    );
};

export default LeafletMap;
