import React from "react";
import getRandomInt from "../../common/getRandomNumber";
import { SwitchGridSize } from "../../../utils/Switches/grid-switch";
import Skeleton from "react-loading-skeleton";
import SkeletonWrapper from "./skeleton-wrapper";




// TODO make a component that differentiates from SkeletonCard and SkeletonPaginationCard 
const SkeletonCardBasic = ({index, grid=1}) => {
    let gridSize = SwitchGridSize(grid, null);
    return(
        <div
            className={`${gridSize} items graphic lg-mr wow fadeInUp`}
            data-wow-delay=".4s"
            key={index}
        >
            <div className="item-img">
                <div className="cont">
                    <h6>
                        <SkeletonWrapper>
                            <Skeleton containerClassName="skeleton-wrap" width={"40%"} height={"100%"}/>
                        </SkeletonWrapper>
                    </h6>
                    <span>
                        <SkeletonWrapper>
                            <Skeleton containerClassName="skeleton-wrap" width={`${getRandomInt(60, 80)}%`} height={"100%"}/>
                        </SkeletonWrapper>
                    </span>
                </div>
                
                <div className="rota">
                    <SkeletonWrapper>
                        <Skeleton containerClassName="skeleton-wrap" width={"100%"} height={"300px"} />
                    </SkeletonWrapper>
                    <div className="item-img-overlay"></div>
                </div>
        
                <div className="tags">
                    <span>
                        <SkeletonWrapper>
                            <Skeleton containerClassName="skeleton-wrap" width={`${getRandomInt(10, 15)}%`} height={"100%"}/>
                        </SkeletonWrapper>
                    </span>
                    <span>
                        <SkeletonWrapper>
                            <Skeleton containerClassName="skeleton-wrap" width={`${getRandomInt(10, 15)}%`} height={"100%"}/>
                        </SkeletonWrapper>
                    </span>
                    <span>
                        <SkeletonWrapper>
                            <Skeleton containerClassName="skeleton-wrap" width={`${getRandomInt(10, 15)}%`} height={"100%"}/>
                        </SkeletonWrapper>
                    </span>
                </div>
            </div>
        </div>

    );
}

export { 
    SkeletonCardBasic 
};