import React from "react";
import { SwitchGridSize } from "../../utils/Switches/grid-switch";
import { Modal } from "./Modal";
import { ModalNavBar } from "../components/Navbar/modal-navbar";




const CardContainer = ({ 
  data,
  index,
  theme,
  gridSize = null,
  CardComponent,
  PopUpComponent,
  ...props
}) => {
    let CardSizeClassName = SwitchGridSize(gridSize, data);
  
    const [clicked, setClicked] = React.useState(false);
  
    React.useEffect(() => {
    }, [clicked]);
  
    return(
      <>
        <div
          className={`${
            CardSizeClassName
          }${CardComponent.CardsClassName? " "+CardComponent.CardsClassName : ""}`}
          data-wow-delay=".4s"
          style={{
            visibility: "visible",
            animationDelay: "0.4s",
            animationName: "fadeInUp",
            padding: CardComponent?.CardPadding
          }}
          onClick={ (e) => setClicked(true) }
          { ...props }
        >
          { CardComponent
              ? CardComponent.jsx
                ? CardComponent.jsx({ data, index, ...CardComponent.CardProps })
                : null
              : null
          }
          { 
            // Is PopupComponent set
            PopUpComponent?
              // If jsx property is set else return null
              PopUpComponent.jsx?
                clicked && <Modal
                  theme={theme}
                  navbar={
                    <ModalNavBar theme={theme} />
                  }
                  mainJSX={PopUpComponent.jsx(data)} 
                  isParentClicked={clicked} 
                  // handleClose={(e) => setModalOpen(false)}
                  handleParentClicked={setClicked}
                  appendToElID={"#render-container-id"}
                  appendOrder={"after"}
                  containerWidth={PopUpComponent.width?? "100%"} 
                  containerHeight={PopUpComponent.height?? "100%"}
                  scrollLockOnOpen={true}
                  hideElmentsOnOpen={["#progress-scroll-to-top"]}
                />
              :
                null
            : 
              null 
          }
        </div>
      </>
    );
  };


  export { CardContainer };