import React from "react";
import { Title } from "../../../components/Parts/TextSections/title-component";
import AccordionLGiconCard from "../../../components/Accordion/accordion-lg-icon-component";




const SkillsDisplay = ({ data, theme, title, subtitle }) => {
  // const darkThemeEnabled = useSelector((state) => state.preferences.darkThemeEnabled);

  return (
    <section className="services box lficon section-padding position-re">
      <div className="container">
        <Title title={title} subtitle={subtitle}/>
        <div className="row">
          {data.MySkills.map((item, key) => (
            <AccordionLGiconCard key={key} data={item} index={key} theme={theme} />
          ))}
        </div>
      </div>
      {/* <div className="line bottom right"></div> */}
      {/* <CharsTick className={"binary-tick bottom right"} id={null} width={"65%"} fontSize={"11px"} theme={theme} char={"0"} />  */}
    </section>
  );
};

export default SkillsDisplay;
