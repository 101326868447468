import React from "react";
import { ArrayToHTMLlist } from "../../common/arrayToHTMLlist";




const AccordionBasic = ({ iconJSX, theme, data, isOpen, index }) => {
    // To render accordions closed on page load, ADD "collapsed" to button className and remove "show" from "accordian-body" parent - vis versa o render all accordions open.
    const [ isAccordionOpen, setAccordionOpen ] = React.useState(isOpen);
    return (
        <div className={`cont ${isOpen? "active" : ""}`} style={{ width: "100%" }}>
        
            { Array.isArray(data.content) &&  data.content.length > 1? 
                <div className="accordion accordion-flush" data-bs-theme={theme}>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id={`heading_${index}`}>
                            <button 
                                className="accordion-button collapsed" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target={`#collapse_${index}`} 
                                aria-expanded="false" 
                                data-bs-theme={theme} 
                                aria-controls={`collapse_${index}`}
                            >
                                {
                                    iconJSX && React.isValidElement(iconJSX) && iconJSX
                                }
                                <h6>{data.title}</h6>
                                
                            </button>
                        </h2>
                        <div 
                            id={`collapse_${index}`} 
                            className="accordion-collapse collapse" 
                            aria-labelledby={`heading_${index}`} 
                            data-bs-parent="#accordionExample"
                        >
                            <div className="accordion-body">
                                { ArrayToHTMLlist(data.content) }
                            </div>
                        </div>
                    </div>
                </div>
                :
                <>
                    <h6>{data.title}</h6>
                    <p>{data.content}</p>
                </>
            }
        </div>
    );
};

export { AccordionBasic };
