import React from "react";




const Title = ({ title, subtitle, wowDelay=.5, justifyContent="center", textAlign="center" }) => {
    return(
      <div className={`row ${justifyContent.toLowerCase() === "center"? "justify-content-center" : ""}`}>
        <div className="col-lg-8 col-md-10">
          <div className={`sec-head ${textAlign.toLowerCase() === "center"? "text-center" : ""}`}>
            {subtitle && 
                <h6 className="stit mb-30 wow fadeIn" data-wow-delay={`.${wowDelay}s`}>
                  <span className="left"></span>
                  { subtitle }
                  <span className="right"></span>
                </h6> 
            }
            {title && 
              <h3 className="wow color-font">
                { title }
              </h3>
            }
          </div>
        </div>
      </div>
    );
  };

export {
    Title
};