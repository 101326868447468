import React from "react";
import fadeWhenScroll from "../../../common/fadeWhenScroll";




const ParallaxHeader = ({ 
  sliderRef, 
  title, 
  subtitle, 
  backgroundWords,
  zIndex
}) => {
  React.useEffect(() => {
    fadeWhenScroll(document.querySelectorAll(".fixed-slider .capt .parlx"));
    fadeWhenScroll(document.querySelectorAll(".fixed-slider .capt .bactxt .full-width" ));
  }, []);
  return (
    <header
      ref={sliderRef}
      className="works-header fixed-slider hfixd valign sub-bg"
      style={{
        zIndex: zIndex? zIndex : null
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-9 static">
            <div className="capt mt-50">
              <div className="parlx text-center">
                <h1 className="color-font">{title}</h1>
                <p>
                  { subtitle } 
                </p>
              </div>
              {
                backgroundWords?  
                  <div className="bactxt custom-font valign">
                    <span className="full-width">{ backgroundWords }</span>
                  </div>
                :
                  null
              }
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default ParallaxHeader;
