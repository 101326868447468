import React from "react";




const Split = ({ children }) => {
    const target = React.createRef();
    const [ Splitting, setSplitting ] = React.useState();
    
    const split = (e) => {
        if (e.current) {
            setSplitting(e.current);
        }
    };

    React.useEffect( () => { 
        split(target);
    }, [Splitting] );
  
    
    return ( 
        <div ref={target}>{ children }</div> 
    );
    
};
  
export { 
    Split 
};
