import React from "react";




const AccordionFAQ = ({ 
    theme = null, 
    data, 
    index,
}) => {
    const [ isFAQopen, setFAQopen ] = React.useState(false);

    React.useEffect(() => {
    }, [isFAQopen]);

    return (
        <div
            className={`accordion-item item ${isFAQopen? "active" : ""} mb-30`}
            key={index}
            data-bs-theme={theme}
        >
            <h6 className="accordion-header title">
                <button 
                    className="accordion-button collapsed" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target={`#collapse-faq-accordion-${index+1}`} 
                    aria-expanded={isFAQopen}
                    aria-controls={`collapse-faq-accordion-${index+1}`}
                    onClick={() => {
                        isFAQopen?
                            setFAQopen(false)
                        :
                            setFAQopen(true)
                    }}
                >
                    <b>{ data.question }</b>
                </button>
            </h6>
            
            <div 
                id={`collapse-faq-accordion-${index+1}`} 
                className={`accordion-collapse collapse`}
            >
                <div className={`accordion-body accordion-info`}>
                    <p>
                        { data.answer }
                    </p>
                </div>
            </div>
        </div>
    );

};

export { AccordionFAQ };
