import React from "react";




export const ArrayToHTMLlist = (content) => {
    if(Array.isArray(content)) {
        const HTML = <ul>
            { 
                content.map( (item, key) => {
                    if(item && Array.isArray(item)) {
                        return (
                            <li key={key}>
                                <dl>
                                    <dt key={key}>{item[0]}</dt>
                                    {   
                                        item.map( 
                                            (j, jkey) => {
                                                if(jkey !==0 && j && Array.isArray(j)) {
                                                    return j.map( (k, kkey) => <dd key={kkey}>- {k}</dd> )
                                                } else if(jkey !==0 && j && !Array.isArray(j))  {
                                                    return ( <dd key={jkey}>- {j}</dd>)
                                                } else {
                                                    return null
                                                }
                                            } 
                                        )
                                    }
                                </dl>
                            </li>
                        ); 
                    } else {
                        return( 
                            <li key={key}><b>{ item }</b></li>
                        )
                    }
                    
                }) 
            }
        </ul>

        return (HTML);

    }
    else return (<p>{content}</p>);
};
