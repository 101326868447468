const SwiperPagination1 = (containerElement, bulletClassName, includeIndex=true) => { 
    return {
        el: containerElement,
        clickable: true,
        renderBullet: function (index, bulletClassName) {
            return `<li class="${bulletClassName}">${includeIndex? (index + 1):""}</li>`;
        }
    };
};

export default SwiperPagination1;
