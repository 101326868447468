import React from "react";
import "./Apps.css";
import { Provider as ReduxProvider } from "react-redux";
import store from "../redux/store";
import DarkThemeProvider from "./containers/Theme";
import Cursor from "./components/Cursor/cursor-component";
import ScrollToTop from "./components/ScrollToTop/scroll-to-top-component";
import ThemeToggleWidget from "./components/Toggles/theme-toggle-widget-component";
import site_data from "./site_data.json";




const App = () => {
  // const darkThemeEnabled = useSelector((state) => state.preferences.darkThemeEnabled);
  return (
    <ReduxProvider store={store}>
      <main className="main-content-container" id="main-content-container">
        <DarkThemeProvider site_data={site_data} app_version={process.env.APPLICATION_VERSION} />    
      </main>
      <Cursor />
      <ScrollToTop />
      <ThemeToggleWidget/>
    </ReduxProvider>
  )
};

export { App };
