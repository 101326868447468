import React from "react";




const TextSection1 = ({ textData, num }) => {
  return (
    <section className="intro-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <div className="htit">
              <h4>
                <span>0{num} </span> Introduction
              </h4>
            </div>
          </div>
          <div className="col-lg-8 offset-lg-1 col-md-8">
            <div className="text js-scroll__content">
              <p className="extra-text">
                {textData}
              </p>
              {/* 
              <ul className="smp-list mt-30">
                {data.spmList.map((item) => (
                  <li key={item.id}>{item.name}</li>
                ))}
              </ul> 
              */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { TextSection1 };
