import React from "react";




const FullScreenPopUp = ({
    theme,
    elements=[]
}) => {
    return(
        <div className="full-page-popup-wrapper">
            { 
                Array.isArray(elements) && elements.length > 0? 
                    elements.map((element, key) => { 
                        const Element = () => element;
                        return( 
                            <Element key={key} /> 
                        )
                    })
                :
                    null
            }
        </div>
    );
};

export { FullScreenPopUp };
