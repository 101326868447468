export function SwitchGridSize(gridSize, data) {
    let _CardSizeClassName;
    switch(gridSize) {
        // 12/12 = 1
        case 1:
            _CardSizeClassName = "col-lg-1 col-md-1 col-sm-12";
            break;
        // 12/6 = 2
        case 2:
            _CardSizeClassName = "col-lg-2 col-md-2 col-sm-12";
            break;
        // 12/4 = 3
        case 3:
            _CardSizeClassName = "col-lg-3 col-md-6 col-sm-12";
            break;
        // 12/3 = 4
        case 4:
            _CardSizeClassName = "col-lg-4 col-md-6 col-sm-12";
            break;
        // 12/2 = 6
        case 6:
            _CardSizeClassName = "col-lg-6 col-md-6 col-sm-12";
            break;
        // 12/1 = 12
        case 12:
            _CardSizeClassName = "col-lg-12 col-md-12 col-sm-12";
            break;
        default:
            _CardSizeClassName = Array.isArray(data)
            ? `col-lg-${data?.length?? (12 / data.length)} col-md-${data?.length?? (12 / data.length)} col-sm-12`
            : 12 
    };
    return _CardSizeClassName
};
