import React from "react";
import { handleAccordion } from "../../../common/handleAccordion";
import { AccordionFAQ } from "../../../components/Accordion/accordion-faq-component";
import { PaginatedDisplayComponent } from "../../../components/Displays/paginated-display-component";



const FaqServices = ({ 
  theme,
  title, 
  subTitle,
  description,
  CTAtext,
  FAQs,
  ...props 
}) => {
  return (
    <section className="app-faq section-padding" data-bs-theme={theme}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="content md-mb50">
              <h6 className="stit mb-30">
                <span className="left"></span> { title }<span className="right"></span>
              </h6>
              <h2 className="mb-30 color-font">
                { subTitle }
              </h2>
              <p>
                { description }
              </p>
              <a href="#0" className="butn-bord-red rounded buton mt-30">
                { CTAtext? 
                    ( <span>{CTAtext}</span> )
                  :
                    null
                }
              </a>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1">
            <div className="content">
              <div className="accordion shadwo">
                <PaginatedDisplayComponent
                  theme={theme}
                  content={FAQs}
                  skeletonLoader={false}
                  CardComponent={{
                    jsx: AccordionFAQ,
                    gridSize: 12,
                  }}
                  FilterComponent={{
                    filters: [
                      "Graphic Design", 
                      "UI Design", 
                      "Software Development",
                      "General"
                    ],
                    filterByKey: "category",
                    filtersColSize: 12,
                    searchFilterColSize: 12
                  }}
                  PageComponent={{
                    itemsPerPage: 4
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqServices;
