import React from "react";
import { Link } from "react-router-dom";
import { SkeletonCardBasic } from "../Skeletons/skeleton-card-basic-component";
import { titleCase } from "../../../utils/titleCase";
import { Title } from "../Parts/TextSections/title-component";
import { CardContainer } from "../../containers/CardContainer";




// ####################################### Start DISPLAY HELPERS ####################################### //
const DOTS = '...';

const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

// Algorithim to determine totalCount, PageSize, siblingCount, and currentPage
const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage
}) => {
  const paginationRange = React.useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
// ####################################### End DISPLAY HELPERS ####################################### //




// ####################################### Start SUB COMPONENTS ####################################### //
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div
      className={`pagination ${className}`}
    >
      <span
        className={`pagination-item ${currentPage === 1? 'disabled' : ''}`}
        onClick={onPrevious}
      >
        {/* <div className="arrow left" /> */}
        <i className="fas fa-angle-left"></i>
      </span>
      {
        paginationRange.map((pageNumber, key) => {
          if (pageNumber === DOTS) {
            return <span className="pagination-item dots">&#8230;</span>;
          }

          return (
            <span
              key={key}
              className={`pagination-item ${pageNumber === currentPage? 'selected' : ''}`}
              onClick={() => onPageChange(pageNumber)}
            >
              { pageNumber }
            </span>
          );
        })
      }
      <span
        className={`pagination-item ${currentPage === lastPage? 'disabled' : '' }`}
        onClick={onNext}
      >
        {/* <div className="arrow right" /> */}
        <i className="fas fa-angle-right"></i>
      </span>
    </div>
  );
};
// ####################################### End SUB COMPONENTS ####################################### //


// ####################################### Start MAIN DISPLAY COMPONENT ####################################### //
const DisplaySection = ({ 
  data, 
  theme, 
  pageSize, 
  grid,
  CardComponent, 
  PopUpComponent
}) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const PageArrayData = React.useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data]);

  return (
    <>
      <div className="d-lg-flex card-group">
        {
          PageArrayData.map( (item, key) => { 
            return (
              <CardContainer
                data={item}  
                key={key}
                index={key}
                theme={theme}
                gridSize={grid}
                CardComponent={{
                  jsx: CardComponent.jsx,
                  CardsClassName: CardComponent.CardsClassName?? ""
                }}
                PopUpComponent={{
                  jsx: PopUpComponent?.jsx?? null
                }}
              />
            ) 
          })
        }  
      </div>
      {
        Array.isArray(data) && data.length > pageSize 
        ?
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={data.length}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        :
          null
      }
    </> 
  );
};
// ####################################### End MAIN DISPLAY COMPONENT ####################################### //
const PaginatedDisplayComponent = ({
  content,
  theme=null,
  ContainerColSize,
  skeletonLoader=true,
  CardComponent,
  PopUpComponent,
  searchOption,
  FilterComponent=null,
  PageComponent
}) => {
  const defaults = {
    gridSize: 2,
    filters: ["open source", "application", "art/illustration"],
    filterComponentPosition: "center",
    itemsPerPage: 4,
    filterByKey: "name",
    searchFilterColSize: 4,
    filtersColSize: 8
  }
  
  // Render default props
  if (CardComponent) {
    /* Default gridSize */
    if (!CardComponent.gridSize) CardComponent.gridSize = defaults.gridSize;
  };
  if (FilterComponent) {
    /* Default filters */
    if (!FilterComponent.hasOwnProperty("filters")) FilterComponent.filters = defaults.filters;
    
    /* Default filters position */
    if (!FilterComponent.hasOwnProperty("filterPosition")) FilterComponent.filterPosition = defaults.filterComponentPosition;
    /* Default search param */
    if (!FilterComponent.hasOwnProperty("filterByKey")) FilterComponent.filterByKey = defaults.filterByKey;
    /*Default filter UI col sizes */
    if (!FilterComponent.hasOwnProperty("searchFilterColSize")) FilterComponent.searchFilterColSize = defaults.searchFilterColSize;
    if (!FilterComponent.hasOwnProperty("filtersColSize")) FilterComponent.filtersColSize = defaults.filtersColSize;
  }

  if (PageComponent) {
    /* Default items per page */
    if (!PageComponent.itemsPerPage) PageComponent.itemsPerPage = defaults.itemsPerPage;
  };

  const [pageLoaded, setPageLoaded] = React.useState(false);
  const [error, setError] = React.useState(null);

  // Query from user search input
  const [query, setQuery] = React.useState("");

  // Possible list of keys to match items to, all your possible tags will go here...
  const [searchParam] = React.useState([FilterComponent.filterByKey??defaults.filterByKey]);

  const [filterValues] = React.useState([]);
  const [filterParam, setFilterParam] = React.useState("*");
  const filterContainer = () => document.querySelector("#filters-container");
  
  // Icon size
  const iconSize = 50;

  React.useEffect(() => {
    if(filterContainer()) [...filterContainer().children].forEach((child) => filterValues.push(child.attributes["data-filter"].value));
  });

  // Search function
  function search(items) {
    const data = Object.values(items);

    return data.filter(
      (item) => {
        let value = item.Metadata ?? item
        // let value = item;
        if (FilterComponent.filterByKey && filterParam === value[FilterComponent.filterByKey].toString().toLowerCase()) {
            return searchParam.some((newItem) => {
              return (
                  value[newItem]
                      .toString()
                      .toLowerCase()
                      .indexOf(query.toLowerCase()) > -1
              );
            });
        } else if (filterParam === "*") {
          return searchParam.some((newItem) => 
            value[newItem]
            .toString()
            .toLowerCase()
            .indexOf(query.toLowerCase()) > -1
          
          );
        }
      }
    );
  };

  const getFilterValue = (e) => {
    // Get key attribute value and class
    const val = e.target.attributes["data-filter"].value;
    // Set new value
    setFilterParam(val);
  };

  React.useEffect(() => {
    const filterContainer = () => document.querySelector("#filters-container");
    if (filterContainer()) {
      const filtContainerChildren = [...filterContainer().children];
      // Make sure to uncheck everything else
      
      for(const node of filtContainerChildren) {
        if (node.attributes["data-filter"].value === filterParam) node.classList.add("active")
        else node.classList.remove("active");
      }
    }
  }, [filterParam]);

  return(
    <div 
      className={`${ContainerColSize
        ? Number.isInteger(ContainerColSize)
          ? `col-${ContainerColSize}` 
          : ContainerColSize 
        : "container-fluid"
      }`
      }
    >
      <div className="row">
        <div
          className={`filtering ${
            FilterComponent.filterPosition === "center"? 
              "text-center"
            : FilterComponent.filterPosition === "left"? 
                "text-left"
              : 
                "text-right"
          } col-12`}
        >
          <div className="col-lg-12">
            <div className="row">
              {/* Searcher UI */}
              {
                searchOption?
                  <div 
                    className={`basic-search-bar wrapper col-lg-${FilterComponent.searchFilterColSize} col-sm-12`} 
                    data-bs-theme={theme}
                  >
                      <div className="input-wrapper">
                        <input
                            type="search"
                            name="works-search-form"
                            id="works-search-form"
                            className="input"
                            placeholder="Search for..."
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            style={{ paddingRight: iconSize + 10 }}
                        />
                        <span 
                          className="basic-search-bar icon pe-7s-search" 
                          style={{ width: iconSize, height: iconSize }}
                        >
                        </span>  
                      </div>
                  </div>
                :
                  null
              }
              {/* Filters UI TOD MAKE THIS DYNAMIC WITH A MAP FUNCTION */}
              {
                FilterComponent.filters?
                  <div 
                    className={`basic-filter filter wow fadeIn col-lg-${FilterComponent.filtersColSize} col-sm-12`}
                    data-wow-delay=".5s" 
                    id={"filters-container"}
                  >
                    <span 
                      className="active" 
                      key={0} 
                      data-filter="*" 
                      data-name="filter-all"
                      onClick={getFilterValue}
                    >
                      All
                    </span>
                    { 
                      FilterComponent.filters.map((filter, key) => {
                        return(
                          <span 
                            className="" 
                            key={key+1} 
                            data-filter={String(filter).toLowerCase()}
                            data-name="filter-all"
                            onClick={getFilterValue}
                          >
                            { titleCase(String(filter)) }
                          </span>
                        )
                      }) 
                    }
                  </div> 
                :
                  null
              }

            </div>
              
          </div>
        </div>

        {/* <div className="gallery full-width d-lg-flex card-group" > */}
        <div className="gallery full-width">
          {
            content.length > 0? 
              <DisplaySection
                theme={theme}
                data={search(content)} 
                pageSize={PageComponent.itemsPerPage} 
                grid={CardComponent.gridSize}
                CardComponent={CardComponent}
                PopUpComponent={PopUpComponent} 
              />
            :
              skeletonLoader?
                <div className="d-lg-flex card-group">
                  { Array(PageComponent.itemsPerPage).fill().map((skeleton, key) => <SkeletonCardBasic key={key} grid={CardComponent.gridSize} index={key} />) }
                </div>
              :
                null
          }
        </div>
      </div>
    </div>
  );
};
  

export {
  PaginatedDisplayComponent
};
