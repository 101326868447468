import React from "react";
import Typewriter from "typewriter-effect";




const FreelancerHero = ({ theme, data, portrait_img }) => {
  return (
    <header className="freelancre valign col-lg-12">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="img">
              <img src={ portrait_img } alt=""  />
            </div>
          </div>
          <div className="col-lg-8">
            <div className="cont">
            <h1 className="cd-headline clip">
                { data.Name.toUpperCase() }
            </h1>
            <p className="cd-headline sub">Software Engineer / Data Engineer / Designer</p>
            <br/>
            <h6 className="cd-headline clip">
                Hello,
                <br/>
                <br/>
                {data?.BusinessDescription}&nbsp;
                  <span
                    // style={{ fontSize: "30px" }}
                    className="cd-words-wrapper"
                  >
                    <Typewriter
                      options={{
                        wrapperClassName: "saying-font fw-600",
                        strings: [
                          "Building Automation scripts",
                          "Architecting Software Systems",
                          "Building Web and Mobile Apps",
                          "Building Landing Pages",
                          "Creating Custom Digital Graphics",
                        ],
                        autoStart: true,
                        loop: true,
                      }}
                      onInit={(typewriter) => {
                        typewriter;
                      }}
                    />
                  </span>
                  .
              </h6>
              

            </div>
          </div>
          
        </div>

        <div className="states">
          <div className="container">
            <ul className="row">
              <li className="flex col">
                <div className="numb valign">
                  <h3>{ new Date().getFullYear() - ( Math.min(...[data.ProSEstart, data.FreelanSEstart]) )}+</h3>
                </div>
                <div className="text valign">
                  <p>
                    Years <br /> Of SE 👨🏽‍💻 Experience
                  </p>
                </div>
              </li>

              <li className="flex col">
                <div className="numb valign">
                  <h3>{ new Date().getFullYear() - data.FreelanDesignerStart }+</h3>
                </div>
                <div className="text valign">
                  <p>
                    Years <br /> Of Design 👨🏾‍🎨 Experience
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
         
      </div>
      {/* <div className="line bottom left"></div> */}
      {/* <CharsTick className={"binary-tick bottom left"} id={null} width={"65%"} fontSize={"11px"} theme={theme} char={"0"} />  */}
    </header>
  );
};

export default FreelancerHero;
