function checkFilesCount(files, max_count) {
    files = Array.from(files);
    return files.length > max_count? false : true; 
};

function checkIfFilesAreTooBig(files) {
    files = Array.from(files);
    return files.length > 0? files.some( (file) => (file.size / 1024 / 1024) > 2 ) : false;
};
  
function checkIfFilesAreCorrectType(files) {
    files = Array.from(files);
    return files.length > 0? files.some( (file) => ["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(file.type) ) : false;
};

export {
    checkFilesCount,
    checkIfFilesAreTooBig,
    checkIfFilesAreCorrectType
};
