import React from "react";
import { Link } from "react-router-dom";
import SVG_LOGO from "../Logo/svg-logo";
import { MapMarker, Mail, Call, LinkedIn, Github } from "../RawSVGs/IconsSpirits";




const Footer = ({ fr, data, hideBGCOLOR, theme, app_version }) => {
    return (
        <footer data-bs-theme={theme} className={`${!hideBGCOLOR ? "sub-bg" : ""}`} ref={fr}>
            <div className="container footer-meta-container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="item md-mb50">
                            <div className="title">
                                <h5>Contact Me</h5>
                            </div>
                            <ul>
                                <li>
                                    <span className="icon">
                                        <MapMarker theme={theme} lightcolor={"#fefefe"} darkcolor={"#fefefe"} />
                                    </span>
                                    <div className="cont">
                                        <h6>Area of Operation</h6>
                                        <p>{data?.BusinessAddress}</p>
                                    </div>
                                </li>
                                <li>
                                    <span className="icon">
                                        <Mail theme={theme} lightcolor={"#fefefe"} darkcolor={"#fefefe"} />
                                    </span>
                                    <div className="cont">
                                        <h6>Email Me</h6>
                                        <p>{data?.BusinessEmail}</p>
                                    </div>
                                </li>
                                <li>
                                    <span className="icon">
                                        <Call lightcolor={"#fefefe"} darkcolor={"#fefefe"} />
                                    </span>
                                    <div className="cont">
                                        <h6>Call Me</h6>
                                        <p>{data?.BusinessPhone}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 footer-logo-box">
                        <div className="item">
                            <div className="logo-social-pack">
                                <div className="logo" style={{ width: "300px" }}>
                                    <Link to="/">
                                        <SVG_LOGO theme={theme} link={"/"} />
                                    </Link>
                                </div>
                                <div className="social">
                                    <Link to="https://www.linkedin.com/in/chadgresham" target="_blank">
                                        <LinkedIn theme={theme} lightcolor={"#fefefe"} darkcolor={"#fefefe"} />
                                    </Link>
                                    <Link to="https://github.com/xero-one" target="_blank">
                                        <Github theme={theme} lightcolor={"#fefefe"} darkcolor={"#fefefe"} />
                                    </Link>
                                    {/* <Link to="#0" target="_blank">
                                        <i className="fab fa-instagram"></i>
                                    </Link> */}
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="sub-footer">
                <div className="container footer-copy-right-container">
                    <div className="row">
                        <div className="col-lg-12 copy-right">
                            <p>
                                © { new Date().getFullYear() }, Made with ❤️ by&nbsp;
                                <Link
                                    to="https://github.com/xero-one"
                                    target="_blank"
                                >
                                        XER0_0NE
                                </Link>
                            </p>
                            <p>
                                - A <span style={{color: "rgb(241, 194, 125)"}}><b>GroundZero_Tech()</b></span> product -
                            </p>
                        </div>
                        {
                            app_version?
                                <div className="col-lg-12 app-version">
                                    <p>
                                        version: {app_version}
                                    </p>
                                </div>
                            :
                                null
                        }
                        
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
