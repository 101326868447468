/* eslint-disable @next/next/no-img-element */
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import SwiperPagination1 from "../../../components/Parts/Pagination/swiper-paginations";




const PictureAndTestimonials = ({ theme, data, staticBackgroundImg }) => {
    const designerTotalYears = new Date().getFullYear() - data.FreelanDesignerStart;
    const softwareTotalYears = new Date().getFullYear() - ( Math.min(...[data.ProSEstart, data.FreelanSEstart]) );
    const totalYearsOfExp = designerTotalYears + softwareTotalYears;

    return (
        <section className="section-padding">
            <div className="testimonials block-sec">
                <div
                    className=""
                    style={{
                        display: "flex", 
                        justifyContent: "center", 
                        alignItems: "center"
                    }}
                    // data-overlay-dark="5"
                    id="testimonial-background"
                >
                    <div className="container">
                        <div className="row">
                            <div className="combined-exp-container col-lg-6 col-md-12 valign">
                                <div 
                                    className="img" 
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                >
                                    <div 
                                        className="bg-img bg-fixed hero-bg"  
                                        style={{
                                            backgroundImage: `url(${staticBackgroundImg})`,
                                            width: "100%",
                                            height: "100%"
                                        }}
                                    >  
                                    </div>
                                    <div className=""
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            position: "absolute",
                                            inset: "0 0 0 0",
                                            paddingTop: "50px",
                                            paddingRight: "50px",
                                            paddingBottom: "50px",
                                            paddingLeft: "50px"
                                        }}
                                    >
                                        <div 
                                            className="exp valign text-center" 
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                background: theme==="light"? "rgba(255, 255, 255, 0.9)" : "rgba(45, 45, 45, 0.9)"
                                            }}
                                        >
                                            <div className="full-width">
                                                <h2 
                                                    className="bg-img bg-fixed" 
                                                    data-background="assets/img/arch/hero.jpg" 
                                                    style={{
                                                        fontSize: "150px",
                                                        fontWeight: 700,
                                                        textAlign: "center",
                                                        margin: "auto",
                                                        lineHeight: 1
                                                    }}
                                                >
                                                    { totalYearsOfExp?? 1 }
                                                </h2>
                                                <p className="saying-font fw-600">Years of Combined Experience</p>
                                            </div>
                                        </div>    
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="col-lg-6 /*col-lg-5 offset-lg-1*/">
                                <div className="">
                                    <div className="sec-head">
                                        <h6 className="stit mb-30 wow fadeIn" data-wow-delay=".5s">
                                            <span className="left"></span>My Network<span className="right"></span>
                                        </h6>
                                        <h3 className="color-font wow fadeInLeft" data-wow-delay=".5s">
                                            What professionals said about me...
                                        </h3>
                                    </div>
                                    <Swiper 
                                        className="slic-item wow fadeInUp" 
                                        data-wow-delay=".5s"
                                        loop={true}
                                        pagination={SwiperPagination1(".slick-dots", "", false)}
                                        modules={[Autoplay, Pagination]}
                                    >
                                        { data?.ClientReviews.map((reviewer, key) => {
                                            return(
                                                <SwiperSlide key={key} className="testimonial-slide">
                                                    <p className="drop-caps-container">
                                                        <b className="drop-caps">''</b>
                                                    </p>
                                                    <p className="testimonials-comment">
                                                        <br />
                                                        <i>{reviewer.Comment}</i>
                                                    </p>
                                                    <div className="info">
                                                        {
                                                            reviewer.ProfessionalPic?
                                                                <div 
                                                                    className="img" 
                                                                    style={{
                                                                        display: "table-cell", 
                                                                        verticalAlign: "middle", 
                                                                        paddingLeft: "15px"
                                                                    }}
                                                                >
                                                                    <div 
                                                                        className="img-box"
                                                                    >
                                                                        <img src="assets/img/clients/1.jpg" alt="" />
                                                                    </div>
                                                                </div>
                                                            :
                                                                ""
                                                        }
                                                        <div 
                                                            className="cont"
                                                            style={{
                                                                display: "table-cell", 
                                                                verticalAlign: "middle",
                                                            }}
                                                        >
                                                            <div className="author">
                                                                <h6 className="author-name ">{reviewer.Name}</h6>
                                                                <span className="author-details">
                                                                    {reviewer.ProfessionalTitle}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )

                                        })  }
                                        <ul 
                                            className="cyber slick-dots" 
                                            data-bs-theme={theme}
                                            style={{ 
                                                display: "block",
                                                width: "100%",
                                                padding: 0,
                                                margin: 0,
                                                listStyle: "none",
                                                textAlign: "center"
                                            }}
                                        >
                                        </ul>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </section>
    );
};

export default PictureAndTestimonials;
