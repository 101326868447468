function handleFormData(data, returnAsObj=false) {
    const formData = new FormData();
    let obj = {};
    for (const key in data) {
        // Handle JavaScript FileList[] items 
        if (key.toLowerCase().includes("file")) {
            const fileList = Array.from(data[key]);
            fileList.forEach(file => formData.append(key, file));
        } else {
            formData.append(key, data[key]);
        };
        // Handle "desired service" Arrays and Objects
        if (key.toLowerCase().includes("service")) {
            if ( data[key].constructor.name === "Array" ) {
                const desired_services_values = data[key].map((v) => v["value"]).join(", ");
                formData.append(key, desired_services_values);
            } else if (data[key].constructor.name === "Object" && "value" in data[key]) {
                formData.append(key, data[key]["value"])
            } else {
                formData.append(key, data[key]);
            }
        };
    };
    if (returnAsObj) {
        for (const key of formData.keys()) {
            obj[key] = formData.getAll(key).length === 1? formData.getAll(key)[0] : formData.getAll(key);
        };
        return obj;
    }
    else return formData
};

export default handleFormData;
