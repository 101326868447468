// DO NOT TOUCH THIS INDEX FILE. 
// THIS INDEX FILE AUTO MAPS ALL PAGES FOR EXPORTING. THIS ALLOWS FOR DYNAMIC ROUTING.

const requireContext = require.context('./', true, /.*\.(jsx)$/);

let pages = {};

requireContext.keys().forEach(fileName => {
    // Ignore index.js | jsx files
    if (fileName === './index.jsx') return

    let name = fileName.replace(/(\.\/|\.jsx)/g, '');
    const Component = requireContext(fileName).default ?? requireContext(fileName)[name];
    pages[name] = Component;
});

module.exports = pages;
