import getSiblings from "./getSiblings";



const animateElement = (el, to) => {
  function draw() {
    el.style.left = to;
  }
  draw();
};


const delay = (time, callback) => {
  setTimeout(function () {
    callback
  }, time);
};

/*
const doIfOpen = (open, parentElement=null) => {
  if (open) {
    console.log("OPENING MENU")
    animateElement(document.querySelector(".hamenu"), "0px");
    document.querySelector(".topnav .menu-icon .text ").classList.add("open");
    // if (navDark) {
    //   navDark.classList.add("navlit");
    // }
    // if (logoChan) {
    //   logoChan.setAttribute("src", appData.lightLogo);
    // }
    // window.addEventListener("scroll", noScroll);
  } else {
    console.log("CLOSING MENU")
    delay(300, animateElement(document.querySelector(".hamenu"), "-100%"));
    document.querySelector(".topnav .menu-icon .text").classList.remove("open");
    
    // if (navDark) {
    //   navDark.classList.remove("navlit");
    // }
    // if (logoChan) {
    //   logoChan.setAttribute("src", appData.darkLogo);
    // }
    if (parentElement) {
      setTimeout(function () {
        parentElement.style.overflow = "auto"
      }, 425)
    }
    // window.removeEventListener("scroll", noScroll);
    
  }
};
*/

const doIfOpen = (open, parentElement=null) => {
  animateElement(document.querySelector(".hamenu"), "0px");
  document.querySelector(".topnav .menu-icon .text ").classList.add("open");
};

const doIfClose = (open, parentElement=null) => {
  delay(300, animateElement(document.querySelector(".hamenu"), "-100%"));
  document.querySelector(".topnav .menu-icon .text").classList.remove("open");

  if (parentElement) {
    setTimeout(function () {
      parentElement.style.overflow = "auto"
    }, 425)
  }
    // window.removeEventListener("scroll", noScroll);
};


const initFullNavbarMenu = ({ lockScroll, theme, isMenuOpen, setMenuOpenClosed }) => {
  function noScroll() {
    window.scrollTo(0, 0);
  };

  var themeListener = {
    aInternal: 10,
    aListener: function(val) {},
    set _theme(val) {
      this.aInternal = val;
      this.aListener(val);
    },
    get _theme() {
      return this.aInternal;
    },
    registerListener: function(listener) {
      this.aListener = listener;
    }
  }
  themeListener.registerListener(function(val) {
    if (document.querySelector(".main-menu").classList.contains("gosub")) {
      document.querySelector(".main-menu").classList.remove("gosub");
    }
  });
  themeListener._theme = theme;

  let open = isMenuOpen;

  let navDark = document.querySelector(".topnav.dark"),
    logoChan = document.querySelector(".topnav.dark .logo img"),
    menuIcon = document.querySelector(".topnav .menu-icon"),
    closeBtn = document.querySelector(".close-full-menu-btn");

  if (menuIcon) {
    menuIcon.addEventListener("click", function (e) {
      // open = true;
      setMenuOpenClosed(true);
      const parentTarget = this.closest(".custom-modal-content");
      if (lockScroll) {
        if (open) {
          parentTarget.style.overflow = "visible";
        }
      }
      if (closeBtn) {
        closeBtn.addEventListener("click", function() {
          // open = false
          setMenuOpenClosed(false);
          if(!open) {
            document.querySelector(".hamenu").classList.remove("open")
            if (lockScroll) { 
              doIfClose(open, parentTarget);
            } else {
              doIfClose(open);
            }
          }
          // document.querySelector(".hamenu").classList.toggle("open");
        })
      };
      document.querySelector(".hamenu").classList.add("open");
      doIfOpen(open);
    });
    
    document.querySelectorAll(".main-menu a").forEach((item) => {
      item.addEventListener("click", () => {
        delay(300, animateElement(document.querySelector(".hamenu"), "-100%"));
        document.querySelector(".topnav .menu-icon .text").classList.remove("open");
        if (navDark) {
          navDark.classList.remove("navlit");
        }
        if (logoChan) {
          logoChan.setAttribute("src", appData.darkLogo);
        }
        window.removeEventListener("scroll", noScroll);
      });
    });
  }

  document
  .querySelectorAll(".hamenu .menu-links .main-menu > li")
  .forEach((item) => {
    item.addEventListener("mouseenter", function () {
      this.style.opacity = "1";
      getSiblings(this).forEach((item) => {
        item.style.opacity = ".5";
      });
    });
    item.addEventListener("mouseleave", function () {
      document
        .querySelectorAll(".hamenu .menu-links .main-menu > li")
        .forEach((item2) => {
          item2.style.opacity = "1";
        });
    });
  });
  if (document.querySelectorAll(".main-menu > li .dmenu").length) {
    document.querySelectorAll(".main-menu > li .dmenu").forEach((item) => {
      item.addEventListener("click", function () {
        document.querySelector(".main-menu").classList.add("gosub");

        getSiblings(this.parentNode.parentNode).forEach((item) => {
          item.childNodes[1]
            ? item.childNodes[1].classList.remove("sub-open")
            : "";
        });
        this.parentNode.parentNode.childNodes[1].classList.add("sub-open");
      });
    });
  }
  if (
    document.querySelectorAll(".main-menu .sub-menu div .sub-link.back").length
  ) {
    document
      .querySelectorAll(".main-menu .sub-menu div .sub-link.back")
      .forEach((item) => {
        item.addEventListener("click", function () {
          document.querySelector(".main-menu").classList.remove("gosub");
          document.querySelector(".main-menu").classList.remove("sub-open");
        });
      });
  }
  
};
  
export default initFullNavbarMenu;