import React from "react";
import { Link } from "react-router-dom";
import { DynamicWrapper } from "../Wrapper/dynamic-wrapper";
import { pathSplit } from "../../../../utils/StringMethods/pathSplit";
import { titleCase } from "../../../../utils/titleCase";
import { AngleRight } from "../../RawSVGs/IconsSpirits";




const Breadcrumbs = ({ theme, crumbs, LinkJSX, CrumbJSX, allSiteUrls=[] }) => {
    crumbs = 
        crumbs?.length > 0 ?
            crumbs
        :
            crumbs = [ 
                window.location.host, 
                window.location.pathname 
            ]

    // Don't render a single breadcrumb.
    if (crumbs.length <= 1) {
        return null;
    };

    // Used to check which links are valid in compound links for example:  
    // '/contact/start-a-project' is a compound link format where 'contact' parent link can be a valid link
    // evalLinks checks if parent links are valid pages so we aren't linking to nonexistent pages in cumbs
    const evalLinks = (links) => {
        const home = window.location.host;
        const currentLocation = window.location.pathname;
        const validURLs = [];
    
        links.forEach(element => {
            element = element.split("/").map(( (el, idx) => idx > 0? `/${el}` : el ));
            // Filter all children URL parts and see if they are valid page links then append current page link
            element.filter(el => { 
                const index = allSiteUrls.indexOf(el);
                if (index !== -1) validURLs.push(el);
            });
        });
        // Append home/host URL to beginning of array
        validURLs.unshift(home);
        // Append current page location last
        validURLs.push(currentLocation);
        return validURLs;
    };

    const BREAD_CRUMBS = evalLinks(crumbs);

    return (
        <div className="BreadCrumbs">
            {/* Link back to any previous steps of the breadcrumb. */}
            {BREAD_CRUMBS.map((path, key) =>
                <React.Fragment key={key}>
                    <>
                        { 
                            React.isValidElement(LinkJSX)?
                                <DynamicWrapper component={LinkJSX}>
                                    { window.location.host === path? "Home" : titleCase(pathSplit(path.replaceAll("-", " ")).pop()) }   
                                </DynamicWrapper> 
                            :
                                <Link to={window.location.host === path? "/" : path}>
                                    { window.location.host === path? "Home" : titleCase(pathSplit(path.replaceAll("-", " ")).pop()) }
                                </Link>
                        }
                        { 
                            key !== BREAD_CRUMBS.length-1? 
                                (
                                    React.isValidElement(CrumbJSX)?
                                        <DynamicWrapper component={CrumbJSX}></DynamicWrapper> 
                                    :
                                        // <span className="icon pe-7s-angle-right"></span>
                                        <span className="icon">
                                            <AngleRight theme={theme} lightcolor={"#000000"} darkcolor={"#fefefe"}/>
                                        </span>
                                ) 
                            :
                                ("")
                        }
                    </>
                </React.Fragment>
            )}
        </div>
    );
  };

  export default Breadcrumbs;
