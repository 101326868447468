import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { TOGGLE_DARKTHEME } from "../../../redux/actions";
import { SunMoonSVG } from "../RawSVGs/SunMoon";




const ThemeToggleWidget = ( props ) => {
    const darkThemeEnabled = useSelector((state) => state.preferences.darkThemeEnabled);
    const dispatch = useDispatch();

    const { className, id, style, btnClassName, btnID, btnStyle, ...restProps } = props;

    const handelOnclick = (e) => {
        // flip current value
        e.preventDefault();
        dispatch({ type: TOGGLE_DARKTHEME });
    };

    const defaultStyles = {
        position: "fixed",
        bottom: process.env.NODE_ENV !== "production"? " 70px" : "15px",
        left: "15px",
        zIndex: 999999
    }
      
    return (
        <div 
            className={className?? "theme-toggle-widget"}
            id={id?? "theme-toggle-widget-id"}
            style={ style?? defaultStyles }
            { ...restProps }
        >
            <button 
                className={btnClassName?? "theme-toggle-btn"} 
                id={btnID?? "theme-toggle-btn-id"} 
                title="Toggles light & dark" 
                aria-label={darkThemeEnabled ? "dark" : "light"}
                aria-live="polite" 
                onClick={ handelOnclick }
                data-theme={ darkThemeEnabled ? "dark" : "light" }
            >
                <SunMoonSVG />
            </button>
        </div>
    );
};

export default ThemeToggleWidget;