import React from "react";
import { Link } from "react-router-dom";
import { titleCase } from "../../../../utils/titleCase";
// import { returnMediaMeta } from "../../../../utils/Schemas/returnMediaMeta";




const DetailsHero = ({ headerData }) => {
  return (
    <section
      className="page-header proj-det bg-img parallaxie valign"
      style={{ backgroundImage: `url(${headerData.img})` }}
      data-overlay-dark="4"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-9">
            <div className="cont">
              <h6>{headerData.subtitle}</h6>
              <h2>{headerData.title}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="item mt-30">
              <h6>Client</h6>
              <p>
                <Link href={headerData.websiteLink}>
                  { headerData.websiteLink }
                </Link>
              </p>
            </div>
          </div>
          <div className="col">
            <div className="item mt-30">
              {
                headerData.workPublishDate?
                  <>
                    <h6>Date</h6>
                    <p>{headerData.workPublishDate}</p>
                  </>
                : null
                
              }
            </div>
          </div>
          {/* 
          <div className="col">
            <div className="item mt-30">
              <h6>Categories</h6>
              <p>
                {headerData.categories.map((cat, index) => (
                  <Link href={cat.link} key={cat.id}>
                      {cat.name}
                      {
                        headerData.categories.length !== index + 1
                        ? " ,"
                        : ""
                      }
                  </Link>
                ))}
              </p>
            </div>
          </div>
          <div className="col">
            <div className="item mt-30">
              <h6>Tags</h6>
              <p>
                {headerData.tags.map((tag, index) => (
                  <Link href={tag.link} key={tag.id}>
                      {tag.name}
                      {headerData.tags.length !== index + 1 ? " ," : ""}
                  </Link>
                ))}
              </p>
            </div>
          </div> 
          */}
        </div>
      </div>
    </section>
  );
};

export { DetailsHero };
