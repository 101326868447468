import React from "react";
import mouseEffect from "../../common/mouseEffect";
import { MDNCharMandala } from "../RawSVGs/CharsMandala";




const Cursor = () => {
  React.useEffect(() => {
    mouseEffect();
  }, []);
  return (
    <>
      <div className="mouse-cursor cursor-outer">
        <MDNCharMandala forceColor={"#12c2e9"}/>
      </div>
      <div className="mouse-cursor cursor-inner"></div>
    </>
  );
};

export default Cursor;
