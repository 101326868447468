/* eslint-disable @next/next/no-img-element */
import React from "react";
import { Split } from "../../../components/Parts/Split";
import { thumparallax, thumparallaxDown } from "../../../common/thumparallax";
import { SkeletonResumeNode } from "../../../components/Skeletons/skeleton-resume-node-component";
import { downloadFile } from "../../../../utils/fileSaver";




function useScrollBarPosition() {
  const [top, setTop] = React.useState(0);
  const ref = React.useRef();

  React.useLayoutEffect(() => {
    function getTopPosition() {
      const topPosition = ref.current.scrollTop;
      setTop(topPosition);
    }
    ref.current.addEventListener('scroll', getTopPosition);
    getTopPosition();
    return () => ref.current.removeEventListener('scroll', getTopPosition);
  }, [top]);

  return [ref, top];
};


const ResumeStalkerButn = () => {
  const [ref, top] = useScrollBarPosition();
  // return React.useMemo(
  //   () => (
  //     <div className="special-sticky-scroll-container">
  //       <div id="resume-stalker-btn" 
  //         className={`btn`} 
  //         onClick={(e)=> downloadFile("/v2/content/download_cv")} 
  //         style={{
  //           paddingLeft: top===0 || top===100? "100px" : "0px",
  //           paddingRight: top===0 || top===100? "100px" : "0px"
  //         }}
  //         ref={ref}
  //       >
  //         <div className="icon col-2">
  //           <i className="ion-ios-download-outline"></i>
  //         </div>
  //         <div className="text col-10">
  //           <h6 className="saying-font">Download CV</h6>
  //         </div>
  //       </div>
  //   </div>
  //   ),
  //   [ref,top]
  // );
  return (
      <div className="special-sticky-scroll-container">
        <div id="resume-stalker-btn" 
          className={`btn`} 
          onClick={(e)=> downloadFile("/v2/content/download_cv")} 
          style={{
            paddingLeft: top===0 || top===100? "100px" : "0px",
            paddingRight: top===0 || top===100? "100px" : "0px"
          }}
          ref={ref}
        >
          <div className="icon col-2">
            <i className="ion-ios-download-outline"></i>
          </div>
          <div className="text col-10">
            <h6 className="saying-font">Download CV</h6>
          </div>
        </div>
    </div>
    )
}



const ResumeDisplay = ({ resumeContent, loaded }) => {
  React.useEffect(() => {
    setTimeout(() => {
      thumparallax();
      thumparallaxDown();
    }, 1000);

  }, [loaded]);
  const stickyClassName = "special-sticky-scroll-container";
  let mobileDataNameProp={className: stickyClassName};
  mobileDataNameProp["data-for"]="mobile";


  return (
    <section className="resume-section section-padding position-re">
      <div className="container">
        <div className="row" style={{display: "flex", flexDirection: "row"}}>
          <div className="resume-btn-container col-lg-3 col-md-3 order-2 order-md-1">

            <div className="special-sticky-scroll-container">
              <div id="resume-stalker-btn" style={{position: "sticky", top: "50%"}} className={`btn`} data-for="resume-content" onClick={(e)=> downloadFile("/v2/content/download_cv")}>
                <div className="icon col-2">
                  <i className="ion-ios-download-outline"></i>
                </div>
                <div className="text col-10">
                  <h6 className="saying-font">Download CV</h6>
                </div>
              </div> 
            </div>

          </div>

          <div id={"resume-content"} className="content-container col-lg-9 col-md-9 valign order-1 order-md-2" style={{ position: "relative" }}>
            
            <div className="special-sticky-scroll-container">
               <div id="resume-stalker-btn" style={{position: "sticky", top: "100%", transform: "translateY(-60px)"}} className={`btn`} data-for="resume-content" onClick={(e)=> downloadFile("/v2/content/download_cv")}>
                <div className="icon col-2">
                  <i className="ion-ios-download-outline"></i>
                </div>
                <div className="text col-10">
                  <h6 className="saying-font">Download CV</h6>
                </div>
              </div> 
            </div>
            <div className="content">
              <Split>
                <h4 className="wow words chars splitting color-font" data-splitting>
                    Professional Work Experience
                </h4>
              </Split>
              <>
                { 
                  resumeContent.length > 0?
                    resumeContent.map(((xp, key) => {
                        return (
                          <div 
                            key={key} 
                            id={key === 0? "first-resume-node-id": key === resumeContent.length-1? "last-resume-node-id" : null} 
                            className={`xp-node row`}  
                            data-resume-node-index={key}
                          >
                            <div className="xp col-md-12">

                              <div className="xp-node-bullet-tail col-2" style={{ justifyContent: resumeContent.length-1 === key? "flex-start" : "center" }} >
                                <div className="fancey-bullet-img default">{ `0${ (resumeContent.length+1 - key) - 1}` }</div>
                                {
                                  resumeContent.length-1 === key? 
                                    null
                                  :
                                    <div className="xp-node-tail row">
                                      <span className="xp-v-line col-lg-12"></span>
                                    </div>
                                }
                              </div>
                              <div className="xp-body col-10">
                                <div className="job-date">
                                  <h6>{xp[0]}</h6>
                                </div>
                                <div className="job-title">
                                    <h5>{xp[2]}</h5>
                                </div>
                                <p className="wow txt words chars splitting" data-splitting>
                                  {xp[1]}
                                </p>  
                              </div>

                            </div>
                            
                          </div>
                        )
                      }))
                  :
                    Array(8).fill().map(
                      (skeleton, key) => { 
                        return(
                          <SkeletonResumeNode 
                            key={key}
                            id={key === 0? "first-resume-node-id": key === 8-1? "last-resume-node-id" : null} 
                            index={key} 
                            nodeArr={Array(8)}
                          /> 
                        )
                      })
                }
              </>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="line bottom right"></div>
    </section>
  );
};

export default ResumeDisplay;
