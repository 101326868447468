import React from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import Breadcrumbs from "../components/Parts/BreadCrumbs/bread-crumbs";
import CustomizableHeader from "../components/Sections/Headers/customizable-header";
import GeneralContactForm from "../containers/Forms/general-contact-form";
import LeafletMap from "../components/Maps/Leaflet/general-leaflet-map";
import CustomReactVideoPlayer from "../components/Parts/ReactPlayer/react-video-player-component";




const Contact = (props) => {
    const darkThemeEnabled = useSelector((state) => state.preferences.darkThemeEnabled);
    const theme = darkThemeEnabled? "dark" : "light";
    const site_data = useOutletContext();
    const businessLAT = 40.892;
    const businessLONG = -73.972;
    const businessLocation = [businessLAT, businessLONG];
    
    const vidoeSrc = "assets/videos/terminal-action.mp4";

    const contactCardData = {
        title: "Contact Info.",
        contactSection: {
            title: "Let's Talk.",
            email: site_data.BusinessEmail,
            phone: site_data.BusinessPhone
        },
        addressSection: {
            title: "Area of Operation",
            address: site_data.BusinessAddress
        }
    };

    const DrawLeafletMap = ({ theme }) => (
        <section className="map-container" data-bs-theme={theme}>
            <LeafletMap 
                location={businessLocation}
                regionCircleOptions={{ 
                    color: "salmon",
                    fillColor: "#f03",
                    fillOpacity: 0.3,
                    radius: 2000 
                }}
            />
        </section>
    );
    
    const BackgroundVideo = <CustomReactVideoPlayer 
        videoPlayerProps={{ 
            url: vidoeSrc,
            loop: true, 
            width: "100%", 
            height: "auto",
            style: {
                display: "flex"
            }
        }} 
    />
            
    return (
        <>
            <CustomizableHeader 
                title={["Leave us a note or start a project with us"]}
                subTitle={
                    <div 
                        className="header-subtitle" 
                        data-bs-theme={theme} 
                    >
                        "Feel free to ask me any questions or let’s talk about a future collaboration."
                        <br/>
                        <Breadcrumbs theme={theme} allSiteUrls={props?.siteURLs}/>
                    </div>
                }
            />
            <GeneralContactForm 
                contactCardData={contactCardData} 
                data={site_data} 
                theme={theme} 
            />
            <DrawLeafletMap theme={theme} />
        </>
    );
};

export default Contact;
