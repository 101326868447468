import { pathSplit } from "./StringMethods/pathSplit";

// Insert path into directory tree structure:
// function insert(children = [], [head, ...tail]) {
//     let child = children.find(child => child.name === head);
//     if (!child) children.push(child = {name: head, children: []});
//     if (tail.length > 0) insert(child.children, tail);
//     return children;
// };

// let dirTree = paths
// .map(path => path.split('/').slice(1))
// .reduce((children, path) => insert(children, path), []);


function mapDirsToTree(pathsObj, includeComponent=false) {
    let paths;
    let urlValues;

    if (pathsObj.constructor.name === "Object") {
        paths = Object.keys(pathsObj);
        urlValues = Object.values(pathsObj);
    } else if (Array.isArray(pathsObj) && pathsObj.every(item => typeof item === "string")) {
        paths = pathsObj
    } else {
        return;
    };

    function insert(children = [], value = null, [head, ...tail]) {
        let child = children.find(child => child.name === head);
        let index = urlValues.indexOf(value);
        const isDirectory = [head, ...tail].length > 1? true : false;
        const isPage = [head, ...tail].length > 1? false : true;
        if (!child) children.push(child = {
            name: head,
            path: `/${ paths[index] === "Home"? "": paths[index].toLowerCase().replaceAll(" ", "-") }`, 
            ...includeComponent && { component: [head, ...tail].length > 1? null : value }, 
            is_dir: isDirectory,
            is_page: isPage,
            type: [head, ...tail].length > 1? "group" : "page",
            children: []
        });
        if (tail.length > 0) insert(child.children, value, tail);
        return children;
    };

    let position = 0;
    let dirTree = paths
    .map(path => { 
        const res = pathSplit(path).constructor.name === "Array"? 
            pathSplit(path)[0]===''? 
                pathSplit(path).slice(1)
            : 
                pathSplit(path)
        : 
            [ pathSplit(path) ];
        return res;
    })
    .reduce((children, path) => {
        const res = insert(children, urlValues === undefined? null : urlValues[position], path);
        position += 1;
        return res;
    }, []);

    return dirTree;
};

export default mapDirsToTree;
