import React from "react";
import { useSelector } from "react-redux";
import { PAGE_URLS } from "../routing/AppRoutes";
import Navbar from "../components/Navbar/navbar";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/footer-component";
import { useWindowSize } from "../common/useWindowSize";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";




export const backgroundColor = {
    light: "#fff",
    dark: "#2d2d2d",
};

export const textColor = {
    light: "#000",
    dark: "#fff",
};

const ThemeStyles = ({ theme }) => {
    let _theme = String(theme).toLowerCase();

    return {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: `${backgroundColor[_theme]}`,
        color: `${textColor[_theme]}`
    }
};

// This is a theme wrapper using redux in order to manipulate the css properties of the entire DOM. Used for toggling light and dark modes.
// Like the App.js module all elements and children included in this Wrapper will appear on all pages
const DarkThemeProvider = ({ children, site_data, app_version=null }) => {
    const darkThemeEnabled = useSelector((state) => state.preferences.darkThemeEnabled);
    const navbarRef = React.useRef(null);
    const footerRef = React.useRef(null);
    const logoRef = React.useRef(null);
    const screenSize = useWindowSize();
    const [ footerHeight, setFooterHeight ] = React.useState(0);

    React.useEffect(() => {
        var navbar = navbarRef.current;
        var footer = footerRef.current;

        if (window.scrollY > 300) {
            navbar.classList.add("nav-scroll");
        } else {
            navbar.classList.remove("nav-scroll");
        }
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                navbar.classList.add("nav-scroll");
            } else {
                navbar.classList.remove("nav-scroll");
            }
        });

        // if (screenSize) setFooterHeight(footer.offsetHeight);
        setFooterHeight(footer.offsetHeight);

    }, [navbarRef, footerRef, screenSize]);

    return (
        <div 
            className="theme-wrapper"
            id="theme-wrapper-id"
            theme={darkThemeEnabled ? "dark" : "light"} 
            style={ThemeStyles({ theme: darkThemeEnabled ? "dark" : "light" })}
        >
            <Navbar nr={navbarRef} lr={logoRef} pages={PAGE_URLS} theme={darkThemeEnabled ? "dark" : "light"} />
            
            <div 
                className="page-container container-fluid"
                id="page-container-id"
                data-bs-theme={ darkThemeEnabled ? "dark" : "light"} 
                style={{ 
                    marginBottom: footerHeight, 
                    padding: 0, 
                    zIndex: 1, 
                    backgroundColor: `${backgroundColor[darkThemeEnabled ? "dark" : "light"]}` 
                }}>
                {/* <Outlet context={URL_TREE} /> */}
                <Outlet context={site_data} />
            </div>

            <Footer fr={footerRef} data={site_data} theme={darkThemeEnabled ? "dark" : "light"} app_version={app_version} />
            <ToastContainer />
        </div>
    );
};

export default DarkThemeProvider;
