import React from "react";
import { ReactPortal } from "../common/ReactPortal";
import "../../styles/modal.css";




// UNIVERSAL MODAL CONTAINER USED TO RENDER CUSTOM POPUP CONTENT, (USED WITH )
function Modal({
    theme,
    mainJSX, 
    isParentClicked, 
    handleParentClicked,
    navbar = null,
    appendToElID = null,
    appendOrder = "after",
    containerWidth = "100%", 
    containerHeight = "100%",
    modalWidth = "75%",
    modalHeight = "100%",
    scrollLockOnOpen = true,
    hideElmentsOnOpen = null
}) {
    const [open, setOpen] = React.useState(isParentClicked);
    
    // X Button Style
    // const XbuttonStyles = {
    //     top: "16px",
    //     right: "-40px",
    //     border: "none",
    //     backgroundImage: "none",
    //     backgroundColor: "transparent",
    //     boxShadow: "none",
    //     padding: 0
    // };

    // Event listeners and controls
    React.useEffect(() => {
        if (open) { 
            // Modal transitions and effects (apply first before state change)
            if (scrollLockOnOpen) document.body.style.overflow = "hidden"
            if (hideElmentsOnOpen) hideElmentsOnOpen.forEach(element => document.querySelector(element).classList.add("trans-hidden"))
            if (document.querySelector(".custom-modal")) {
                document.querySelector(".custom-modal").classList.add("custom-modal-enter-done")
                document.querySelector(".custom-modal").classList.remove("custom-modal-exit") 
            }
            handleParentClicked(true);
        } else {
            // Modal transitions and effects (apply first before state change)
            if (scrollLockOnOpen) document.body.style.overflow = "auto"
            if (hideElmentsOnOpen) hideElmentsOnOpen.forEach(element => document.querySelector(element).classList.remove("trans-hidden"))
            if (document.querySelector(".custom-modal")) {
                document.querySelector(".custom-modal").classList.remove("custom-modal-enter-done")
                document.querySelector(".custom-modal").classList.add("custom-modal-exit") 
            }
            handleParentClicked(false);
        }
        const closeOnEscapeKey = e => e.key === "Escape" ? 
            (() => {
                // Modal transitions and effects (apply first before state change)
                if (scrollLockOnOpen) document.body.style.overflow = "auto"
                if (hideElmentsOnOpen) hideElmentsOnOpen.forEach(element => document.querySelector(element).classList.remove("trans-hidden"))
                if (document.querySelector(".custom-modal")) {
                    document.querySelector(".custom-modal").classList.remove("custom-modal-enter-done")
                    document.querySelector(".custom-modal").classList.add("custom-modal-exit")
                }
                handleParentClicked(false);
            })()
        : 
            null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
        
    }, [open, isParentClicked, handleParentClicked]);
    
  
    if (!isParentClicked) return null;

    return (
        <ReactPortal id={"react-portal-modal-container"} appendToElID={appendToElID} appendOrder={appendOrder}>
            <div className="custom-modal" style={{ width: containerWidth , height: containerHeight }}>
                <div className="custom-modal-container" style={{ width: modalWidth, height: modalHeight, position: "relative"}}>
                    <div className="custom-modal-content">
                        <div
                            id="navi"
                            className={`topnav ${theme ? (theme === "light" ? "light" : "dark") : ""}`}
                        >
                            <div className="container-fluid modal-header">
                                { navbar? navbar : null }
                                <button 
                                    id="modal-x-btn"
                                    onClick={(e) => {
                                        setOpen(false)
                                    }}
                                    className="custom-modal-x-btn"
                                >
                                    <svg
                                        viewBox="0 0 24 24" 
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="custom-modal-x-btn-icon"
                                    >
                                        <path 
                                            d="M11.8 11.8L4 4l7.8 7.8L4 19.6l7.8-7.8zm0 0l7.8 7.8-7.8-7.8L19.6 4l-7.8 7.8z" 
                                            stroke="currentColor" 
                                            strokeWidth="2" 
                                            strokeLinecap="round" 
                                            strokeLinejoin="round">
                                        </path>
                                    </svg>
                                </button>    
                            </div>
                        </div>
                        { mainJSX }
                    </div>
                </div>
            </div>
        </ReactPortal>
    );
};

export { Modal };
