import React from "react";
import { CardContainer } from "../../CardContainer";
import { CardIconBasic1, CardPricingBasic2} from "../../../components/Cards/cards-component";




const ServicesListBasic = ({ 
  ServicesData,
  PricesData,
  SectionTitle=null,
  ServicesTitle=null,
  PricesTitle=null,
  SecondarySubHeader=null,
  description=null,
  ServicesCardGrid=null,
  PricesCardGrid=null,
  ExtraComponents=null,
  ...props 
}) => {
  const className =  props?.className?? "";
  const cardClassName = props?.cardClassName?? "";

  return (
    <section className={className}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12">
            <div className="s-head section-title mb-80">
              { 
                SectionTitle?
                  <h6 className="stit header text-center mb-30">
                    <span className="left"></span> 
                      { SectionTitle }
                    <span className="right"></span>
                    </h6>
                :
                  ("")
              }
              { description?
                  <p className="description">
                    { description }
                  </p>
                :
                  ("")
              }
            </div>

            <div className="container mb-80">
              <div className="s-head mb-80">
                <h2 className="header color-font">
                  { ServicesTitle }
                </h2> 
              </div>
              <div className="row row-eq-height">
                {  
                  Array.isArray(ServicesData) && ServicesData.length > 0?
                    ServicesData.map((focusAttribute, key) => {
                      return (
                        <CardContainer 
                          data={{
                            title: focusAttribute.title,
                            icon: focusAttribute.icon,
                            description: focusAttribute.description
                          }}
                          index={key}
                          CardComponent={{
                            jsx: CardIconBasic1,
                            CardsClassName: cardClassName
                          }}
                          gridSize={12 / ServicesData.length}
                          key={key}
                        />
                      );
                    })
                  :
                    ("")
                }
              </div>
            </div>
          </div>
        </div>
        

        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12">
            <div className="s-head mb-80">
              <h2 className="header color-font">
                { PricesTitle }
              </h2>
            </div>

            <div className="col-lg-12">
              <div className="app-price">
                <div className="pric-tables">
                  <div className="row">
                    {
                      Array.isArray(PricesData) && PricesData.length > 0?
                        PricesData.map((_package, key) => {
                          return(
                            <CardContainer 
                              data={_package}
                              index={key}
                              CardComponent={{
                                jsx: CardPricingBasic2
                              }}
                              gridSize={12 / PricesData.length}
                              key={key}
                            />
                          );
                        })
                      :
                        ("")
                    }
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export { ServicesListBasic };
