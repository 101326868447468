import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
// My module imports
import site_config from "../../../../config/site.config";
import ReCAPTCHA from "react-google-recaptcha";
import handleFormData from "../../common/handleFormData";
import { ReactHookFormError } from "../../components/Parts/Errors/Forms/form-errors";
import { SUBMIT_SUCCESS_TOAST_THEME, SUBMIT_FAILURE_TOAST_THEME } from "../../components/component_configs/toast-configs";
import { StartAProjectFormSchema } from "../../../utils/Schemas/Yup/schemas";
import { FORM_INPUT_CONSTANTS } from "../../../utils/Schemas/Yup/constants";
import useRecaptcha from "../../../utils/googleReCaptcha";
import site_data from "../../site_data.json";




const formatSelectObjects = ({flatArr, key="key", value="value"}) => {
    let newArr = flatArr.map((item, idx) => {
        if (typeof item === "string") {
            const obj = { label: item, value: item };
            return obj;
        } else if (typeof item === "object") {
            const obj = { label: item[key], value: item[key] };
            return obj;
        }
        else return [];
    });
    return newArr;
};


const StartAProjectForm = ({ theme, formDescription, defaultService }) => {
    // Static constants
    const DESCRIPTION_MIN = FORM_INPUT_CONSTANTS.DESCRIPTION.min;
    const DESCRIPTION_MAX = FORM_INPUT_CONSTANTS.DESCRIPTION.max;
    const MESSAGE_MIN = FORM_INPUT_CONSTANTS.MESSAGE.min;
    const MESSAGE_MAX = FORM_INPUT_CONSTANTS.MESSAGE.max;
 
    const INDUSTRIES = site_data.BusinessIndustries;
    const SOFTWARE_PRICE_PACKAGES = site_data.BusinessServices.SOFTWARE_PRICE_PACKAGES;
    const DESIGN_PRICE_PACKAGES = site_data.BusinessServices.DESIGN_PRICE_PACKAGES;

    const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();
    // Dynamic constants
    const [ industrySelectMenuIsOpen, setIndustrySelectMenuIsOpen ] = React.useState(false);
    const [ serviceSelectMenuIsOpen, setServiceSelectMenuIsOpen ] = React.useState(false);
    const [ submitting, setSubmitting ] = React.useState(false);
    const [ services, setServices ] = React.useState([]);
    const {
        watch,
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(StartAProjectFormSchema()),
    });

    const onSubmit = (values, ms=500) => new Promise(
        (r) => {
            setTimeout(
                r(axios.post("/v2/contact/project-inquiry", 
                    values
                )), 
                ms
            );
        }
    ).then((res) => {
         toast(
            "Your Message has been successfully sent. I will contact you soon.", 
            SUBMIT_SUCCESS_TOAST_THEME({ theme: theme })
        ); 
        reset();
    })
    .catch((err) => { 
        if (err) {
            let toastTXT = null;
            const { 
                code: err_code, 
                config: err_config, 
                message: err_message, 
                name: err_name, 
                request: err_request, 
                response: err_response, 
                stack: err_stack 
            } = err;
            console.log(err);
            const { 
                data: err_res_data,
                status: err_res_status,
                statusText: err_res_statusText,
                headers: err_res_headers,
                config: err_res_config,
                request: err_res_request
            } = err_response;

            function parseDetails(details) {
                if (Array.isArray(details)) {
                    // errorTXT = details.length > 1? errorTXT + "S:" : errorTXT+":";
                    let TXTparts = [];
                    details.forEach((_detail, idx) => {
                        let { ctx, loc, msg, type } = _detail;
                        type = type.toLowerCase();
                        loc = Array.isArray(loc) && loc.length > 1? `${(loc[loc.length-1]).replaceAll("_", " ")}` : "";
                        msg = msg?? "";
                        if( (type.includes("min") || type.includes("max")) && type.includes("length")) {
                            TXTparts.push(`"${loc}": ${msg[msg.length-1]==="."? msg: msg+"."}`);
                        };
                        if(type.includes("required")) {
                            TXTparts.push(`"${loc}":${msg[msg.length-1]==="."? msg: msg+"."}`);
                        };
                    });
                    // return errorTXTparts.join("\r\n");
                    return TXTparts;
                };
            };
            if (err_res_status === 422) {
                if ( (Object.keys(err_res_data)).includes("detail") ) {
                    const detail = err_res_data.detail;
                    toastTXT = parseDetails(detail);
                };
                Array.isArray(toastTXT)?
                    toastTXT.forEach(txt => toast(
                        "ERROR: \r\n" + txt, 
                        SUBMIT_FAILURE_TOAST_THEME({ theme: theme })
                    ))
                    :
                    toast(
                        toastTXT, 
                        SUBMIT_FAILURE_TOAST_THEME({ theme: theme })
                    )
                ;
            } else {
                toast(
                    "ERROR: \r\n" + err_message, 
                    SUBMIT_FAILURE_TOAST_THEME({ theme: theme })
                )
            };
        }
     });

    const INDUSTRIES_OPTIONS = formatSelectObjects({flatArr: INDUSTRIES, key: "label"});
    const allServices = SOFTWARE_PRICE_PACKAGES.concat(DESIGN_PRICE_PACKAGES, ["other"]);
    const SERVICES_OPTIONS = formatSelectObjects({flatArr: allServices, key: "title", value: "value"});
    React.useEffect(() => {
        const element = document.getElementById("sap-form");
        if (element && defaultService) {
            element.scrollIntoView({ behavior: "smooth" }) ;
        }
    }, [defaultService]);
    const defaultServiceChoice = defaultService ? SERVICES_OPTIONS.find((c) => c.value === defaultService) : SERVICES_OPTIONS[0];
    return (
        <section className="contact general-form section-padding" data-bs-theme={theme} id={"sap-form"}>
            <div className="container">
                <div className="row">
                    { formDescription && (<div className="form-description mb-80"><p>{formDescription}</p></div>) }
                    <div className="col-lg-12">
                        <div className="form md-mb50">
                            <form 
                                id="start-a-project-form" 
                                onSubmit={handleSubmit(async (data) => {
                                    setSubmitting(true);
                                    data["gcaptcha_token"] = capchaToken;
                                    data = handleFormData(data);
                                    await onSubmit(data);
                                    setSubmitting(false);
                                })}
                            >
                                <div className="controls">
                                    <div className="form-group row mb-15">
                                        <div className="form-group-child-wrapper col-lg-6 col-sm-12 sm-mb15">
                                            <input
                                                id="form_name"
                                                type="text"
                                                name="name"
                                                placeholder="Name*"
                                                defaultValue=""
                                                {...register("name")}
                                            />
                                            <ReactHookFormError 
                                                errorObj={errors.name} 
                                                rquiredTxt={"Your name is required"} 
                                                patternTxt={"Your email is weird please make sure it includes a '@'"}
                                            />
                                        </div>
                                        <div className="form-group-child-wrapper col-lg-6 col-sm-12">
                                            <input
                                                id="form_email"
                                                type="text"
                                                name="email"
                                                placeholder="Email*"
                                                defaultValue=""
                                                aria-invalid={errors.email? "true" : "false"}
                                                {...register("email")}
                                            />
                                            <ReactHookFormError 
                                                errorObj={errors.email} 
                                                rquiredTxt={"Your Email is required"} 
                                                patternTxt={"Your email is weird please make sure it includes a '@'"}
                                            />
                                        </div>                              
                                    </div>
                                    <div className="form-group mb-40">
                                        <input
                                            id="business_name"
                                            type="text"
                                            name="business_name"
                                            placeholder="Business Name*"
                                            aria-invalid={errors.business_name? "true" : "false"}
                                            {...register("business_name")}
                                        />
                                        <ReactHookFormError 
                                            errorObj={errors.business_name} 
                                            rquiredTxt={"Please provide your business name"} 
                                        />
                                    </div>
                                    <div className="form-group mb-40">
                                        <div className="input-group file-input-butn select">
                                            <label 
                                                className="input-group-text butn bord light"
                                                onClick={() => { 
                                                    serviceSelectMenuIsOpen?
                                                        setServiceSelectMenuIsOpen(false)
                                                    :
                                                        setServiceSelectMenuIsOpen(true)
                                                }}
                                            >
                                                Desired Service(s)
                                            </label>
                                            <Controller
                                                name="desired_service"
                                                control={control}
                                                defaultValue={defaultServiceChoice}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        placeholder={"Select Desired Service(s)"}
                                                        options={SERVICES_OPTIONS}
                                                        defaultValue={defaultServiceChoice}
                                                        value={SERVICES_OPTIONS.find((c) => c.value === value)}
                                                        isMulti={true}
                                                        onChange={onChange}
                                                        // menuIsOpen={serviceSelectMenuIsOpen}
                                                        menuIsOpen={serviceSelectMenuIsOpen}
                                                        onMenuOpen={() => setServiceSelectMenuIsOpen(true)}
                                                        onMenuClose={() => setServiceSelectMenuIsOpen(false)}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                            />
                                        </div> 
                                        <ReactHookFormError 
                                            errorObj={errors.desired_service} 
                                        />
                                    </div>
                                    <div className="form-group mb-40">
                                        <span 
                                            style={{
                                                color: watch("project_description") && watch("project_description").length > DESCRIPTION_MAX? "red" : ""
                                            }}
                                        >
                                            {watch("project_description")? watch("project_description").length : 0}/{DESCRIPTION_MAX}
                                        </span>
                                        <textarea
                                            id="project_description"
                                            name="project_description"
                                            placeholder="Project Description*"
                                            rows="2"
                                            defaultValue=""
                                            aria-invalid={errors.project_description? "true" : "false"}
                                            {...register("project_description")}
                                        />
                                        <ReactHookFormError 
                                            errorObj={errors.project_description} 
                                        />
                                    </div>
                                    <div className="form-group row mb-40">
                                        <div className="form-group-child-wrapper col-lg-3 col-sm-12 sm-mb15">  
                                            <input
                                                id="project_budget"
                                                type="number"
                                                name="project_budget"
                                                placeholder="Project Budget (estimate)"
                                                {...register("project_budget")}
                                                // defaultValue={1}
                                            />
                                            <ReactHookFormError 
                                                errorObj={errors.project_budget} 
                                            />
                                        </div>
                                        <div className="form-group-child-wrapper col-lg-4 col-sm-12 sm-mb40">
                                            <div className="special-input-component">
                                                <label className="">Project Timeline:</label>
                                                <Controller
                                                    control={control}
                                                    name="project_deadline"
                                                    render={({ field: { onChange, onBlur, value, ref } }) =>  (
                                                        <DatePicker
                                                            placeholderText="Select Desired Date"
                                                            onChange={(date) => onChange(date)}
                                                            selected={value}
                                                        />
                                                    )}
                                                    rules={{
                                                        required: false
                                                    }}
                                                />
                                            </div>
                                            <ReactHookFormError 
                                                errorObj={errors.project_deadline} 
                                            />
                                        </div>
                                        <div className="form-group-child-wrapper col-lg-5 col-sm-12">
                                            <div className="input-group file-input-butn select">
                                                <label 
                                                    className="input-group-text butn bord light" 
                                                    
                                                    onClick={() => { 
                                                        industrySelectMenuIsOpen?
                                                            setIndustrySelectMenuIsOpen(false)
                                                        :
                                                            setIndustrySelectMenuIsOpen(true)
                                                    }}
                                                >
                                                    Target Industry
                                                </label>
                                                <Controller
                                                    name="project_target_audience"
                                                    control={control}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            menuIsOpen={industrySelectMenuIsOpen}
                                                            onMenuClose={() => setIndustrySelectMenuIsOpen(false)}
                                                            onMenuOpen={() => setIndustrySelectMenuIsOpen(true)}
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            options={INDUSTRIES_OPTIONS}
                                                            value={INDUSTRIES_OPTIONS.find((c) => c.value === value)}
                                                            onChange={(val) => onChange(val.value)}
                                                            defaultValue={""}
                                                            placeholder={INDUSTRIES_OPTIONS[0].value}
                                                        />
                                                    )}
                                                    rules={{ required: true }}
                                                />
                                            </div>
                                            <ReactHookFormError 
                                                errorObj={errors.project_target_audience} 
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-40">
                                        <div className="input-group file-input-butn">
                                            <label className="input-group-text butn bord light" htmlFor="formFileMultiple">Add Files 🗂</label>
                                            <input
                                                className="form-control file-input shadow-none"
                                                id="formFileMultiple"
                                                type="file"
                                                name="concept_files"
                                                size="2"
                                                multiple
                                                {...register("concept_files")}
                                            />    
                                        </div>
                                        <ReactHookFormError 
                                            errorObj={errors.concept_files}
                                        />
                                    </div>
                                    <div className="form-group mb-40">
                                        <input
                                            id="how_you_found_us"
                                            type="text"
                                            name="how_you_found_us"
                                            placeholder="How did you find us?"
                                            aria-invalid={errors.how_you_found_us? "true" : "false"}
                                            {...register("how_you_found_us")}
                                        />
                                    </div>
                                    <div className="form-group mb-40">
                                        <span 
                                            style={{
                                                color: watch("message") && watch("message").length > MESSAGE_MAX? "red" : ""
                                            }}
                                        >
                                            {watch("message")? watch("message").length : 0}/{MESSAGE_MAX}
                                        </span>
                                        <textarea
                                            id="message"
                                            name="message"
                                            placeholder="Anything Else?"
                                            rows="4"
                                            defaultValue=""
                                            {...register("message")}
                                        />
                                        <ReactHookFormError 
                                            errorObj={errors.message}
                                        />
                                    </div>
                                </div>

                                <div className="form-group mb-40">
                                    <div className="captcha" style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>
                                        <ReCAPTCHA
                                            className={"g-recaptcha"}
                                            ref={recaptchaRef}
                                            sitekey={site_config.Google_ReCAPTCHA_site_key}
                                            onChange={handleRecaptcha}
                                        />
                                    </div>
                                </div>
                                
                                <input 
                                    value={submitting? "Submitting..." : "Submit"} 
                                    type="submit" 
                                    className="butn bord" 
                                    style={{display: "inline-block"}} 
                                    disabled={submitting || !capchaToken} 
                                />
                            </form>
                        
                            
                            {/* <h4 className="fw-700 color-font mb-50">Get In Touch.</h4> */}   
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    );
};

export default StartAProjectForm;
