const SUBMIT_SUCCESS_TOAST_THEME = ({ 
    theme="light",
    position="bottom-center",
    autoClose=6000,
    hideProgressBar=false,
    closeOnClick=true,
    pauseOnHover=true,
    draggable=false,
    progress=undefined,
    type="success",
    style={whiteSpace: "break-spaces"},
    ...props   
}) => ({
    theme: theme,
    position: position,
    autoClose: autoClose,
    hideProgressBar: hideProgressBar,
    closeOnClick: closeOnClick,
    pauseOnHover: pauseOnHover,
    draggable: draggable,
    progress: progress,
    type: type,
    style: style,
    ...props
});


const SUBMIT_FAILURE_TOAST_THEME = ({ 
    theme="light",
    position="bottom-center",
    autoClose=6000,
    hideProgressBar=false,
    closeOnClick=true,
    pauseOnHover=true,
    draggable=false,
    progress=undefined,
    type="error",
    style={whiteSpace: "break-spaces"},
    ...props   
}) => ({
    theme: theme,
    position: position,
    autoClose: autoClose,
    hideProgressBar: hideProgressBar,
    closeOnClick: closeOnClick,
    pauseOnHover: pauseOnHover,
    draggable: draggable,
    progress: progress,
    type: type,
    style: style,
    ...props
});

export {
    SUBMIT_SUCCESS_TOAST_THEME,
    SUBMIT_FAILURE_TOAST_THEME
};
