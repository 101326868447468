import React from "react";




// TODO make a component that differentiates from SkeletonCard and SkeletonPaginationCard 
const SkeletonIconCardBasic = ({index}) => {
    return(
        <div
            className={`col items graphic lg-mr wow fadeInUp h-100`}
            data-wow-delay=".4s"
            key={index}
        >
            <div className="content">
                <div className="img">
                    <span className="imgio" style={{ width: "100%" }}>
                        <img src={"assets/img/placeholders/placeholder-view-vector-square.svg"} alt="placeholder"/>
                    </span>
                </div>
            </div>
        </div>

    );
}

export { 
    SkeletonIconCardBasic
};