import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import CustomizableHeader from "../../components/Sections/Headers/customizable-header";
import StartAProjectForm from "../../containers/Forms/start-a-project-form";
import Breadcrumbs from "../../components/Parts/BreadCrumbs/bread-crumbs";




const StartAProject = (props) => {
    const darkThemeEnabled = useSelector((state) => state.preferences.darkThemeEnabled);
    const theme = darkThemeEnabled? "dark" : "light";
    const siteURLs = props?.siteURLs.map((item, key) => item["path"]);
    const [searchParams, setSearchParams] = useSearchParams();
    // searchParams.get("select_service")
    return (
        <>
            <CustomizableHeader
                title={["Let's talk", "about your next project"]}
                subTitle={ <Breadcrumbs theme={theme} allSiteUrls={siteURLs}/> }
            />
            <StartAProjectForm 
                theme={theme} 
                formDescription={(searchParams.get("selected_service")? `Thank you for showing interest in our '${searchParams.get("selected_service")}' service. ` : "")+"We’d love to learn more about your company and how we can help you FIRST! Tell us about your project in the form below, and we’ll put you in touch with the right team. If you’d prefer to email us instead, reach out to."} 
                defaultService={searchParams.get("selected_service")}
            />
        </>
    );
};

export default StartAProject;
