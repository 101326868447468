import React from "react";
import { Split } from "../components/Parts/Split";




const MainContentSection = ({ mcr, mcTitle=null, content, theme }) => {
    const contentDivClassName = mcTitle?
        `col-lg-8 offset-lg-1 col-md-8`
        :
        `col-lg-12 col-md-12`;
    return (
        <section ref={mcr} className="intro-section section-padding main-content" data-bs-theme={theme} id={"my_story"}>
            <div className="container">
                <div className="row">
                    {
                        mcTitle? 
                            <div className="col-lg-3 col-md-4">
                                <div className="htit sm-mb30">
                                <h4>{mcTitle}</h4>
                                </div>
                            </div>
                        :
                        null
                    }
                    <div className={contentDivClassName}>
                        <div className="text">
                            <Split>
                               { content }
                            </Split>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainContentSection;
