import React from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
// Components
import { CardPortraitSM } from "../components/Cards/cards-component";
import FreelancerHero from "../containers/Sections/Headers/freelancer-hero-container";
import SkillsDisplay from "../containers/Sections/Abouts/skills-display-container";
import WorksPaginated from "../containers/Sections/Works/works-display";
import { CardsDisplaySwiper2 } from "../components/Displays/cards-display-swiper2-component";
import ProcessDisplay from "../containers/Sections/Abouts/process-display-container";
import AboutMeWithPortrait from "../containers/Sections/Abouts/about-me-with-portrait-container";
import CallToAction from "../containers/Sections/CTAs/call-to-action-container";




const Home = (props) => {
  const darkThemeEnabled = useSelector((state) => state.preferences.darkThemeEnabled);
  const theme = darkThemeEnabled? "dark" : "light";
  const site_data = useOutletContext();
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const [webWorks, getWebWorks] = React.useState([]);
  const [artWorks, getArtWorks] = React.useState([]);

  // Page assets
  const profile_pic = "assets/img/me/professional-pictureA.jpg";
  const codePic  = "assets/img/backgrounds/closeup-of-code-1.jpg";
  // ===================================================== //

  /* Load Page Content */
  React.useEffect(() => {
    const fetchArtWorks = async () => {
      let response;
      try {
        response = (await axios.get("/v2/content/works")).data["data"];
      } catch(error) {
        return [];
      }
      getWebWorks(response["web_works"]);
      getArtWorks(response["art_works"]);
    };

    if (artWorks.length <= 0) {
      fetchArtWorks();
    };

    setPageLoaded(true);
  }, [pageLoaded, webWorks, artWorks]);

  return (
    <>
      <FreelancerHero 
        data={site_data} 
        theme={theme} 
        portrait_img={profile_pic} 
      />

      <SkillsDisplay 
        data={site_data} 
        theme={theme} 
        title={"Core Skills"} 
        subtitle={"Competencies"} 
      />
      
      <WorksPaginated
        title={"Software Projects"}
        subtitle={"Most Recent Work"}
        CardsClassName={"items graphic wow fadeInUp"}
        content={webWorks}
        CardComponent={CardPortraitSM}
        skeletonLoader={true} 
        itemsPerPage={2}
        gridSize={6}
        searchOption={false}
        filter={null}
        theme={theme} 
      />

      <CardsDisplaySwiper2 
        title={"Graphics"}
        subtitle={null}
        content={artWorks}
        skeletonLoader={true}
        maxWorks={8}
        theme={theme}
        breakpoints={{
          767: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          991: {
            slidesPerView: 2,
          },
        }}
      />

      <ProcessDisplay 
        data={site_data} 
        theme={theme} 
        title={"From Start to Finish"} 
        subtitle={"Workflow"} 
      />

      <AboutMeWithPortrait theme={theme} />
      {/* For sections with pictured backgrounds its best to lock the theme to dark */}
      <CallToAction 
        backgroundImage={codePic} 
        theme={"dark"} 
        invert={false}
        btnLink={"/contact#general-contact-section"}
      />
    </>
  );
};

export default Home;
