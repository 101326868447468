import React from "react";




function SkeletonWrapper({ children }) {
    return (
      <div
        style={{
            width: "100%",
            display: "block",
            lineHeight: 1
        }}
      >
        {children}
      </div>
    );
};

export default SkeletonWrapper;
