import React from "react";




// Pass a key from React Hook Form "errors" object into this helper
const ReactHookFormError = ({
    errorObj
}) => {
    return(
        errorObj && (
            <div role="alert" style={{paddingTop: "10px", paddingBottom: "10px", color: "red"}}>
                {
                    errorObj?.message? errorObj.message : ""
                }
            </div>
        )
    );
};

export {
    ReactHookFormError
};
