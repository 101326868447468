import React, { forwardRef, cloneElement } from "react";



const DynamicWrapper = ({ component, ...props}) => {
    return(
        React.cloneElement(
            component,
            props
        )
    )
};


export { 
    DynamicWrapper
};
