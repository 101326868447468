import React from "react";
import { AccordionBasic } from "./accordion-basic-component";


const AccordionLGiconCard = ({
    data,
    index,
    theme,
}) => {
    const [isOpen, setOpen] = React.useState(false);
    const IconJSX = (
        <div className="skills-icon-container">
            <i 
                className={`icon color-font ${data.icon}`} 
                style={{
                    marginTop: "0px",
                    marginRight: "0px",
                    marginBottom: "0px",
                    marginLeft: ""
                }}
            >
            </i>
        </div> 
    ); 
    return (
        <div
            className={`col-lg-6 wow fadeInLeft ${isOpen? "active" : ""}`}
            aria-selected={isOpen? "true" : "false"}
            data-wow-delay={
                index === 0
                    ? ".5s"
                    : index === 1
                    ? ".7s"
                    : index === 2
                    ? ".9s"
                    : ".5s"
            }
            onClick={() => { 
                isOpen?
                    setOpen(false)
                :
                    setOpen(true)
            }}
        >
            <div className="item-box no-curve">
                {/* 
                <div className="skills-icon-container">
                    <i className={`icon color-font ${data.icon}`}></i>
                </div> 
                */}
                <AccordionBasic iconJSX={IconJSX} theme={theme} data={data} index={index} isOpen={isOpen}/>
            </div>
        </div>
    );

};

export default AccordionLGiconCard;
