// TODO site config variables should be dynamic and pulled from a database
const site_config = {
    siteName: "DesignBytes",
    siteIcon: "ion-flash",
    enableAnimatedRoute: false,
    google: {
      analyticsKey: "UA-xxxxxxxxx-1",
    },
    orderOfNavTabs: [ 
      "Home", 
      "About", 
      "Works", 
      "Services", 
      "Contact" 
    ],
    Google_ReCAPTCHA_site_key: "6Le30AoqAAAAANahTYE0cflI8Ou9f49EBCQQeXgC"
};

export default site_config;
