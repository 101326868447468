import React from "react";




export const MenuContext = React.createContext({
    prefixCls: "",
    firstLevel: true,
    inlineCollapsed: false,
});
