import React from "react";
import { MenuContext } from "../../Menu/MenuContext/menu-context"




const SubMenu = (props) => {
    const { className, popupClassName, icon, title, theme: theme } = props;
    const context = React.useContext(MenuContext);
    const { prefixCls, inlineCollapsed, theme: contextTheme } = context;
    let titleNode;
  
    // CONDITIONLY SET TITLE NODE
    // If there is no icon make differentiating title HTML node div
        //  If inlineCollapsed and that 'title' is a non empty string make div with abbreviated title
        // Else render span tag with full title
    if (!icon) {
        titleNode =
            inlineCollapsed && title && typeof title === "string"? 
                (
                    <div className={`${title}-inline-collapsed-noicon`}>
                        {title.charAt(0)}
                    </div>
                ) 
            : 
                (
                    <span className={`${title}-title-content`}>
                        {title}
                    </span>
                );
    // If there is an icon render icon jsx with a title wraped in a span tag or just a title text content
    } else {
        const titleIsSpan = React.isValidElement(title) && title.type === "span";
        titleNode = (
            <>
                {
                    React.cloneElement(icon, {
                        className: `${React.isValidElement(icon)? icon.props?.className : ""} ${prefixCls}-item-icon`,
                    })
                }
                {titleIsSpan ? title : <span className={`${prefixCls}-title-content`}>{title}</span>}
            </>
        );
    };
  
    const contextValue = React.useMemo(
      () => ({ ...context, firstLevel: false }),
      [context],
    );
  
    return (
        <MenuContext.Provider value={contextValue}>
            <li
                // {...omit(props, ['icon'])}
                className={`${className} ${prefixCls} ${popupClassName} ${prefixCls}-${theme || contextTheme}`}
            >
                { titleNode }
            </li>
        </MenuContext.Provider>
    );
  };
  
  export default SubMenu;
  