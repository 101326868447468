export function pathSplit(str, keepSlash = true) { 
    return typeof(str) === "string"? [
        ...["/", /\\/g].map(
            (char, i) => 
                keepSlash?
                    [...[...str.matchAll(char)].map(
                            (entry, j) =>
                                j === 0? 
                                    // The first instance
                                    str.slice(0, entry.index+1) 
                                :
                                    // This returns a match array of all instances of the desired character found in the string
                                    str.slice([...str.matchAll(char)][j-1].index+1, entry.index+1)
                        ),
                        str.split(char).pop()
                    ]
                :
                    str.split(char)
        )
    ].filter((val) => val.length > 1? val : null)[0]?? str : "ERROR: Please enter a string value."; 
};
