const FORM_INPUT_CONSTANTS = {
    NAME: {
        min: 10,
        max: 50
    },
    BUSINESS_NAME: {
        min: 10,
        max: 65
    },
    DESCRIPTION: {
        min: 10,
        max: 150
    },
    CONCEPT_FILES: {
        max: 5
    },
    HOW_YOU_FOUND_US: {
        min: 10,
        max: 50
    },
    MESSAGE: {
        min: 10,
        max: 250
    }
};

export { 
    FORM_INPUT_CONSTANTS 
};
