import React from "react";
import SubMenu from "./Submenu/submenu";
import { MenuDivider } from "./MenuDivider/menu-divider";
import MenuItem from "./MenuItem/menu-item";
import { DynamicWrapper } from "../../../../components/Parts/Wrapper/dynamic-wrapper";




// Item node converter, this function takes all the pages in the application and auto routes/builds a nav node tree dynamically
function convertItemsToNodes({
    theme,
    data, 
    menuOrder = [],
    handleNavbarDropdown,
    subMenuClassName = "nav-item dropdown",
    MenuLinkJSX = null,
    MenuItemJSX = null,
    SubMenuTitleJSX = null, 
    SubMenuJSX = null
}) {
    // if (menuOrder === null || menuOrder === undefined) menuOrder = [];
    // Order "list" argument array if order array is not empty
    // make empty array size of "list" argument tosort by using MDNs "with()" array method 
    let sortedList = Array(data.length).fill(undefined);
    let copyMenuOrder = [...menuOrder];
    if (menuOrder && menuOrder.length > 0) {
        for (let i = 0; i < data.length; i++ ) {
            const pageName = data[i]["name"];
            if (pageName.toLowerCase() === "home") {
                sortedList[0] = data[i];
            }
            const itemInOrder = menuOrder.indexOf(pageName);
            if (itemInOrder !== -1) {
                sortedList[itemInOrder] = data[i];
                copyMenuOrder.splice(copyMenuOrder.indexOf(pageName), 1);
            }
        }; 
        /* Friendy message to alert invalid page names included in the orderOfNavTabs */
        if (process.env.NODE_ENV !== 'production' && copyMenuOrder.length > 0) { 
            const lastItem = copyMenuOrder[copyMenuOrder.length-1];
            const staticSentence = `valid page name${copyMenuOrder.length>1? "s": ""} in orderOfNavTabs.`;
            const invalidsSentence = copyMenuOrder.length > 1? `${copyMenuOrder.length === 2? `"${copyMenuOrder[0]}"` : `"${copyMenuOrder.slice(0, -1).join('", "')}`} and ${lastItem} are not ${staticSentence}` : `"${copyMenuOrder[0]}" is not a ${staticSentence}`;
            console.log(invalidsSentence);
        };
        data = sortedList;
    };
    // Set JSX to pass down to props
    // MenuJSX
    return (data || [])
        .map((opt, index) => {
        if (opt && typeof opt === "object") {
            const { name, path, type, children, ...restProps } = opt;
            const mergedKey = `tmp-${Math.random().toFixed(5).toString().slice(2)}-${index}`;
            // MenuItemGroup & SubMenuItem
            if (children?.length > 0 || type === "group") {
                if (type === "group") {
                    // Group (DEFAULT CODE FOR DROPDOWN MENU/NESTED NODES WITHIN PARENT MENU ITEM)
                    return (
                        <li
                            className={"nav-item dropdown"}
                            title={name}
                            role="presentation"
                            key={ mergedKey }
                            // {...restProps}
                            onClick={handleNavbarDropdown}
                        >
                            {
                                React.isValidElement(SubMenuTitleJSX)?
                                    <DynamicWrapper component={SubMenuTitleJSX}>
                                        {{
                                            index: index, 
                                            name: name,
                                        }}
                                    </DynamicWrapper>
                                :
                                <span
                                    className={`${name?name:""}-title nav-link dropdown-toggle`}
                                    title={typeof name === "string" ? name : undefined}
                                    data-toggle="dropdown"
                                    role="button"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {name}
                                </span>
                            }
                            { 
                                React.isValidElement(SubMenuJSX)? 
                                    <DynamicWrapper component={SubMenuJSX}>
                                        { convertItemsToNodes({ data: children }) }
                                    </DynamicWrapper>
                                :
                                    <div className="dropdown-menu">
                                        <ul role="group" className={`${mergedKey}-list`}>
                                            { convertItemsToNodes({ data: children }) }
                                        </ul>   
                                    </div> 
                            }
                        </li>
                    );
                }
    

                // Sub Menu
                return (
                    <SubMenu className={"nav-item dropdown"} key={mergedKey} {...restProps} title={name}>
                        { convertItemsToNodes(children) }
                    </SubMenu>
                );
            }
    
            // MenuItem & Divider
            if (type === "divider") {
                return <MenuDivider key={mergedKey} {...restProps} />;
            }

            return (
                <MenuItem 
                    key={mergedKey}
                    index={index} 
                    name={name} 
                    path={path} 
                    noodeChildren={children} 
                    className={"nav-item"}
                    MenuLinkJSX={MenuLinkJSX}
                    MenuItemJSX={MenuItemJSX} 
                    {...restProps}
                >
                    {name}
                </MenuItem>
            );
        }
        return null;
    })
    .filter((opt) => opt);
};


// Actual/Final Burger JSX
const NavNodes = ({
    theme,
    menuData,
    handleNavbarDropdown,
    display="",
    handleNavbarMobileDropDown = null,
    menuID = "navbar-menu", 
    menuContainerClassName = "navbar-nav ml-auto", 
    orderOfTabs = [],
    BurgerBtnJSX = null,
    MenuJSX = null,
    MenuLinkJSX = null,
    MenuItemJSX = null,
    SubMenuTitleJSX=null,
    SubMenuJSX = null
}) => {
    React.useEffect(()=> {}, [theme]);
    const defaultHandleMobileDropdown = (e) => {
        document
        .getElementById("navbarSupportedContent")
        .classList.toggle("show-menu");
    };

    const dataDisplayOptions = [ "breakpoint-aware", "burger-nav", "full-nav" ];
    
    display = dataDisplayOptions.filter((opt) => opt === display.toLowerCase()).length === 0?
        dataDisplayOptions[0]
    :
        display

    // Burger Node JSX
    const BurgerNode =
        BurgerBtnJSX && React.isValidElement(BurgerBtnJSX)?
            BurgerBtnJSX
        :
            <button
                className="navbar-toggler"
                type="button"
                onClick={handleNavbarMobileDropDown? handleNavbarMobileDropDown : defaultHandleMobileDropdown}
                data-display={display?? dataDisplayOptions[1]}
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="icon-bar">
                    <i className="fas fa-bars"></i>
                </span>
            </button>

    // Render Menu Navigation with Burger Component
    const menuNodes = convertItemsToNodes({
        theme: theme,
        data: menuData, 
        menuOrder: orderOfTabs, 
        handleNavbarDropdown: handleNavbarDropdown,
        MenuLinkJSX: MenuLinkJSX, 
        MenuItemJSX: MenuItemJSX,
        SubMenuTitleJSX: SubMenuTitleJSX,
        SubMenuJSX: SubMenuJSX
    });

    const DefaultMenuJSX = ({ children }) => {
        return (
            <ul className="navbar-nav ml-auto">
                <div className="menu-container" data-id="navbar-menu">
                    <ul className={`menu-nodes-container ${menuContainerClassName}`}>
                        { children }
                    </ul>
                </div>
            </ul>
        );
    };
 
    return(
        <>
            {
                BurgerNode
            }
            <div 
                className="collapse navbar-collapse" 
                id="navbarSupportedContent" 
                data-display={display} 
                // style={{ display: dataDisplay === dataDisplayOptions[1]? "block" : null }}
            >
                {
                    React.isValidElement( MenuJSX )?
                        <DynamicWrapper component={MenuJSX}>
                            { menuNodes }
                        </DynamicWrapper>
                        :
                        <DefaultMenuJSX>
                            { menuNodes }
                        </DefaultMenuJSX> 
                }
            </div>
            
        </>
    );
};

export default NavNodes;
