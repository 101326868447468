import React from "react";
import initFullNavbarMenu from "../../common/initFullNavbarMenu";
import NavNodes from "../../containers/Sections/Navigation/Menu/renderMenuNode";
// import { useOutletContext } from "react-router-dom";
import { PAGE_URLS } from "../../routing/AppRoutes";
import site_config from "../../../../config/site.config";




const PopUpNavBurger = ({ theme, isOpen, setOpenCallback }) => {
    React.useEffect(() => {
        initFullNavbarMenu({ 
            lockScroll: true, 
            theme: theme, 
            isMenuOpen: isOpen, 
            setMenuOpenClosed: setOpenCallback 
        });

    }, [theme, isOpen]);

    return(
        <div className="menu-icon" onClick={() => { 
            setOpenCallback(true) 
        }}>
            <span className="icon">
                <i></i>
                <i></i>
            </span>
            <span className="text" data-splitting>
                <span className="menu-text word">Menu</span>
            </span>
        </div>
    );
};


const PopUpNavMenuContainer = ({ theme, setOpenCallback, children }) => { 
    console.log(children)
    return(
        <div className="hamenu" data-bs-theme={theme}>
            <div 
                className="close-full-menu-btn" 
                onClick={() => {setOpenCallback(false)}}
            >
                CLOSE X
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-8">
                        <div className="menu-links" data-bs-theme={theme}>
                            <ul className="main-menu">{ children }</ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


const PopUpNavMenuItem = ({ children }) => {
    return(<div className="o-hidden">{ children }</div>)
};


const PopUpNavMenuSubmenuTitle = ({ children }) => {
    return (
        <div className="o-hidden">
            <span className="link dmenu">
            <span className="nm">{`0${children.index+1}.`}</span>{children.name}<i className="fas fa-angle-right"></i>
            </span>
        </div>
    );
};


const PopUpNavMenuSubmenu = ({ theme, children }) => {
    // const [ Open, isOpen ] = React.useState(false);
    // React.useState(() => {

    // }, [Open]);
    return (
        <div className="sub-menu">
            <div>
                <div className="o-hidden">
                    <span className="sub-link back" data-bs-theme={theme}>
                        <i className="pe-7s-angle-left"></i> Go Back
                    </span>
                </div>
            </div>
            <PopUpNavMenuItem>{children}</PopUpNavMenuItem>
        </div>
    );
};

const PopUpNavMenuLink = ({ children }) => {
    return (
        <a className="link" href={children.path}>
            <span className="nm">{`0${children.index+1}.`}</span>{children.children}
        </a>
    );
};


const ModalNavBar = ({ theme }) => {
    // const URL_TREE = useOutletContext();
    const [ open, setOpen ] = React.useState(false);
    React.useEffect(() => {}, [open]);
    return(
        <NavNodes
            theme={theme}
            menuData={PAGE_URLS} 
            orderOfTabs={site_config.orderOfNavTabs}
            display={"burger-nav"}
            handleNavbarDropdown={null}
            BurgerBtnJSX={<PopUpNavBurger theme={theme} isOpen={open} setOpenCallback={setOpen}/>}
            MenuLinkJSX={<PopUpNavMenuLink />}
            MenuJSX={<PopUpNavMenuContainer theme={theme} setOpenCallback={setOpen} />}
            MenuItemJSX={<PopUpNavMenuItem />}
            SubMenuTitleJSX={<PopUpNavMenuSubmenuTitle />}
            SubMenuJSX={<PopUpNavMenuSubmenu theme={theme} />}
        />
    );
};

export { ModalNavBar };
