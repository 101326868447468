import React from "react";
import { MenuContext } from "../MenuContext/menu-context";



function Divider({ className, style }) {
    const { prefixCls } = React.useContext(MenuContext);
    // const measure = React.useMeasure();
  
    // if (measure) {
    //   return null;
    // }
  
    return (
      <li
        className={`${prefixCls}-item-divider ${className}`}
        style={style}
      />
    );
}


export const MenuDivider = (props) => {
  return(
    <Divider className={`item-divider ${props.className}`}></Divider> 
  );
};
