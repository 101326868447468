import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { formatTime } from "../../../../utils/formatTime";





const defaultVideoState = (playing) => ({
    playing: playing,
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0
});


const CustomReactVideoPlayer = ({ videoPlayerProps }) => {
    const videoPlayerRef = React.useRef(null);
    const controlRef = React.useRef(null);
    const [videoState, setVideoState] = React.useState( defaultVideoState(true) );
    const { playing, muted, volume, playbackRate, played, seeking, buffer } = videoState;
    const currentTime = videoPlayerRef.current
        ? videoPlayerRef.current.getCurrentTime()
        : "00:00";
    const duration = videoPlayerRef.current
        ? videoPlayerRef.current.getDuration()
        : "00:00";

    const formatCurrentTime = formatTime(currentTime);
    const formatDuration = formatTime(duration);

    return (
        <>
            <div
                className="background bg-img parallaxie"
                style={{
                    position: "absolute",
                    inset: "0%",
                    zIndex: -1,
                    width: "100%",
                    height: "100%"
                }}
            >
                <div
                    style={{
                        width: "auto",
                        height: "auto",
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        backgroundSize: "cover",
                        overflow: "hidden",
                        zIndex: -100
                    }}
                >
                    <ReactPlayer 
                        {...videoState}
                        {...videoPlayerProps}
                    />
                    <div 
                        className="vid-div__overlay_class" 
                        style={{
                            position: "absolute",
                            inset: "0%",
                            height: "100%",
                            backgroundColor: "rgba(85, 57, 57, 0.61)"
                        }}
                    >
                    </div>
                </div>
            </div>
        </>
    );
};





export default CustomReactVideoPlayer;
