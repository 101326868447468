import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ServicesListBasic } from "../containers/Sections/CTAs/services-list-basic-container";
import FaqServices from "../containers/Sections/FQAs/faq-services-container";
import ParallaxHeader from "../components/Sections/Headers/parallax-header-container";
import site_data from "../site_data.json"




const Services = (props) => {
    const darkThemeEnabled = useSelector((state) => state.preferences.darkThemeEnabled);
    const theme = darkThemeEnabled? "dark" : "light";
    const fixedHeader = React.useRef(null);
    const MainContent = React.useRef(null);
    React.useEffect(() => {      
        if (fixedHeader.current) {
            var slideHeight = fixedHeader.current.offsetHeight;
        }
        if (MainContent.current) {
            MainContent.current.style.marginTop = slideHeight + "px";
        }
    }, [fixedHeader, MainContent]);

    const SOFTWARE_SERVICE_FOCUSES = site_data.BusinessCoreFocuses.SOFTWARE_SERVICE_FOCUSES;
    const DESIGN_SERVICE_FOCUSES = site_data.BusinessCoreFocuses.DESIGN_SERVICE_FOCUSES;

    const SOFTWARE_PRICE_PACKAGES = site_data.BusinessServices.SOFTWARE_PRICE_PACKAGES;
    const DESIGN_PRICE_PACKAGES = site_data.BusinessServices.DESIGN_PRICE_PACKAGES;

    const BUSINESS_FAQs = site_data.BusinessFAQs;
   
    return (
        <>
            {/* <BasicHeroBreadCrumbs
                title="Popular Services"
                links={null}
                background={null}
            /> */}
            <ParallaxHeader 
                sliderRef={fixedHeader} 
                title={"Popular Services"} 
                subtitle={"Seamlessly integrating strategy in artistic and technological works."}
                zIndex={-10000}
            />
            <section ref={MainContent} className="app-services main-content section" data-bs-theme={theme} style={{ position: "relative" }}>
                <ServicesListBasic
                    SectionTitle={"Software Development and Automation"}
                    description={
                        <>
                            My software development services are packaged based off software development skills I primarily specialize in and based off software products in hot demand. Why choose my services? Every product I build comprise of {SOFTWARE_SERVICE_FOCUSES.length} core focuses, {SOFTWARE_SERVICE_FOCUSES.map((service, key) => key !== SOFTWARE_SERVICE_FOCUSES.length-1? service.title : null).join(", ") + ' and ' + SOFTWARE_SERVICE_FOCUSES.map((service) => service.title).pop() }. For more info my priciple breakdown and pricing packages are listed below.
                            <br /> 
                            If what you need is not listed below in my pricing plan please reach out to me personally via email or {
                                <Link to={"/Contact"}>Contact Page</Link>
                            }, I'm sure what ever software inquiry you may have I can resolve or refer you to the right person to resolve it. 
                        </>
                    }
                    className={"app-services section-padding software-dev pb-0"}
                    ServicesTitle={"Core Focuses 🎯"}
                    ServicesCardClassName={"service-cards"}
                    PricesTitle={"Popular Software Development Pricing Packages"}
                    ServicesData={SOFTWARE_SERVICE_FOCUSES}
                    PricesData={SOFTWARE_PRICE_PACKAGES}
                    cardClassName={"icon-cards"}
                />
                <ServicesListBasic
                    SectionTitle={"Design and Art"}
                    description={
                        <>
                            As a natural born artist, I offer in demand digital design services. Having served dozens of clients and a more than a decade of artistic and design experience I offer one of the industries best systems for client design. My process includes {DESIGN_SERVICE_FOCUSES.length} basic focuses; {DESIGN_SERVICE_FOCUSES.map((service, key) => key !== DESIGN_SERVICE_FOCUSES.length-1? service.title : null).join(", ") + ' and ' + DESIGN_SERVICE_FOCUSES.map((service) => service.title).pop() } which is all important in ensuring your next design project with me meets your needs. 
                            <br /> 
                            For more info my priciple breakdown and pricing packages are listed below.
                        </>
                    }
                    className={"app-services section-padding design"}
                    ServicesTitle={"Core Focuses 🎯"}
                    ServicesCardClassName={"service-cards"}
                    PricesTitle={"Popular Graphic Design Pricing Packages"}
                    ServicesData={DESIGN_SERVICE_FOCUSES}
                    PricesData={DESIGN_PRICE_PACKAGES}
                    cardClassName={"icon-cards"}
                />
                <div className="line bottom right"></div>
            </section>    

            <FaqServices
                theme={theme}
                title={"FAQs"}
                subTitle={"Frequently Asked Questions"}
                description={"When starting a new project there are many questions. Based on my deep experience with freelancing for clients I've identified many frequnetly asked questions. Hopefully you can see your question listed, if not please hit the button below to ask me a question."}
                CTAtext={"Need more info? Contact us >"}
                FAQs={BUSINESS_FAQs}
            />
        </>
    );
};

export default Services;
