import React from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
// Components
import ParallaxHeader from "../components/Sections/Headers/parallax-header-container";
import MainContentSection from "../containers/MainContentSection";
// import VideoWithTestimonials from '../containers/Sections/Abouts/video-and-testimonials-container';
import PictureAndTestimonials from "../containers/Sections/Abouts/picture-testimonials-container";
import ResumeDisplay from "../containers/Sections/Abouts/resume-display-container";




const AboutMeBio = (props) => {
    return(
        <p
            className="wow txt mb-10 words chars splitting"
            data-splitting
        >
            Hello Visitors
            <br/>
            <br/>
            Welcome to DesignBytes! My name is Chad Gresham and I'm the face of this business. I started DesignBytes during the pandemic in 2020 but built this website years later (2024). 
            <br/>
            <br/>
            I started as a designer freelancing logos for clients before creating DesignBytes, then over the next couple years I studied web development and software engineering. In 2021 my relentless passion for design and software development helped me land a professional career as a fullstack developer and data engineer. While working a fulltime position I continued to freelance under DesignBytes out of passion. You might ask "why continue freelancing after having a fulfilling fulltime position?", its simple... besides passion for designing and making products I formed DesignBytes with two goals in mind; <b>one, build sound products for target industries;</b> <b>two, provide immersive digital experiences and future-sound software solutions for businesses</b>.
            <br/>
            <br/>
            These goals are utmost important to me and are the core values of DesignBytes which is why we put people and their projects first.
        </p>
    );
};


const About = () => {
    const darkThemeEnabled = useSelector((state) => state.preferences.darkThemeEnabled);
    const theme = darkThemeEnabled? "dark" : "light";
    const [pageLoaded, setPageLoaded] = React.useState(false);
    const fixedHeader = React.useRef(null);
    const MainContent = React.useRef(null);
    const [resumeContent, getResumeContent] = React.useState([]);
    const site_data = useOutletContext();

    // Page assets
    const staticBackgroundImg = "assets/img/backgrounds/coder-hands-on-laptop.png";
    // ===================================================== //
    
    /* Load Page Content */
    React.useEffect(() => {
        const fetchResume = async () => {
          let response;
          try {
            response = (await axios.get("/v2/content/resume")).data["data"];
          } catch(error) {
            return [];
          }
          const res_content = response["content"]??[];
          getResumeContent(res_content);
        };
    
        if (Object.keys(resumeContent).length <= 0) {
            fetchResume();
        };

        setPageLoaded(true);
    }, [resumeContent]);

    React.useEffect(() => {   
        if (fixedHeader.current) {
            var slideHeight = fixedHeader.current.offsetHeight;
        }
        if (MainContent.current) {
            MainContent.current.style.marginTop = slideHeight + "px";
        }
    }, [fixedHeader, MainContent]);

    return (
        <>
            <ParallaxHeader 
                sliderRef={fixedHeader} 
                title={"About Me"} 
                subtitle={"From a deadend industry to finding a passion that allows me to utilizes all my talents"}
            />
            <MainContentSection 
                mcr={MainContent} 
                mcTitle={"My Journey..."} 
                content={ <AboutMeBio /> } 
                theme={theme} 
            />
            {/* 
            <VideoWithTestimonials 
                theme={theme} 
                videoSrc={null} 
            /> 
            */}
            <ResumeDisplay 
                resumeContent={resumeContent}
                loaded={pageLoaded}
            />
            <PictureAndTestimonials 
                data={site_data} 
                theme={theme}
                staticBackgroundImg={staticBackgroundImg}    
            />
        </>
    );
};

export default About;
