import React from "react";




export const CheckMarkInCircle = ({ props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
        >
            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
        </svg>
    );
};


export const AngleLeft = ({ 
    theme,
    width,
    height, 
    lightcolor, 
    darkcolor
}) => (
    <svg 
        width={ width? width : "32px" } 
        height={ height? height : "32px" }
        viewBox="0 0 32 32"
    >
        <g id="icomoon-ignore"></g>
        <path 
            fill={theme==="light"? lightcolor : darkcolor}
            d="M12.792 15.233l-0.754 0.754 6.035 6.035 0.754-0.754-5.281-5.281 5.256-5.256-0.754-0.754-3.013 3.013z" 
        ></path>
    </svg>
);


export const AngleRight = ({
    theme,
    width,
    height, 
    lightcolor, 
    darkcolor
}) => (
    <svg 
        width={ width? width : "32px" } 
        height={ height? height : "32px" }
        viewBox="0 0 32 32"
    >
        <g id="icomoon-ignore"></g>
        <path 
            fill={theme==="light"? lightcolor : darkcolor}
            d="M19.159 16.767l0.754-0.754-6.035-6.035-0.754 0.754 5.281 5.281-5.256 5.256 0.754 0.754 3.013-3.013z"
        ></path>
    </svg>
);


export const Call = ({
    theme,
    width,
    height, 
    lightcolor, 
    darkcolor
}) => (
    <svg 
        width={ width? width : "32px" } 
        height={ height? height : "32px" }
        viewBox="0 0 32 32"
    >
        <g id="icomoon-ignore"></g>
        <path 
            fill={theme==="light"? lightcolor : darkcolor}
            d="M23.407 30.394c-2.431 0-8.341-3.109-13.303-9.783-4.641-6.242-6.898-10.751-6.898-13.785 0-2.389 1.65-3.529 2.536-4.142l0.219-0.153c0.979-0.7 2.502-0.927 3.086-0.927 1.024 0 1.455 0.599 1.716 1.121 0.222 0.442 2.061 4.39 2.247 4.881 0.286 0.755 0.192 1.855-0.692 2.488l-0.155 0.108c-0.439 0.304-1.255 0.869-1.368 1.557-0.055 0.334 0.057 0.684 0.342 1.068 1.423 1.918 5.968 7.55 6.787 8.314 0.642 0.6 1.455 0.685 2.009 0.218 0.573-0.483 0.828-0.768 0.83-0.772l0.059-0.057c0.048-0.041 0.496-0.396 1.228-0.396 0.528 0 1.065 0.182 1.596 0.541 1.378 0.931 4.487 3.011 4.487 3.011l0.050 0.038c0.398 0.341 0.973 1.323 0.302 2.601-0.695 1.327-2.85 4.066-5.079 4.066zM9.046 2.672c-0.505 0-1.746 0.213-2.466 0.728l-0.232 0.162c-0.827 0.572-2.076 1.435-2.076 3.265 0 2.797 2.188 7.098 6.687 13.149 4.914 6.609 10.532 9.353 12.447 9.353 1.629 0 3.497-2.276 4.135-3.494 0.392-0.748 0.071-1.17-0.040-1.284-0.36-0.241-3.164-2.117-4.453-2.988-0.351-0.238-0.688-0.358-0.999-0.358-0.283 0-0.469 0.1-0.532 0.14-0.104 0.111-0.39 0.405-0.899 0.833-0.951 0.801-2.398 0.704-3.424-0.254-0.923-0.862-5.585-6.666-6.916-8.459-0.46-0.62-0.641-1.252-0.538-1.877 0.187-1.133 1.245-1.866 1.813-2.26l0.142-0.099c0.508-0.363 0.4-1.020 0.316-1.242-0.157-0.414-1.973-4.322-2.203-4.781-0.188-0.376-0.336-0.533-0.764-0.533z" 
        ></path>
    </svg>
);


export const MapMarker = ({
    theme,
    width,
    height, 
    lightcolor, 
    darkcolor
}) => (
    <svg
        width={ width? width : "32px" } 
        height={ height? height : "32px" } 
        viewBox="0 0 32 32"
        fill={theme==="light"? lightcolor : darkcolor}
    >
        <g id="icomoon-ignore"></g>
        <path 
            fill={theme==="light"? lightcolor : darkcolor}
            d="M16.001 1.072c5.291 0 9.596 4.305 9.596 9.597 0 1.683-0.446 3.341-1.29 4.799l-8.307 14.394-8.308-14.395c-0.843-1.456-1.289-3.115-1.289-4.798 0-5.292 4.305-9.597 9.597-9.597zM16.001 14.4c2.058 0 3.731-1.674 3.731-3.731s-1.674-3.731-3.731-3.731c-2.058 0-3.732 1.674-3.732 3.731s1.674 3.731 3.732 3.731zM16.001 0.006c-5.889 0-10.663 4.775-10.663 10.663 0 1.945 0.523 3.762 1.432 5.332l9.23 15.994 9.23-15.994c0.909-1.57 1.432-3.387 1.432-5.332 0-5.888-4.774-10.663-10.662-10.663v0zM16.001 13.334c-1.472 0-2.666-1.193-2.666-2.665 0-1.471 1.194-2.665 2.666-2.665s2.665 1.194 2.665 2.665c0 1.472-1.193 2.665-2.665 2.665v0z"
        ></path>
     </svg>
);


export const Mail = ({
    theme,
    width,
    height, 
    lightcolor, 
    darkcolor
}) => (
    <svg 
        width={ width? width : "32px" } 
        height={ height? height : "32px" } 
        viewBox="0 0 32 32"
        fill={theme==="light"? lightcolor : darkcolor}
    >
        <g id="icomoon-ignore"></g>
        <path 
            fill={theme==="light"? lightcolor : darkcolor}
            d="M28.244 7.47h-25.572v17.060h26.656v-17.060h-1.084zM27.177 8.536l-10.298 10.298c-0.47 0.47-1.289 0.47-1.759 0l-10.3-10.298h22.356zM3.738 8.961l6.923 6.922-6.923 6.923v-13.846zM4.589 23.464l6.827-6.826 2.951 2.95c0.436 0.436 1.016 0.677 1.633 0.677s1.197-0.241 1.633-0.677l2.951-2.951 6.826 6.826h-22.822zM28.262 22.807l-6.923-6.924 6.923-6.924v13.848z" 
        ></path>
    </svg>
);


// Social media icons
export const LinkedIn = ({
    theme,
    width,
    height, 
    lightcolor, 
    darkcolor
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
        clipRule="evenodd"
        width={ width? width : "32px" } 
        height={ height? height : "32px" }
        viewBox="0 0 34 34"
    >
        <path
            fill="none"
            stroke={theme==="light"? lightcolor : darkcolor}
            strokeWidth="0px"
            d="M33 17c0 8.837-7.163 16-16 16-8.836 0-16-7.163-16-16S8.164 1 17 1c8.837 0 16 7.163 16 16z"
        ></path>
        <g fillRule="nonzero">
            <path
                fill={theme==="light"? lightcolor : darkcolor}
                d="M14.315 13.938h3.56v1.632h.051c.495-.94 1.707-1.93 3.514-1.93 3.759 0 4.454 2.475 4.454 5.692v6.553h-3.713v-5.81c0-1.385-.024-3.167-1.929-3.167-1.933 0-2.227 1.51-2.227 3.067v5.91h-3.71V13.938zM10.129 7.998a2.153 2.153 0 11-.003 4.307 2.153 2.153 0 01.003-4.307zm-1.86 5.94h3.717v11.947H8.269V13.938z"
            ></path>
        </g>
    </svg>
);


export const Github = ({
    theme,
    width,
    height,
    lightcolor, 
    darkcolor
}) => (
    <svg
         width={ width? width : "32px" } 
        height={ height? height : "32px" } 
        viewBox="0 0 34 34"
        style={{
            fillrule: "evenodd",
            cliprule: "evenodd",
            strokelinejoin: "round",
            strokemiterlimit: 2
        }}
    >
        <g>
            <path
                d="M33,17c-0,8.837 -7.164,16 -16,16c-8.836,0 -16,-7.163 -16,-16c0,-8.837 7.164,-16 16,-16c8.836,0 16,7.163 16,16Z"
                style={{
                    fill: "none", 
                    fillrule: "nonzero", 
                    stroke: theme==="light"? lightcolor : darkcolor,
                    strokeWidth: "0px" 
                }}
            />
            <g>
                <path
                    d="M17,8.26c-4.948,0 -8.96,4.012 -8.96,8.96c0,3.96 2.567,7.318 6.128,8.503c0.447,0.082 0.612,-0.193 0.612,-0.43c-0,-0.215 -0.009,-0.921 -0.013,-1.668c-2.492,0.54 -3.018,-1.057 -3.018,-1.057c-0.408,-1.035 -0.995,-1.313 -0.995,-1.313c-0.813,-0.555 0.061,-0.545 0.061,-0.545c0.9,0.065 1.374,0.925 1.374,0.925c0.799,1.37 2.096,0.973 2.607,0.745c0.081,-0.58 0.313,-0.975 0.569,-1.2c-1.99,-0.225 -4.083,-0.992 -4.083,-4.427c0,-0.978 0.351,-1.778 0.924,-2.405c-0.093,-0.225 -0.4,-1.138 0.087,-2.37c-0,-0 0.752,-0.241 2.464,0.917c0.715,-0.197 1.482,-0.297 2.243,-0.3c0.762,0.003 1.528,0.103 2.245,0.3c1.71,-1.158 2.461,-0.917 2.461,-0.917c0.488,1.232 0.181,2.145 0.088,2.37c0.574,0.627 0.922,1.427 0.922,2.405c0,3.442 -2.096,4.2 -4.091,4.422c0.321,0.278 0.608,0.823 0.608,1.657c-0,1.201 -0.011,2.165 -0.011,2.461c0,0.237 0.162,0.517 0.616,0.43c3.558,-1.188 6.122,-4.546 6.122,-8.503c0,-4.948 -4.012,-8.96 -8.96,-8.96Z"
                    style={{
                        fillrule: "nonzero",
                        fill: theme==="light"? lightcolor : darkcolor
                    }}
                />
                <path
                    d="M11.434,21.125c-0.02,0.045 -0.09,0.057 -0.154,0.027c-0.065,-0.027 -0.101,-0.089 -0.08,-0.135c0.019,-0.044 0.089,-0.057 0.154,-0.027c0.065,0.03 0.102,0.09 0.08,0.135Z"
                    style={{
                        fillrule: "nonzero",
                        fill: theme==="light"? lightcolor : darkcolor
                    }}
                />
                <path
                    d="M11.797,21.53c-0.043,0.04 -0.127,0.022 -0.183,-0.04c-0.059,-0.063 -0.07,-0.147 -0.027,-0.188c0.044,-0.039 0.125,-0.019 0.184,0.043c0.059,0.063 0.07,0.145 0.026,0.185Z"
                    style={{
                        fillrule: "nonzero",
                        fill: theme==="light"? lightcolor : darkcolor
                    }}
                />
                <path
                    d="M12.15,22.047c-0.055,0.038 -0.145,0.003 -0.2,-0.077c-0.055,-0.08 -0.055,-0.175 0.001,-0.215c0.055,-0.037 0.144,-0.002 0.2,0.075c0.055,0.083 0.055,0.178 -0.001,0.217Z"
                    style={{
                        fillrule: "nonzero",
                        fill: theme==="light"? lightcolor : darkcolor
                    }}
                />
                <path
                    d="M12.634,22.545c-0.049,0.055 -0.154,0.04 -0.23,-0.035c-0.079,-0.072 -0.101,-0.175 -0.051,-0.227c0.05,-0.055 0.155,-0.041 0.232,0.035c0.078,0.07 0.101,0.174 0.049,0.227Z"
                    style={{
                        fillrule: "nonzero",
                        fill: theme==="light"? lightcolor : darkcolor
                    }}
                />
                <path
                    d="M13.302,22.835c-0.022,0.07 -0.123,0.103 -0.225,0.073c-0.101,-0.033 -0.167,-0.113 -0.147,-0.185c0.021,-0.071 0.123,-0.103 0.225,-0.073c0.101,0.032 0.168,0.113 0.147,0.185Z"
                    style={{
                        fillrule: "nonzero",
                        fill: theme==="light"? lightcolor : darkcolor
                    }}
                />
                <path
                    d="M14.035,22.888c0.002,0.074 -0.084,0.134 -0.19,0.137c-0.107,0.002 -0.194,-0.057 -0.195,-0.13c-0,-0.075 0.084,-0.135 0.191,-0.137c0.107,-0.003 0.194,0.057 0.194,0.13Z"
                    style={{
                        fillrule: "nonzero",
                        fill: theme==="light"? lightcolor : darkcolor
                    }}
                />
                <path
                    d="M14.717,22.772c0.013,0.073 -0.061,0.146 -0.167,0.166c-0.104,0.019 -0.2,-0.025 -0.214,-0.098c-0.012,-0.073 0.063,-0.147 0.167,-0.165c0.106,-0.02 0.201,0.025 0.214,0.097Z"
                    style={{
                        fillrule: "nonzero",
                        fill: theme==="light"? lightcolor : darkcolor
                    }}
                />
            </g>
        </g>
    </svg>
);
