import React from "react";
import { MenuContext } from "../MenuContext/menu-context";
import toArray from "../../../../../common/toArrayReactElements";
import { DynamicWrapper } from "../../../../../components/Parts/Wrapper/dynamic-wrapper";



const MenuItem = (props) => {
    const { className, path, nodeChildren, children, icon, title, index, MenuLinkJSX, MenuItemJSX } = props;
    const {
      prefixCls,
      firstLevel,
      direction,
      disableMenuItemTitleTooltip,
      inlineCollapsed: isInlineCollapsed,
    } = React.useContext(MenuContext);
    
    // const { siderCollapsed } = React.useContext(SiderContext);
  
    let tooltipTitle = title;
  
    if (typeof title === "undefined") {
        tooltipTitle = firstLevel ? children : "";
    } else if (title === false) {
        tooltipTitle = "";
    }
  
    const tooltipProps = { title: tooltipTitle };
  
    // if (!siderCollapsed && !isInlineCollapsed) {
    //   tooltipProps.title = null;
    //   // Reset `open` to fix control mode tooltip display not correct
    //   // ref: https://github.com/ant-design/ant-design/issues/16742
    //   tooltipProps.open = false;
    // }
  
    const childrenLength = toArray(children).length;
  
    // JSX children
    const iconJSX = (icon) =>
        icon? 
            React.cloneElement(icon, {
                className: `${React.isValidElement(icon) ? icon.props?.className : ""} ${prefixCls}-item-icon`,
            }) 
        :
            null

    const renderItemChildren = (inlineCollapsed) => {
        const defaultItemNode = <span className={`${prefixCls? prefixCls : title? `${title}-`:""}title-content nav-link`}><a href={path} title={children}>{children}</a></span>;
        const MenuItemNode = React.isValidElement(MenuItemJSX)?
            <DynamicWrapper component={MenuItemJSX}>
                { 
                    React.isValidElement(MenuLinkJSX)?
                        <DynamicWrapper component={MenuLinkJSX}>
                            {{
                                index: index,
                                path: path,
                                children: children
                            }}
                        </DynamicWrapper>
                    :
                        defaultItemNode
                }
            </DynamicWrapper>
        :
            React.isValidElement(MenuLinkJSX)?
                <DynamicWrapper component={MenuLinkJSX}>
                    {{
                        index: index,
                        path: path,
                        children: children
                    }}
                </DynamicWrapper>
            :
                defaultItemNode
            
        // inline-collapsed.md demo 依赖 span 来隐藏文字,有 icon 属性，则内部包裹一个 span
        // ref: https://github.com/ant-design/ant-design/pull/23456
        if (!icon || (children && React.isValidElement(children))) {
            if (children && inlineCollapsed && firstLevel && children.constructor.name === "String") {
                return <div className={`${prefixCls}-inline-collapsed-noicon`}>{children.charAt(0)}</div>;
            }
        };
        return MenuItemNode;
    };
    
    // Return JSX
    let returnNode = (
        <li
            className={`${className}`}
            title={typeof title === "string" ? title : undefined}
        > 
            {
                iconJSX(icon)
            }
            { renderItemChildren(isInlineCollapsed) }
        </li>
    );

    return returnNode;
  };
  
  export default MenuItem;
