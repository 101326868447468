import React from "react";
import { Link } from "react-router-dom";
import cardMouseEffect from "../../../common/cardMouseEffect";
import { Title } from "../../../components/Parts/TextSections/title-component";




const ProcessDisplay = ({ data, theme, title, subtitle }) => {
  React.useEffect(() => {
    cardMouseEffect(document.querySelectorAll(".feat .items"));
  }, []);
  return (
    <section className="feat sub-bg section-padding">
      <div className="container">
        <Title title={title} subtitle={subtitle} justifyContent={"none"} textAlign={"none"} />
        <div className="row">
          {
            data?.BusinessWorkflow.map((process, key) => {
              return (
                <div key={key} className="col-lg-3 col-md-6 items md-mb30">
                  <div className="item wow fadeIn" data-wow-delay=".3s">
                    <span className="icon">
                      <i className={process.processIconClassName?? ""} style={process.processIcon? {} : { backgroundImage: `url(${process.processIcon})`}}></i>
                    </span>
                    <h5>{process.processTitle}</h5>
                    <p>
                      {process.processDescription}
                    </p>
                    {
                      process.processLinkData? 
                        <Link to={process.processLinkData.link?? "#"}>
                          <div className="more-stroke">
                            <span>{process.processLinkData.title?? ""}</span>
                          </div>
                        </Link>
                      :
                        ""
                    }
                    
                  </div>
                </div>
              );
            }) 
          }
        </div>
      </div>
    </section>
  );
};


export default ProcessDisplay;
