import React from "react";
import { useSelector } from "react-redux";
import axios from "axios";
// Components
import { CardBasic, CardPortraitSM } from "../components/Cards/cards-component";
import ParallaxHeader from "../components/Sections/Headers/parallax-header-container";
import MainContentSection from "../containers/MainContentSection";
import WorksPaginated from "../containers/Sections/Works/works-display";
import CallToAction from "../containers/Sections/CTAs/call-to-action-container";
import { WorksPopup } from "../containers/Sections/Popups/works-popup";




const Works = (props) => {
    const darkThemeEnabled = useSelector((state) => state.preferences.darkThemeEnabled);
    const theme = darkThemeEnabled? "dark" : "light";
    const [pageLoaded, setPageLoaded] = React.useState(false);
    const fixedHeader = React.useRef(null);
    const MainContent = React.useRef(null);
    const [allWorks, getAllWorks] = React.useState([]);

    // Page assets
    const codePic  = "assets/img/backgrounds/closeup-of-code-1.jpg";
    // ===================================================== //

     /* Load Page Content */
     React.useEffect(() => {
        const fetchAllWorks = async () => {
          let response;
          try {
            response = (await axios.get("/v2/content/works")).data["data"];
          } catch(error) {
            return [];
          }
            getAllWorks([
                ...response["web_works"], 
                ...response["art_works"]
            ]);
            
        };
    
        if (allWorks.length <= 0) {
          fetchAllWorks();
        };
        
        setPageLoaded(true);
    }, [pageLoaded, allWorks]);

    React.useEffect(() => {      
        if (fixedHeader.current) {
            var slideHeight = fixedHeader.current.offsetHeight;
        }
        if (MainContent.current) {
            MainContent.current.style.marginTop = slideHeight + "px";
        }
    }, [fixedHeader, MainContent]);

    const PopUp = (data) => WorksPopup({
        data: data
    });
    

    return (
        <>
            <ParallaxHeader 
                sliderRef={fixedHeader} 
                title={"Works"} 
                subtitle={"Seamlessly integrating strategy in artistic and technological works."}
            />
            <MainContentSection
                mcr={MainContent} 
                mcTitle={null} 
                content={
                    <WorksPaginated
                        title={"All Web Projects & Designs."}
                        subtitle={"- Portfolio -"}
                        CardsClassName={"items graphic lg-mr wow fadeInUp"}
                        content={allWorks}
                        CardComponent={CardPortraitSM}
                        searchOption={true}
                        filter={{
                            filterTags: ["Application", "Art/Illustration"],
                            filterByKey: "type"
                        }}
                        PopUpComponent={{ 
                            jsx: (data) => PopUp(data),
                            modalWidth: "100%",
                            modalHeight: "100%"
                        }}
                        skeletonLoader={true} 
                        itemsPerPage={4}
                        gridSize={6}
                        theme={theme}
                    />
                } 
                theme={theme} 
            />
            <CallToAction 
                backgroundImage={codePic} 
                theme={"dark"} 
                invert={false} 
            />
        </>
    );
};

export default Works;
