import React from "react";
import { Link } from "react-router-dom";
import { titleCase } from "../../../utils/titleCase";
import { returnMediaMeta } from "../../../utils/Schemas/returnMediaMeta";
import { CheckMarkInCircle } from "../RawSVGs/IconsSpirits";




const CardBasic1 = (data) => {
    const content = returnMediaMeta(data);
    return(
        <>
            <div className="item-img">
                <div className="cont">
                    <h6>{content.title}</h6>
                    <p>{content.subtitle}</p>
                </div>
                <Link href={content.ctaLink}>
                    <span className="rota" style={{ width: "100%" }}>
                        <img src={content.img} alt={titleCase(content.title)} style={{ visibility: "visible" }}/>
                        <div className="item-img-overlay"></div>
                    </span>
                </Link>
                {
                    Array.isArray(content.tags) && content.tags.length > 0?
                        <div className="tags">
                            { content.tags.map((tag, key)=> <span key={key}><Link href="#">{titleCase(tag)}</Link></span>) }
                        </div>
                    :
                        null
                }           
            </div>
        </>
    );
};


const CardIconBasic1 = ({ data }) => {
    const content = returnMediaMeta(data);
    return (
        <div className="item mb-30" style={{ height: "100%" }}>
            <div className="item-tit mb-15">
                { 
                    content?.img || content?.icon? 
                        <div className="icon">
                            {
                                content.icon?
                                    React.isValidElement(content.icon)?
                                        content.icon
                                    :
                                        <span className={`pe-7s-${content.icon}`}></span>
                                :
                                    content.img
                            }
                        </div>
                    :
                        ("") 
                }
                {
                    content?.title?
                        (
                            <div className="text-tit">
                                <h5>{content.title}</h5>
                            </div>
                        )
                    :
                        ("")
                }
            </div>
            {
                content?.description?
                    <p>
                        { content.description }
                    </p>
                :
                    ("")
            }
        </div>
    );
};


const CardPricingBasic1 = ({ data }) => {
    const content = returnMediaMeta(data);
    const defaultCTA = "";

    const DefaultAttributeJSX = ({ content, ...props }) => (
        <ul className="card-pricing attr-section">
            <li className="attr-item d-flex">
                <div className="d-flex" style={{ display: "flex", alignItems: "top" }}>
                    <i className="icon">
                        <CheckMarkInCircle />
                    </i>
                </div>
                <div>
                    { content }
                </div>
            </li>
        </ul>
    );

    return (
        <div className="item sm-mb50">
            <div className="type text-center mb-15">
                <h5>{content?.title}</h5>
            </div>
            <div className="amount text-center mb-40">
                <h3><span>{content?.price?.currency}</span> { content?.price?.value } </h3>
            </div>

            { 
                content?.CTA? 
                    <div className="order mb-40">
                        <a href="#0" className="butn-gray rounded buton">
                            <span>{content?.CTA? content.CTA : defaultCTA }</span>
                        </a>
                    </div>
                :
                    ("")
            } 
            
            {
                content?.description?
                    <p>
                        { content.description }
                    </p>
                :
                    ("")
            }
            {
                content?.attributes?
                    <div className="feat">
                        <ul>
                            {
                                Array.isArray(content.attributes)?
                                    content.attributes.map((attribute, key) => {
                                        return (
                                            <DefaultAttributeJSX content={attribute} key={key}/>
                                        );
                                    })
                                :
                                    <DefaultAttributeJSX content={content.attributes}/>
                            }
                        </ul>
                    </div>
                :
                    ("")
            }
        </div>
    );
};


const CardPricingBasic2 = ({ data, index, includeStartingAt=true }) => {
    const content = returnMediaMeta(data);
    const defaultCTA = "";

    const [ isPriceAccordOpen, setPriceAccordOpen ] = React.useState(false);

    const DefaultAttributeJSX = ({ content, ...props }) => (
        <ul className="card-pricing attr-section">
            <li className="attr-item d-flex">
                <div className="d-flex" style={{ display: "flex", alignItems: "top" }}>
                    <i className="icon">
                        <CheckMarkInCircle />
                    </i>
                </div>
                <div>
                    { content }
                </div>
            </li>
        </ul>
    );

    React.useEffect(() => {
    }, [isPriceAccordOpen]);

    return (
        <div className="item sm-mb50" style={{ height: isPriceAccordOpen? "100%" : "auto" }}>
            <div className="type text-center mb-15">
                <h5>{content?.title}</h5>
            </div>
            <div className="amount text-center mb-40">
                { includeStartingAt? <h6>Starting at</h6> : "" }
                <h3><span>{content?.price?.currency}</span> { content?.price?.value } </h3>
            </div>

            { 
                content?.CTA? 
                    <div className="order mb-40">
                        <a href="#0" className="butn-gray rounded buton">
                            <span>{content?.CTA? content.CTA : defaultCTA }</span>
                        </a>
                    </div>
                :
                    ("")
            } 
            
            {
                content?.description?
                    <p>
                        { content.description }
                    </p>
                :
                    ("")
            }
            {
                content?.attributes?
                    <div className="accordion">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button 
                                    className="accordion-button" 
                                    type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target={`#collapse-${index+1}`} 
                                    aria-expanded={isPriceAccordOpen}
                                    aria-controls={`collapse-${index+1}`}
                                    onClick={() => { 
                                        isPriceAccordOpen? 
                                            setPriceAccordOpen(false)
                                        :
                                            setPriceAccordOpen(true)
                                    }}
                                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                                >
                                    { isPriceAccordOpen? `Hide info -` : `More info +` }
                                </button>
                            </h2>

                            <div id={`collapse-${index+1}`} className="accordion-collapse collapse feat">
                                <div className="accordion-body" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <ul>
                                        {
                                            Array.isArray(content.attributes)?
                                                content.attributes.map((attribute, key) => {
                                                    return (
                                                        <DefaultAttributeJSX content={attribute} key={key}/>
                                                    );
                                                })
                                            :
                                                <DefaultAttributeJSX content={content.attributes}/>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    ("")

            }
            {
                <Link className={"butn-bord-red rounded buton mt-30"} to={`/contact/start-a-project?selected_service=${content?.title}`}>Select ></Link>
            }
        </div>
    );
};


const CardPortraitSM = ({ data }) => {
    const content = returnMediaMeta(data);
    return(
        <div className="portrait-sm">
            <div className="item-img">
                <Link href={content.ctaLink}>
                    <span className="rota" style={{ width: "100%" }}>
                        <img onError={
                            e => {
                                e.currentTarget.src = "assets/img/placeholders/placeholder-view-vector-square.svg"
                            }} 
                            src={content.img} 
                            alt={titleCase(content.title)} 
                        />
                        <div className="item-img-overlay"></div>
                    </span>
                </Link>
            </div>
            <div className="cont">
                <h6>{content.title}</h6>
                {
                    Array.isArray(content.tags) && content.tags.length > 0
                    ?
                        <div className="tags">
                            { content.tags.map((tag, key)=> <span key={key}><Link href="#">{titleCase(tag)}</Link></span>) }
                        </div>
                    :
                        null
                }
            </div>
        </div>
    );
};


const CardPortraitLG = ({ title, subtitle, ctaLink, img }) => {

};

export { 
    CardBasic1,
    CardIconBasic1,
    CardPricingBasic1,
    CardPricingBasic2,
    CardPortraitSM, 
    CardPortraitLG 
};
