/* eslint-disable @next/next/no-img-element */
import React from "react";
import { Split } from "../../../components/Parts/Split";
import { HashLink } from "react-router-hash-link";
import { thumparallaxDown } from "../../../common/thumparallax";
import { GengarHackingSVG } from "../../../components/RawSVGs/Gengar";




const AboutMeWithPortrait = ({ data, theme }) => {
  React.useEffect(() => {
    setTimeout(() => {
      thumparallaxDown();
    }, 1000);
  }, []);
  return (
    <section className="about-cr">
      <div className="container">
        <div className="row">
          {/* <div className="gengar-container col-lg-6 img md-mb50" style={{ display: "flex", padding: "0px" }}> */}
          <div className="col img animated-portrait-container order-2 order-md-1" style={{ display: "flex", padding: "0px", position: "relative" }}>
            <GengarHackingSVG height={"100%"} backgroundHeightPX={"5000"} />
          </div>
          <div className="cont-container col valign order-1 order-md-2">
            <div className="cont full-width h-100">
              <h3 className="saying-font">Dream. Innovate. Implement.</h3>
              <div className="content">
                <Split>
                  <p className="wow txt words chars splitting" data-splitting>
                    DesignBytes, "Design" for designing things while paying close attention to detail and "Bytes" which represents the computer memory our digital designs and unique software solutions occupy. The core goal of DesignBytes is to provide immersive digital experiences and future-sound software solutions for businesses across 70+ industries. Our project oriented nature always keep us hacking away just like this purple guy drawn and animated in code by us.
                  </p>
                </Split>
                {/* 
                <ul>
                  <li className="wow fadeInUp" data-wow-delay=".4s">
                    Building custom/web software systems.
                  </li>
                  <li className="wow fadeInUp" data-wow-delay=".2s">
                    Creating custom pipelines for data.
                  </li>
                  <li className="wow fadeInUp" data-wow-delay=".4s">
                    Custom designs dealing with art and UX/UI. For example this little purple fella (Gengar) nested in this div I designed and animated with code.
                  </li>
                </ul> 
                */}
                <HashLink to={`/about#my_story`}>
                  <div
                    className="butn bord normal curve mt-40 wow fadeInUp"
                    data-wow-delay=".8s"
                  >
                    <span>Discover</span>
                  </div>
                </HashLink>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMeWithPortrait;
