import { saveAs } from "file-saver";
import axios from "axios";


export function downloadFile(endpoint) {
    let filename;
    axios.get(endpoint, {
      responseType: "blob"
    })
    .then(response => {
      const disposition = response.headers["content-disposition"];
      filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
      if (filename.toLowerCase().startsWith("utf-8''"))
          filename = decodeURIComponent(filename.replace("utf-8''", ""));
      else
          filename = filename.replace(/['"]/g, "");
      return response.data;
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      saveAs(url, filename);
    })
    .catch(error => {
      console.error(error);
    });
};
