import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import { Autoplay, Navigation } from 'swiper/modules';
import { shuffleArray } from "../../../utils/ArrayMethods/shuffleArray";
import { SkeletonIconCardBasic } from "../Skeletons/skeleton-card-icon-component";
import { Title } from "../Parts/TextSections/title-component";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";




// SwiperCore.use([Autoplay]);


const CardsDisplaySwiper2 = ({ 
  content, 
  maxWorks, 
  skeletonLoader=true, 
  title, 
  subtitle, 
  theme, 
  breakpoints
}) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const randomizeArr = (arr, maxWorks) => [...shuffleArray(arr)].splice(0, maxWorks);
  // Use memo to preserve content and its order
  const WORKS = React.useMemo(() => randomizeArr(content, maxWorks), [content, maxWorks]);
  return (
    <section className="work-carousel metro position-re section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <Title title={title} subtitle={subtitle}/>
          </div>
        </div>
      </div>
      
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 no-padding">
            <div className="swiper-container">
              {
                content.length > 0?
                  <Swiper
                    className="swiper-wrapper"
                    modules={[Autoplay, Navigation]}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    speed={1000}
                    updateOnWindowResize={true}
                    breakpoints={breakpoints}
                  >
                    {
                      WORKS
                      .map((item, index) => { 
                        return(
                          <SwiperSlide className="swiper-slide" key={index}>
                            <div
                                className="content wow noraidus fadeInUp"
                                data-wow-delay=".3s"
                            >
                                <div
                                    className="item-img bg-img wow imago animated"
                                    style={{
                                        backgroundImage: `url(${item.Metadata.URI ?? ""})`,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        visibility: "visible",
                                    }}
                                > 
                                </div>
                                <div className="cont">
                                    <h6 className="color-font">
                                        {item.Metadata.type ?? ""}
                                    </h6>
                                    <h4>
                                        <Link
                                            href={item.Metadata.name ?? ""}
                                        >
                                            {item.Metadata.name ?? ""}
                                        </Link>
                                    </h4>
                                </div>
                            </div>
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  :
                    skeletonLoader?
                      <div className="skeleton-swiper">
                        <Swiper
                          className="swiper-wrapper"
                          centeredSlides={true}
                          loop={true}
                          speed={1000}
                          updateOnWindowResize={true}
                          breakpoints={breakpoints}
                        >
                          { 
                            Array(maxWorks).fill().map((skeleton, index) => 
                              <SwiperSlide className="swiper-slide" key={index}>
                                <SkeletonIconCardBasic key={index}/>
                              </SwiperSlide>
                            )
                          }
                        </Swiper>
                      </div>
                    :
                      null
                }
             <div
                ref={navigationNextRef}
                className="swiper-button-next swiper-nav-ctrl simp-next cursor-pointer"
              >
                <span className="simple-btn right">Next</span>
              </div>
              <div
                ref={navigationPrevRef}
                className="swiper-button-prev swiper-nav-ctrl simp-prev cursor-pointer"
              >
                <span className="simple-btn">Prev</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { 
  CardsDisplaySwiper2
};
