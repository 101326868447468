export const returnMediaMeta = (_data) => {
    /* 'data' variable changes based on API data schema */
    const data = _data.Metadata?? _data;

    const res =  { 
        title: (data.name || data.title)?? "",
        subtitle: data.stack?? "",
        description: data.description?? "",
        attributes: data.attributes?? [],
        price: { 
            currency: data?.price?.currency?? "",
            value: data?.price?.value?? ""
        },
        icon: data.icon?? "",
        img: Object.hasOwn(data, "type") && ( data.type === "Application" && Object.hasOwn(data, "website-link") )? 
            `${data["website-link"]}/favicon.ico` 
            : 
            data.URI?? "#",
        type: data.type?? "",
        tags: data.tags?? [],
        websiteLink: data["website-link"]?? "#",
        ctaLink: data.cta_link?? "#",
        workPublishDate: data.workPublishDate?? ""
    };

    return res;
};
