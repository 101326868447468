/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React from "react";
import {Routes, Route, BrowserRouter, Navigate, HashRouter} from "react-router-dom";
import * as pages from  "../pages";
import {App} from "../App";
import mapDirsToTree from "../../utils/mapDirsToTree";


// console.log(pages)

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env;
const PAGE_URLS = mapDirsToTree(pages, false);
// const PAGE_URLS = {};
// Object.entries(pages).map(page => {
//   // Folder/
//   const isDir = (item) => item.split();
//   return PAGE_URLS[page[0]] = `/${ page[0] === "Home"? "": page[0].toLowerCase()}`;
// });


const AppRoutes = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          { 
            Object.entries(pages)
            .map((page, page_idx) => { 
              const [_PageName, Component] = page
              const PageName = `/${ _PageName === "Home"? "": _PageName.toLowerCase() }`
              return(
                <Route 
                  index key={page_idx} 
                  path={PageName} 
                  element={ <Component siteURLs={PAGE_URLS} /> } 
                />
            )}) 
          }
          <Route path="*" element={<Navigate to="/" />} /> 
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes, PAGE_URLS };
