import React from "react";
/* The MDN mandala of characters or "Character" like mandala belongs to MDN and is only used in this website for UI purposes and homage to MDN for its long history of elite documentation of web technologies. All credit goes to its creators.*/
export const MDNCharMandala = ({theme, invert, forceColor }) => {
    let evalFill;
    if (invert) {
        if (theme.toLowerCase() === "light") evalFill = "dark"
        if (theme.toLowerCase() === "dark") evalFill = "dark"
    } else if (forceColor) evalFill = forceColor
    else {
        evalFill = theme;
    }
    
    return(
        <div className="mandala-container  homepage-hero-bg" aria-hidden="true">
            <div className="mandala mandala-translate mandala-rotate">
                <svg width="100%" height="100%" viewBox="0 0 675 675" fill={ evalFill }
                    xmlns="http://www.w3.org/2000/svg" className="mandala">
                    <title>Mandala</title>
                    <defs>
                        <path d="M337.5,337.5 m-320,0 a320,320 0 1,1 640,0 a320,320 0 1,1 -640,0" id="circle1"></path>
                        <path d="M337.5,337.5 m-280,0 a280,280 0 1,1 560,0 a280,280 0 1,1 -560,0" id="circle2"></path>
                        <path d="M337.5,337.5 m-240,0 a240,240 0 1,1 480,0 a240,240 0 1,1 -480,0" id="circle3"></path>
                        <path d="M337.5,337.5 m-200,0 a200,200 0 1,1 400,0 a200,200 0 1,1 -400,0" id="circle4"></path>
                        <path d="M337.5,337.5 m-160,0 a160,160 0 1,1 320,0 a160,160 0 1,1 -320,0" id="circle5"></path>
                    </defs>
                    <text className="mandala-accent-1" dy="70" textLength="2010">
                        <textPath textLength="2010" href="#circle1">&nbsp;&nbsp;&nbsp;/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/
                            </tspan>/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>&nbsp;&nbsp;&nbsp;/<tspan>/</tspan>/<tspan>/
                            </tspan>/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>&nbsp;&nbsp;&nbsp;/<tspan>/
                            </tspan>/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>
                            &nbsp;&nbsp;&nbsp;/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>/
                            <tspan>/</tspan>&nbsp;&nbsp;&nbsp;/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>/
                            <tspan>/</tspan>/<tspan>/</tspan>&nbsp;&nbsp;&nbsp;/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>/
                            <tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>&nbsp;&nbsp;&nbsp;/<tspan>/</tspan>/<tspan>/</tspan>/
                            <tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>/<tspan>/</tspan>
                        </textPath>
                    </text>
                    <text className="mandala-accent-2" dy="70" textLength="1760">
                        <textPath textLength="1760" href="#circle2">&nbsp;&nbsp;+<tspan>+</tspan>+<tspan>+</tspan>+<tspan>+</tspan>
                            &nbsp;&nbsp;+<tspan>+</tspan>+<tspan>+</tspan>+<tspan>+</tspan>&nbsp;&nbsp;+<tspan>+</tspan>+<tspan>+
                            </tspan>+<tspan>+</tspan>&nbsp;&nbsp;+<tspan>+</tspan>+<tspan>+</tspan>+<tspan>+</tspan>&nbsp;&nbsp;+
                            <tspan>+</tspan>+<tspan>+</tspan>+<tspan>+</tspan>&nbsp;&nbsp;+<tspan>+</tspan>+<tspan>+</tspan>+<tspan>
                                +</tspan>&nbsp;&nbsp;+<tspan>+</tspan>+<tspan>+</tspan>+<tspan>+</tspan>
                        </textPath>
                    </text>
                    <text className="mandala-accent-3" dy="70" textLength="1507">
                        {/* <textPath textLength="1507" href="#circle3">
                            <tspan>{</tspan>{<tspan>{</tspan>{&nbsp;<tspan>}</tspan>}<tspan>}</tspan>}&nbsp;&nbsp;<tspan>{</tspan>{
                            <tspan>{</tspan>{&nbsp;<tspan>}</tspan>}<tspan>}</tspan>}&nbsp;&nbsp;<tspan>{</tspan>{<tspan>{</tspan>
                            {&nbsp;<tspan>}</tspan>}<tspan>}</tspan>}&nbsp;&nbsp;<tspan>{</tspan>{<tspan>{</tspan>{&nbsp;<tspan>}
                            </tspan>}<tspan>}</tspan>}&nbsp;&nbsp;<tspan>{</tspan>{<tspan>{</tspan>{&nbsp;<tspan>}</tspan>}<tspan>}
                            </tspan>}&nbsp;&nbsp;<tspan>{</tspan>{<tspan>{</tspan>{&nbsp;<tspan>}</tspan>}<tspan>}</tspan>
                            }&nbsp;&nbsp;
                        </textPath> */}
                        <textPath textLength="1507" href="#circle3">
                            <tspan>1</tspan>1<tspan>1</tspan>1&nbsp;<tspan>0</tspan>0<tspan>0</tspan>0&nbsp;&nbsp;<tspan>1</tspan>1
                            <tspan>1</tspan>1&nbsp;<tspan>0</tspan>0<tspan>0</tspan>0&nbsp;&nbsp;<tspan>1</tspan>1<tspan>1</tspan>
                            1&nbsp;<tspan>0</tspan>0<tspan>0</tspan>0&nbsp;&nbsp;<tspan>1</tspan>1<tspan>1</tspan>1&nbsp;<tspan>0
                            </tspan>0<tspan>0</tspan>0&nbsp;&nbsp;<tspan>1</tspan>1<tspan>1</tspan>1&nbsp;<tspan>0</tspan>0<tspan>0
                            </tspan>0&nbsp;&nbsp;<tspan>1</tspan>1<tspan>1</tspan>1&nbsp;<tspan>0</tspan>0<tspan>0</tspan>
                            0&nbsp;&nbsp;
                        </textPath>
                    </text>
                    <text className="mandala-accent-4" dy="70" textLength="1257">
                        <textPath textLength="1257" href="#circle4">&nbsp;&nbsp;&nbsp;../../ &nbsp;&nbsp;&nbsp;../../
                            &nbsp;&nbsp;&nbsp;../../ &nbsp;&nbsp;&nbsp;../../ &nbsp;&nbsp;&nbsp;../../ &nbsp;&nbsp;&nbsp;../../
                            &nbsp;&nbsp;&nbsp;../../</textPath>
                    </text>
                    <text className="mandala-accent-5" dy="70" textLength="1005">
                        <textPath textLength="1005" href="#circle5">
                            <tspan>&lt;&gt;</tspan>&lt;/&gt;<tspan>&lt;&gt;</tspan>&lt;/&gt;<tspan>&lt;&gt;</tspan>&lt;/&gt;<tspan>
                                &lt;&gt;</tspan>&lt;/&gt;<tspan>&lt;&gt;</tspan>&lt;/&gt;<tspan>&lt;&gt;</tspan>&lt;/&gt;<tspan>
                                &lt;&gt;</tspan>&lt;/&gt;<tspan>&lt;&gt;</tspan>&lt;/&gt;<tspan>&lt;&gt;</tspan>&lt;/&gt;<tspan>
                                &lt;&gt;</tspan>&lt;/&gt;<tspan>&lt;&gt;</tspan>&lt;/&gt;<tspan>&lt;&gt;</tspan>&lt;/&gt;<tspan>
                                &lt;&gt;</tspan>&lt;/&gt;<tspan>&lt;&gt;</tspan>&lt;/&gt;<tspan>&lt;&gt;</tspan>&lt;/&gt;<tspan>
                                &lt;&gt;</tspan>&lt;/&gt;
                        </textPath>
                    </text>
                </svg>
            </div>
        </div>
    );
};
