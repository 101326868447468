import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
// My module imports
import site_config from "../../../../config/site.config";
import handleFormData from "../../common/handleFormData";
import { ReactHookFormError } from "../../components/Parts/Errors/Forms/form-errors";
import { LeaveMessageFormSchema } from "../../../utils/Schemas/Yup/schemas";
import { SUBMIT_SUCCESS_TOAST_THEME, SUBMIT_FAILURE_TOAST_THEME } from "../../components/component_configs/toast-configs";
import { FORM_INPUT_CONSTANTS } from "../../../utils/Schemas/Yup/constants";
import useRecaptcha from "../../../utils/googleReCaptcha";
import { LinkedIn, Github } from "../../components/RawSVGs/IconsSpirits";




const GeneralContactForm = ({ contactCardData, theme }) => {
    // Static constants
    const MESSAGE_MIN = FORM_INPUT_CONSTANTS.MESSAGE.min;
    const MESSAGE_MAX = FORM_INPUT_CONSTANTS.MESSAGE.max;
    const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();
    // Dynamic constants
    const [ isFormOpen, setFormOpen ] = React.useState(false);
    const [ submitting, setSubmitting ] = React.useState(false);
    const {
        watch,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(LeaveMessageFormSchema()),
    });

    const onSubmit = (values, ms=500) => new Promise(
        (r) => { 
            setTimeout(
                r(axios.post("/v2/contact/general", 
                    values // OR use stringify (JSON.stringify(values, null, 2)) if not using formdata POST --> JSON.stringify(values, null, 2)
                )), 
                ms
            );
        }
    ).then((res) => {
         toast(
            "Your Message has been successfully sent. I will contact you soon.", 
            SUBMIT_SUCCESS_TOAST_THEME({ theme: theme })
        ); 
        reset();
    })
    .catch((err) => { 
        if (err) {
            let toastTXT = null;
            const { 
                code: err_code, 
                config: err_config, 
                message: err_message, 
                name: err_name, 
                request: err_request, 
                response: err_response, 
                stack: err_stack 
            } = err;
            console.log(err);
            const { 
                data: err_res_data,
                status: err_res_status,
                statusText: err_res_statusText,
                headers: err_res_headers,
                config: err_res_config,
                request: err_res_request
            } = err_response;

            function parseDetails(details) {
                if (Array.isArray(details)) {
                    // errorTXT = details.length > 1? errorTXT + "S:" : errorTXT+":";
                    let TXTparts = [];
                    details.forEach((_detail, idx) => {
                        let { ctx, loc, msg, type } = _detail;
                        type = type.toLowerCase();
                        loc = Array.isArray(loc) && loc.length > 1? `${(loc[loc.length-1]).replaceAll("_", " ")}` : "";
                        msg = msg?? "";
                        if( (type.includes("min") || type.includes("max")) && type.includes("length")) {
                            TXTparts.push(`"${loc}": ${msg[msg.length-1]==="."? msg: msg+"."}`);
                        };
                        if(type.includes("required")) {
                            TXTparts.push(`"${loc}":${msg[msg.length-1]==="."? msg: msg+"."}`);
                        };
                    });
                    // return errorTXTparts.join("\r\n");
                    return TXTparts;
                };
            };
            if (err_res_status === 422) {
                if ( (Object.keys(err_res_data)).includes("detail") ) {
                    const detail = err_res_data.detail;
                    toastTXT = parseDetails(detail);
                };
                Array.isArray(toastTXT)?
                    toastTXT.forEach(txt => toast(
                        "ERROR: \r\n" + txt, 
                        SUBMIT_FAILURE_TOAST_THEME({ theme: theme })
                    ))
                    :
                    toast(
                        toastTXT, 
                        SUBMIT_FAILURE_TOAST_THEME({ theme: theme })
                    )
                ;
            } else {
                toast(
                    "ERROR: \r\n" + err_message, 
                    SUBMIT_FAILURE_TOAST_THEME({ theme: theme })
                )
            };
        }
     });

    React.useEffect(() => {
    }, [isFormOpen]);

    return (
        <section className="contact general-form section-padding" data-bs-theme={theme} id="general-contact-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form md-mb50 accordion">
                            <Link to={`/contact/start-a-project`} style={{ width: "100%", display: "block"}}>
                                <div
                                    className="butn bord normal curve wow fadeInUp"
                                    data-wow-delay=".8s"
                                >
                                    <span>Start A Project</span>
                                </div>
                            </Link>

                            <div className="row or">
                                <hr className="col-5"></hr>
                                <h6 className="col-2 stit">  Or </h6>
                                <hr className="col-5"></hr>
                            </div>
                           
                            <div
                                className={`butn bord ${isFormOpen? '' : 'normal'} curve mt-40 wow fadeInUp accordion-item`}
                                data-wow-delay=".8s"
                                aria-expanded={false}
                            >
                                <button 
                                    className="btn-small accordion-button collapsed shadow-none" 
                                    type="button" 
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-general-contact-form-accordion-${1}`} 
                                    aria-expanded={isFormOpen}
                                    aria-controls={`collapse-general-contact-form-accordion-${1}`}
                                    onClick={(e) => {
                                        let ariaTrue = e.target.getAttribute("aria-expanded");
                                        ariaTrue==="true"?
                                            setFormOpen(true)
                                        :
                                            setFormOpen(false)
                                    }}
                                >
                                    <span>Leave us a note</span>    
                                </button>
                                
                                <div
                                    id={`collapse-general-contact-form-accordion-${1}`}
                                    className={"general-contact-form accordion-collapse collapse mt-20"} 
                                >
                                    <form 
                                        id="contact-form" 
                                        onSubmit={handleSubmit(async (data) => {
                                            setSubmitting(true);
                                            data["gcaptcha_token"] = capchaToken;
                                            data = handleFormData(data);
                                            await onSubmit(data);
                                            setSubmitting(false);
                                        })}
                                    >
                                        <div className="controls">
                                            <div className="form-group mb-15">
                                                <input
                                                    id="visitor_name"
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name*"
                                                    defaultValue=""
                                                    {...register("name", { 
                                                        required: true 
                                                    })}
                                                />
                                                <ReactHookFormError errorObj={errors.name} />
                                            </div>
                                            <div className="form-group mb-15">
                                                <input
                                                    id="email"
                                                    type="text"
                                                    name="email"
                                                    placeholder="Email*"
                                                    defaultValue=""
                                                    aria-invalid={errors.email? "true" : "false"}
                                                    {
                                                        ...register(
                                                            "email", 
                                                            { 
                                                                required: true, 
                                                                // pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                                                                pattern: /^\S+@\S+$/i
                                                            }
                                                        )
                                                    }
                                                />
                                                <ReactHookFormError errorObj={errors.email} />
                                            </div>
                                            <div className="form-group mb-40">
                                                <input
                                                    id="subject"
                                                    type="text"
                                                    name="subject"
                                                    placeholder="Subject"
                                                    defaultValue=""
                                                    {...register("subject", { 
                                                        required: false 
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mb-40">
                                            <span style={{ color: watch("message") && watch("message").length > MESSAGE_MAX? "red" : "" }}>
                                                {watch("message")? watch("message").length : 0}/{MESSAGE_MAX}
                                            </span>
                                            <textarea
                                                id="message"
                                                name="message"
                                                placeholder="Message"
                                                rows="4"
                                                defaultValue=""
                                                {...register("message", { 
                                                    required: true 
                                                })}
                                            />
                                            <ReactHookFormError errorObj={errors.message} />
                                        </div>
                                        <div className="form-group mb-40">
                                            <div className="captcha" style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>
                                                <ReCAPTCHA
                                                    className={"g-recaptcha"}
                                                    ref={recaptchaRef}
                                                    sitekey={site_config.Google_ReCAPTCHA_site_key}
                                                    onChange={handleRecaptcha}
                                                />
                                            </div>
                                        </div>

                                        <input 
                                            value={submitting? "Submitting..." : "Submit"} 
                                            type="submit" 
                                            className="butn bord" 
                                            style={{display: "inline-block"}} 
                                            disabled={submitting || !capchaToken}
                                        />
                                    </form>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="col-lg-5 offset-lg-1">
                        <div className="cont-info">
                            <h4 className="fw-700 color-font mb-50">{contactCardData?.title?? "Contact Me"}</h4>
                            <h3 className="wow" data-splitting>
                                {contactCardData?.contactSection?.title?? "How to Reach Me."}
                            </h3>
                            <div className="item mb-40">
                                <h5>
                                    <a href="#0">{contactCardData?.contactSection?.email?? "Email@example.com"}</a>
                                </h5>
                                <h5>{contactCardData?.contactSection?.phone?? "+4.930.705.5448"}</h5>
                            </div>
                            <h3 className="wow" data-splitting>
                                {contactCardData?.addressSection?.title?? "Address"}
                            </h3>
                            <div className="item">
                                <h6>
                                    {contactCardData?.addressSection?.address?? "295 Witting Streets Suite 666, Melbourne, Australia"}
                                </h6>
                            </div>
                            <div className="social mt-50">
                                <Link to="https://www.linkedin.com/in/chadgresham" target="_blank" className="icon">
                                    <LinkedIn theme={theme} lightcolor={"#000000"} darkcolor={"#fefefe"} />
                                </Link>
                                <Link to="https://github.com/xero-one" target="_blank" className="icon">
                                    <Github theme={theme} lightcolor={"#000000"} darkcolor={"#fefefe"} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GeneralContactForm;
