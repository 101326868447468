import React from "react";




const CustomizableHeader = ({ title, subTitle, sliderRef, backgroundJSX }) => {
    const Subtitle = () => subTitle;
    const BackGround = () => backgroundJSX;
    return (
        <header
            ref={sliderRef}
            className="pages-header circle-bg valign position-re"
        >
            {/* 
                <div className="circle-color">
                    <div className="gradient-circle"></div>
                    <div className="gradient-circle two"></div>
                </div> 
            */}
            <div className="container" style={{ zIndex: 10}}>
                <div className="row justify-content-center">
                    <div className="col-lg-9 col-md-11">
                        <div className="capt">
                            <div className="text-center">
                                <h1 className="color-font mb-10 fw-700">
                                    {
                                        Array.isArray(title) && title.length > 0
                                            ? [title[0], (<br key={0}/>), title[1]]
                                            : title
                                    }
                                </h1>
                                { <Subtitle /> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            { <BackGround /> }
        </header>
    );
};

export default CustomizableHeader;
