import React, { forwardRef } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import getRandomInt from "../../common/getRandomNumber";
import SkeletonWrapper from "./skeleton-wrapper";




const SkeletonResumeNode = forwardRef(({ index, nodeArr, theme, id=null}, ref) => {
    // const color = theme==="light" ? "#202020" : "#202020"
    return(
        <div id={id} className="xp-node row" key={index} data-skel-resume-node-index={index} ref={ref}>
            <div className="xp col-md-12">
                <div className="xp-node-bullet-tail col-2" style={{ justifyContent: nodeArr.length-1 === index? "flex-start" : "center" }} >
                    <div className="fancey-bullet-img default no-color" style={{ alignItems: "stretch" }}>
                        <SkeletonWrapper>
                            <Skeleton containerClassName="skeleton-wrap" width={"100%"} height={"100%"}/>
                        </SkeletonWrapper>
                    </div>
                    {
                        nodeArr.length-1 === index? 
                        null
                        :
                        <div className="xp-node-tail row">
                            <span className="xp-v-line col-lg-12 no-color"></span>
                        </div>
                    }
                </div>
                <div className="xp-body col-10">
                    <div className="job-date">
                        <h6>
                            <SkeletonWrapper>
                                <Skeleton containerClassName="skeleton-wrap" width={"40%"} height={"100%"}/>
                            </SkeletonWrapper>
                        </h6>
                    </div>
                    <div className="job-title">
                        <h5>
                            <SkeletonWrapper>
                                <Skeleton containerClassName="skeleton-wrap" width={`${getRandomInt(60, 80)}%`} height={`100%`} />
                            </SkeletonWrapper>
                        </h5>
                    </div>
                    <span className="wow txt words chars splitting" data-splitting>
                        <SkeletonWrapper>
                            <Skeleton containerClassName="skeleton-wrap" width={`${getRandomInt(20, 40)}%`} height={`100%`} />
                        </SkeletonWrapper>
                    </span>  
                </div>
            </div>
        </div>
    );
});

export { 
    SkeletonResumeNode
};
