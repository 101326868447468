import * as yup from "yup";
import { FORM_INPUT_CONSTANTS } from "./constants";
import SPECIAL_VALIDATION_API_NAMES from "./actions";
import { checkFilesCount, checkIfFilesAreTooBig, checkIfFilesAreCorrectType } from "./validators";





const LeaveMessageFormSchema = () => {
    const NAME_MIN = FORM_INPUT_CONSTANTS.NAME.min;
    const NAME_MAX = FORM_INPUT_CONSTANTS.NAME.max;
    const MESSAGE_MIN = FORM_INPUT_CONSTANTS.MESSAGE.min;
    const MESSAGE_MAX = FORM_INPUT_CONSTANTS.MESSAGE.max;

    return yup
    .object().shape({
        name: yup.string().required().min(NAME_MIN).max(NAME_MAX),
        email: yup.string().email().required(),
        subject: yup.string(),
        message: yup.string().min(MESSAGE_MIN).max(MESSAGE_MAX)
    });
};


const StartAProjectFormSchema = () => {
    const NAME_MIN = FORM_INPUT_CONSTANTS.NAME.min;
    const NAME_MAX = FORM_INPUT_CONSTANTS.NAME.max;
    const BUSINESS_NAME_MIN = FORM_INPUT_CONSTANTS.BUSINESS_NAME.min;
    const BUSINESS_NAME_MAX = FORM_INPUT_CONSTANTS.BUSINESS_NAME.max;
    const DESCRIPTION_MIN = FORM_INPUT_CONSTANTS.DESCRIPTION.min;
    const DESCRIPTION_MAX = FORM_INPUT_CONSTANTS.DESCRIPTION.max;
    const CONCEPT_FILES_MAX_COUNT = FORM_INPUT_CONSTANTS.CONCEPT_FILES.max;
    const HOW_YOU_FOUND_US_MIN = FORM_INPUT_CONSTANTS.HOW_YOU_FOUND_US.min;
    const HOW_YOU_FOUND_US_MAX = FORM_INPUT_CONSTANTS.HOW_YOU_FOUND_US.max;
    const MESSAGE_MIN = FORM_INPUT_CONSTANTS.MESSAGE.min;
    const MESSAGE_MAX = FORM_INPUT_CONSTANTS.MESSAGE.max;

    const TOO_MANY_FILES = SPECIAL_VALIDATION_API_NAMES[0];
    const FILE_TO_BIG = SPECIAL_VALIDATION_API_NAMES[1];
    const INCORRECT_FILE = SPECIAL_VALIDATION_API_NAMES[2];

    return yup
    .object().shape({
        name: yup.string()
            .required(
                "Name is required!"
            )
            .min(
                NAME_MIN, 
                `Name must be at least ${NAME_MIN} characters.`
            )
            .max(
                NAME_MAX,
                `Name is too long.`
            ),
        email: yup.string()
            .email("This is not a valid email!")
            .required("Email is required!"),
        business_name: yup.string()
            .required(
                "Please provide a business name (of some sort)."
            )
            .min(
                BUSINESS_NAME_MIN,
                `Business name must be at least ${BUSINESS_NAME_MIN} characters.`
            )
            .max(
                BUSINESS_NAME_MAX,
                `Business name is too long.`
            )
            ,
        project_description: yup.string()
            .required(
                "Please provide a project description."
            )
            .min(
                DESCRIPTION_MIN,
                `Project description must be at least ${DESCRIPTION_MIN} characters.`,
            )
            .max(
                DESCRIPTION_MAX,
                `Project description is too long. ${DESCRIPTION_MAX} characters are only allowed.`
            ),
        project_budget: yup.lazy((value) => (
            value === ""
                ? yup.string()
                    .required(
                        "Project budget is required. This gives us an idea of how much budget we can work with for this project."
                    ) 
                : yup.number().positive("Project budget must be a positve value.")
            )),
        project_deadline: yup.date(),
        project_target_audience: yup.string(),
        concept_files: yup.mixed()
            .test(
                TOO_MANY_FILES,
                "Too many files.",
                (values) => values.length? checkFilesCount(values, CONCEPT_FILES_MAX_COUNT) : true
            )
            .test(
                FILE_TO_BIG, 
                "A file you entered is too big.", 
                (values) => values.length? checkIfFilesAreTooBig(values) : true
            )
            .test(
                INCORRECT_FILE,
                "Only png, jpeg, and pdfs are accepted.",
                (values) => values.length? checkIfFilesAreCorrectType(values) : true
            ),
        how_you_found_us: yup.string()
            .max(
                HOW_YOU_FOUND_US_MAX,
                "Value entered is too long."
            ),
        message: yup.string()
            .max(
                MESSAGE_MAX,
                `Message is too long. ${MESSAGE_MAX} characters are only allowed.`
            )
    });
};

export {
    LeaveMessageFormSchema,
    StartAProjectFormSchema
};
