import React from "react";
import { HashLink } from "react-router-hash-link";
import { Split } from '../../../components/Parts/Split';




const CallToAction = ({ 
  msg1="Let's Talk", 
  msg2="About Your", 
  hlMsg="Next Project.", 
  btnTxt="Get In Touch", 
  btnLink="/contact", 
  backgroundImage, 
  theme, 
  invert
}) => {
    const getTheme = `${
      invert? 
      (theme === "light"? "dark": theme)
      : 
      theme
    }` ;

    // black
    const colorOverlay = `30, 30, 150`;

    return (
      <section
        className="call-action section-padding sub-bg bg-img"
        style={{  
          backgroundImage: `url(${backgroundImage ? backgroundImage : "assets/img/patrn.svg"})`,
          boxShadow: `inset 0 0 0 2000px rgba(${colorOverlay}, 0.45)`
        }}
        data-bs-theme={getTheme}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-9">
              <div className="content sm-mb30">
                <Split>
                  <h6 className="wow words chars splitting" data-splitting>
                    { msg1 }
                  </h6>
                  <h2 className="wow words chars splitting" data-splitting>
                    {msg2} <br /> <b className="back-color">{hlMsg}</b>
                  </h2>
                </Split>
              </div>
            </div>

            <div className="col-md-4 col-lg-3 valign">
                <HashLink
                  to={btnLink}
                  className="butn bord normal curve wow fadeInUp"
                  data-wow-delay=".5s"
                  data-bs-theme={getTheme}
                >
                  <span>{ btnTxt }</span>
                </HashLink>
            </div>
            
            {/* <MDNCharMandala theme={evalTheme}/> */}
          </div>
        </div>
      </section>
    );
}

export default CallToAction;
