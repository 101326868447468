import React from "react";
import { returnMediaMeta } from "../../../../utils/Schemas/returnMediaMeta";
import { FullScreenPopUp } from "../../../components/Parts/Popups/Custom/full-page-popup";
import { DetailsHero } from "../Headers/details-hero-container";
import { TextSection1 } from "../../../components/Parts/TextSections/text-section-1-component";




const WorksPopup = ({ data, theme, ...props }) => {
    // Content section
    const content = returnMediaMeta(data);

    return (
        <FullScreenPopUp 
            theme={theme}
            elements={[ 
                <DetailsHero headerData={content} />,
                <TextSection1 textData={content.description} num={1} />
            ]} 
        />
    );
};

export { WorksPopup };
