import React from "react";
import "./styles/global.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/dark.css";
import "./styles/light.css";
// Moble styles
import "./styles/mobile-app-dark.css";
import "./styles/mobile-app-light.css";
// JS
import "bootstrap/dist/js/bootstrap.bundle";
import reportWebVitals from "./reportWebVitals";
import {createRoot} from "react-dom/client";
import {Chart, registerables} from "chart.js";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
// Apps
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import { AppRoutes } from './app/routing/AppRoutes'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */




if (process.env.NODE_ENV !== "production") {
  console.log("In development mode 👨🏽‍💻!");
} else {
  console.log("Welcome to DesignBites!");
};

Chart.register(...registerables);

const queryClient = new QueryClient();
const container = document.getElementById("render-container-id");

if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
        <AppRoutes />
        { process.env.NODE_ENV !== "production"? <ReactQueryDevtools initialIsOpen={false}/> : null } 
    </QueryClientProvider>
  )
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
