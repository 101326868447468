import React from "react";
import { Title } from "../../../components/Parts/TextSections/title-component";
import { PaginatedDisplayComponent } from "../../../components/Displays/paginated-display-component";



const WorksPaginated = ({ 
    title, 
    subtitle,
    content,
    CardsClassName,
    CardComponent,
    PopUpComponent,
    searchOption=true,
    filter=null, 
    skeletonLoader=true, 
    itemsPerPage=4, 
    gridSize=2, 
    filterPosition="center",  
    theme 
  }) => {
  
    return (
      <section className="portfolio-frl section-padding pb-70">
        <div className="container">
          {
            title || subtitle ?
              <Title title={title} subtitle={subtitle} />
            :
              null
          }
        </div>
        { 
          <PaginatedDisplayComponent
            content={content}
            theme={theme}
            ContainerColSize={"container"}
            skeletonLoader={skeletonLoader}
            CardComponent={{
              jsx: CardComponent,
              CardsClassName: CardsClassName,
              gridSize: gridSize
            }}
            PopUpComponent={{
              jsx: PopUpComponent?.jsx?? null
            }}
            searchOption={searchOption}
            FilterComponent={{
              filters: filter?.filterTags,
              filterPosition: filter?.filterPosition,
              filterByKey: filter?.filterByKey
            }}
            PageComponent={{
              itemsPerPage: itemsPerPage
            }}
          />
        }
      </section>
    );
};

export default WorksPaginated;