import React from "react";

const SVG_LOGO = ({ theme, darkColor, lightColor, link }) => {
  const themeValue =
    theme === "light" ? lightColor ?? "#fff" : darkColor ?? "#fff";
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 2805 396"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      {/*
        <g id="SiteLogo_Full__DesignBytes">
          <g id="SiteLogo_Full__Design">
            <g id="SiteLogo_Full__D">
              <g id="SiteLogo_Full__Calligraphy-pen">
                <path
                  id="SiteLogo_Full__pen-tip"
                  d="M36.728,323.569l-9.298,-31.614l18.595,-0l-9.297,31.614Z"
                  style={{ fill: themeValue, fillRule: "nonzero" }}
                />
                <path
                  id="SiteLogo_Full__pen-tip-holder"
                  d="M47.408,287.252l-21.361,-0l-19.748,-67.147l60.857,-0l-19.748,67.147Zm-6.235,-8.333l-8.89,-0c-0,-0 -14.847,-50.481 -14.847,-50.481c0,0 38.583,0 38.583,0l-14.846,50.481Z"
                  style={{ fill: themeValue }}
                />
                <path
                  id="SiteLogo_Full__pen-base"
                  d="M72.542,208.609l-71.628,-0l-0,11.496l71.628,-0l-0,-11.496Zm-0,-13.064l-71.628,0l-0,8.361l71.628,-0l-0,-8.361Zm-0,-13.064l-71.628,0l-0,8.361l71.628,0l-0,-8.361Z"
                  style={{ fill: themeValue, fillRule: "nonzero" }}
                />
                <g id="SiteLogo_Full__pen-shaft" style={{ fill: themeValue, fillRule: "nonzero" }}>
                  <rect x="0.914" y="26.24" width="71.628" height="152.061" />
                  <path d="M72.542,26.24l-71.628,0l-0,152.061l71.628,0l-0,-152.061Zm-8.334,8.334l0,135.394c0,-0 -54.961,-0 -54.961,-0c0,-0 0,-135.394 0,-135.394l54.961,-0Z" />
                </g>
              </g>
              <path
                id="SiteLogo_Full__D-part"
                d="M0.914,26.24l128.728,0c29.909,0 55.128,6.254 75.657,18.762c20.529,12.508 36.096,29.909 46.7,52.206c10.604,22.296 15.906,48.263 15.906,77.901c0,29.365 -5.302,55.196 -15.906,77.492c-10.604,22.297 -26.171,39.699 -46.7,52.206c-4.881,2.975 -10.028,5.595 -15.444,7.863c-19.152,7.102 -54.158,12.73 -103.341,-8.832c-21.993,-9.642 -50.12,19.731 -50.12,19.731c0,-0 28.608,-54.855 73.86,-57.995c16.794,-1.165 30.052,-4.608 40.51,-9.252c1.831,-0.638 3.628,-1.334 5.388,-2.089c10.468,-4.486 19.805,-15.932 25.406,-23.397c4.584,-6.107 8.204,-21.389 8.204,-21.389l-0,0c2.337,-10.13 3.506,-22.12 3.506,-35.97c-0,-22.296 -3.263,-39.63 -9.789,-52.002c-6.526,-12.371 -15.499,-21.004 -26.919,-25.899c-11.42,-4.894 -24.471,-7.341 -39.154,-7.341l-116.492,-0l-0,-61.995Z"
                style={{ fill: themeValue, fillRule: "nonzero" }}
              />
            </g>
            <path
              id="SiteLogo_Full__e"
              d="M405.761,327.647c18.762,0 35.62,-3.127 50.575,-9.38c14.955,-6.254 27.054,-14.955 36.299,-26.103c9.245,-11.148 14.683,-24.2 16.314,-39.155l-64.849,0c-1.903,6.798 -6.322,12.304 -13.255,16.519c-6.934,4.214 -15.295,6.321 -25.084,6.321c-13.323,0 -24.063,-4.01 -32.22,-12.031c-8.158,-8.022 -12.1,-19.51 -11.828,-34.464l148.46,-0c2.719,-25.016 0.476,-47.312 -6.73,-66.889c-7.205,-19.577 -19.237,-35.008 -36.095,-46.292c-16.858,-11.284 -38.339,-16.926 -64.442,-16.926c-21.752,-0 -40.853,4.69 -57.304,14.071c-16.45,9.381 -29.161,22.704 -38.134,39.97c-8.973,17.266 -13.46,37.863 -13.46,61.791c0,22.568 4.419,42.281 13.256,59.139c8.837,16.858 21.616,29.977 38.338,39.358c16.723,9.381 36.776,14.071 60.159,14.071Zm-2.855,-177.01c11.149,0 20.393,3.059 27.735,9.177c7.341,6.118 11.284,15.159 11.828,27.122l-80.348,0c1.359,-11.148 5.642,-19.985 12.847,-26.51c7.206,-6.526 16.518,-9.789 27.938,-9.789Z"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
            <path
              id="SiteLogo_Full__s"
              d="M637.832,327.647c32.357,0 57.712,-5.71 76.066,-17.13c18.354,-11.42 27.53,-29.637 27.53,-54.653c0,-13.867 -2.447,-25.083 -7.341,-33.648c-4.894,-8.565 -11.556,-15.227 -19.985,-19.985c-8.429,-4.758 -18.014,-8.361 -28.754,-10.808c-10.74,-2.447 -21.821,-4.351 -33.241,-5.71c-14.682,-1.904 -25.423,-4.283 -32.22,-7.138c-6.798,-2.855 -10.197,-7.817 -10.197,-14.886c0,-5.439 2.379,-9.857 7.138,-13.256c4.758,-3.399 11.896,-5.098 21.412,-5.098c9.517,-0 16.994,2.107 22.432,6.322c5.439,4.214 8.294,9.992 8.566,17.334l67.296,-0c-0.544,-22.025 -9.449,-39.155 -26.715,-51.39c-17.266,-12.236 -40.446,-18.354 -69.539,-18.354c-29.638,-0 -53.634,5.914 -71.987,17.742c-18.354,11.828 -27.531,29.705 -27.531,53.633c0,16.858 4.079,29.842 12.236,38.95c8.157,9.109 19.033,15.703 32.629,19.782c13.595,4.078 28.414,7.341 44.456,9.788c14.411,1.904 24.947,4.079 31.609,6.526c6.662,2.447 9.993,8.021 9.993,16.722c-0,5.982 -2.651,10.672 -7.954,14.071c-5.302,3.399 -13.391,5.098 -24.267,5.098c-10.061,0 -18.558,-2.107 -25.491,-6.321c-6.934,-4.215 -10.537,-11.352 -10.808,-21.413l-68.113,0c-0.272,22.024 8.565,39.834 26.511,53.429c17.946,13.596 43.369,20.393 76.269,20.393Z"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
            <path
              id="SiteLogo_Full__i"
              d="M777.728,77.631l68.928,-0l-0,-51.391l-68.928,0l-0,51.391Zm-0,245.938l68.928,-0l-0,-220.243l-68.928,-0l-0,220.243Z"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
            <path
              id="SiteLogo_Full__g"
              d="M989.406,395.76c23.112,-0 43.233,-3.195 60.363,-9.585c17.13,-6.39 30.453,-16.994 39.97,-31.813c9.517,-14.819 14.275,-34.872 14.275,-60.159l-0,-190.877l-66.481,-0l-2.447,32.628l-3.671,0c-4.35,-11.148 -12.168,-20.053 -23.452,-26.715c-11.284,-6.661 -24.539,-9.992 -39.766,-9.992c-25.831,-0 -46.632,8.701 -62.402,26.103c-15.771,17.402 -23.656,43.777 -23.656,79.124c0,35.348 7.885,61.791 23.656,79.329c15.77,17.537 36.435,26.306 61.994,26.306c15.227,0 28.55,-3.398 39.97,-10.196c11.42,-6.798 19.306,-15.771 23.656,-26.919l3.671,0l-0,34.668c-0,12.508 -3.195,22.229 -9.585,29.162c-6.39,6.934 -17.742,10.4 -34.056,10.4c-10.06,0 -18.082,-1.903 -24.063,-5.71c-5.982,-3.806 -9.517,-8.7 -10.605,-14.682l-66.481,-0c1.36,20.664 10.673,37.319 27.939,49.962c17.266,12.644 40.989,18.966 71.171,18.966Zm4.486,-137.856c-12.507,-0 -22.568,-4.283 -30.181,-12.848c-7.614,-8.565 -11.42,-22.092 -11.42,-40.582c-0,-18.217 3.806,-31.609 11.42,-40.174c7.613,-8.565 17.674,-12.847 30.181,-12.847c13.596,-0 23.656,4.078 30.182,12.236c6.525,8.157 10.196,17.401 11.012,27.734l-0,26.103c-0.544,10.332 -4.147,19.645 -10.808,27.938c-6.662,8.293 -16.79,12.44 -30.386,12.44Z"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
            <path
              id="SiteLogo_Full__n"
              d="M1149.69,323.569l68.928,-0l-0,-123.581c-0,-11.42 3.603,-21.753 10.808,-30.997c7.206,-9.245 17.742,-13.868 31.609,-13.868c13.052,0 22.432,4.079 28.142,12.236c5.71,8.157 8.565,20.665 8.565,37.523l0,118.687l68.928,-0l0,-133.778c0,-22.024 -3.399,-39.63 -10.196,-52.817c-6.798,-13.188 -16.043,-22.772 -27.735,-28.754c-11.691,-5.982 -24.879,-8.973 -39.562,-8.973c-14.955,-0 -28.55,3.535 -40.786,10.604c-12.235,7.07 -21.072,17.538 -26.51,31.405l-3.263,0l-2.447,-37.93l-66.481,-0l-0,220.243Z"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
          </g>
          <g id="SiteLogo_Full__Bytes">
            <path
              id="SiteLogo_Full__l-square-bracket"
              d="M1418.47,391.681l115.832,-0l-0,-51.798l-51.39,0l-0,-287.54l51.39,0l-0,-52.205l-115.832,-0l0,391.543Z"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
            <path
              id="SiteLogo_Full__B"
              d="M1568.16,323.569l141.119,-0c34.804,-0 61.654,-7.07 80.552,-21.209c18.897,-14.139 28.346,-35.619 28.346,-64.441c-0,-16.859 -4.351,-31.473 -13.052,-43.845c-8.701,-12.372 -21.48,-20.325 -38.338,-23.86l-0,-3.263c13.595,-4.078 24.199,-12.031 31.813,-23.859c7.613,-11.828 11.42,-25.22 11.42,-40.174c-0,-23.112 -7.546,-41.67 -22.637,-55.673c-15.09,-14.003 -37.998,-21.005 -68.724,-21.005l-150.499,0l-0,297.329Zm70.967,-178.642l0,-63.218l58.732,0c11.964,0 21.412,2.515 28.346,7.545c6.933,5.031 10.4,12.848 10.4,23.452c0,9.517 -2.923,17.266 -8.769,23.248c-5.846,5.982 -15.294,8.973 -28.346,8.973l-60.363,0Zm0,123.173l0,-69.744l63.626,0c12.508,0 22.5,2.787 29.978,8.361c7.477,5.575 11.216,14.479 11.216,26.715c-0,10.333 -3.399,18.694 -10.197,25.083c-6.797,6.39 -17.81,9.585 -33.036,9.585l-61.587,0Z"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
            <path
              id="SiteLogo_Full__y"
              d="M1845.91,391.681l40.378,-0c13.323,-0 25.423,-1.699 36.299,-5.098c10.877,-3.399 20.597,-9.653 29.162,-18.762c8.565,-9.109 15.703,-21.956 21.413,-38.542l82.795,-225.953l-73.822,-0l-33.037,110.529l-5.302,22.024l-2.039,0l-6.118,-22.024l-35.076,-110.529l-75.862,-0l83.203,202.705l-2.855,8.157c-2.719,7.341 -6.593,12.712 -11.623,16.11c-5.031,3.399 -11.896,5.099 -20.597,5.099l-26.919,-0l0,56.284Z"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
            <path
              id="SiteLogo_Full__t"
              d="M2157.51,323.569l50.167,-0l-0,-55.469l-25.695,0c-5.166,0 -9.245,-1.02 -12.236,-3.059c-2.991,-2.039 -4.486,-6.05 -4.486,-12.032l-0,-94.215l42.417,0l-0,-55.468l-42.417,-0l-0,-64.442l-68.928,6.118l-0,58.324l-37.931,-0l-0,55.468l37.931,0l-0,106.859c-0,11.692 1.699,21.888 5.098,30.589c3.399,8.701 9.517,15.431 18.354,20.189c8.836,4.759 21.412,7.138 37.726,7.138Z"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
            <path
              id="SiteLogo_Full__e1"
              serifid="e"
              d="M2336.15,327.647c18.762,0 35.62,-3.127 50.574,-9.38c14.955,-6.254 27.055,-14.955 36.3,-26.103c9.245,-11.148 14.683,-24.2 16.314,-39.155l-64.849,0c-1.904,6.798 -6.322,12.304 -13.256,16.519c-6.933,4.214 -15.294,6.321 -25.083,6.321c-13.323,0 -24.064,-4.01 -32.221,-12.031c-8.157,-8.022 -12.099,-19.51 -11.828,-34.464l148.461,-0c2.719,-25.016 0.475,-47.312 -6.73,-66.889c-7.205,-19.577 -19.237,-35.008 -36.095,-46.292c-16.859,-11.284 -38.339,-16.926 -64.442,-16.926c-21.752,-0 -40.854,4.69 -57.304,14.071c-16.45,9.381 -29.162,22.704 -38.135,39.97c-8.973,17.266 -13.459,37.863 -13.459,61.791c0,22.568 4.419,42.281 13.255,59.139c8.837,16.858 21.617,29.977 38.339,39.358c16.722,9.381 36.775,14.071 60.159,14.071Zm-2.855,-177.01c11.148,0 20.393,3.059 27.734,9.177c7.342,6.118 11.284,15.159 11.828,27.122l-80.348,0c1.36,-11.148 5.642,-19.985 12.848,-26.51c7.205,-6.526 16.518,-9.789 27.938,-9.789Z"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
            <path
              id="SiteLogo_Full__s1"
              serifid="s"
              d="M2568.22,327.647c32.357,0 57.712,-5.71 76.066,-17.13c18.353,-11.42 27.53,-29.637 27.53,-54.653c-0,-13.867 -2.447,-25.083 -7.341,-33.648c-4.895,-8.565 -11.556,-15.227 -19.985,-19.985c-8.43,-4.758 -18.014,-8.361 -28.754,-10.808c-10.741,-2.447 -21.821,-4.351 -33.241,-5.71c-14.683,-1.904 -25.423,-4.283 -32.221,-7.138c-6.797,-2.855 -10.196,-7.817 -10.196,-14.886c-0,-5.439 2.379,-9.857 7.137,-13.256c4.759,-3.399 11.896,-5.098 21.413,-5.098c9.517,-0 16.994,2.107 22.432,6.322c5.438,4.214 8.293,9.992 8.565,17.334l67.297,-0c-0.544,-22.025 -9.449,-39.155 -26.715,-51.39c-17.266,-12.236 -40.446,-18.354 -69.54,-18.354c-29.637,-0 -53.633,5.914 -71.987,17.742c-18.353,11.828 -27.53,29.705 -27.53,53.633c-0,16.858 4.079,29.842 12.236,38.95c8.157,9.109 19.033,15.703 32.628,19.782c13.596,4.078 28.414,7.341 44.457,9.788c14.411,1.904 24.947,4.079 31.609,6.526c6.661,2.447 9.992,8.021 9.992,16.722c0,5.982 -2.651,10.672 -7.953,14.071c-5.302,3.399 -13.391,5.098 -24.267,5.098c-10.061,0 -18.558,-2.107 -25.492,-6.321c-6.933,-4.215 -10.536,-11.352 -10.808,-21.413l-68.112,0c-0.272,22.024 8.565,39.834 26.511,53.429c17.945,13.596 43.369,20.393 76.269,20.393Z"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
            <path
              id="SiteLogo_Full__r-square-bracket"
              d="M2803.97,0.138l-115.832,-0l0,51.797l51.39,0l0,287.54l-51.39,0l0,52.206l115.832,-0l-0,-391.543Z"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
          </g>
        </g>
        */}
      <g id="DesignBytes">
        <g id="Design">
          <g id="D">
            <g id="pixel-pencil">
              <path
                id="pencil-point"
                d="M268.905,174.905l-67.092,49.554l-0,-99.109l67.092,49.555Z"
                style={{ fill: themeValue, fillRule: "nonzero" }}
              />
              <g id="pencil-shaved">
                <path
                  id="triangle-8"
                  d="M134.72,174.905l67.093,-49.555l-0,99.109l-67.093,-49.554Z"
                  style={{ fill: "#e8a356", fillRule: "nonzero" }}
                />
                <path
                  id="triangle-7"
                  d="M201.813,125.35l-67.093,49.555l0,-99.11l67.093,49.555Z"
                  style={{ fill: "#f3b781", fillRule: "nonzero" }}
                />
                <path
                  id="triangle-6"
                  d="M201.813,224.459l-67.093,49.555l0,-99.109l67.093,49.554Z"
                  style={{ fill: "#f3b781", fillRule: "nonzero" }}
                />
                <path
                  id="triangle-5"
                  d="M67.628,125.35l67.092,-49.555l0,99.11l-67.092,-49.555Z"
                  style={{ fill: "#f4c5a9", fillRule: "nonzero" }}
                />
                <path
                  id="triangle-4"
                  d="M67.628,224.459l67.092,-49.554l0,99.109l-67.092,-49.555Z"
                  style={{ fill: "#f4c5a9", fillRule: "nonzero" }}
                />
                <path
                  id="triangle-3"
                  d="M134.72,274.014l-67.092,49.555l-0,-99.11l67.092,49.555Z"
                  style={{ fill: "#edb44b", fillRule: "nonzero" }}
                />
                <path
                  id="triangle-2"
                  d="M134.72,174.905l-67.092,49.554l-0,-99.109l67.092,49.555Z"
                  style={{ fill: "#edb44b", fillRule: "nonzero" }}
                />
                <path
                  id="triangle-1"
                  d="M134.72,75.795l-67.092,49.555l-0,-99.11l67.092,49.555Z"
                  style={{ fill: "#edb44b", fillRule: "nonzero" }}
                />
              </g>
              <g id="pencil-base">
                <path
                  id="rec-3"
                  d="M0.535,224.459l0,99.11l67.093,-0l-0,-99.11l-67.093,0Z"
                  style={{ fill: "#edb44b", fillRule: "nonzero" }}
                />
                <path
                  id="rec-2"
                  d="M0.535,125.35l0,99.109l67.093,0l-0,-99.109l-67.093,-0Z"
                  style={{ fill: "#edb44b", fillRule: "nonzero" }}
                />
                <path
                  id="rec-1"
                  d="M0.535,26.24l0,99.11l67.093,-0l-0,-99.11l-67.093,0Z"
                  style={{ fill: "#edb44b", fillRule: "nonzero" }}
                />
              </g>
            </g>
          </g>
          <path
            id="e"
            d="M406.761,327.647c18.762,0 35.62,-3.127 50.575,-9.38c14.955,-6.254 27.054,-14.955 36.299,-26.103c9.245,-11.148 14.683,-24.2 16.314,-39.155l-64.849,0c-1.903,6.798 -6.322,12.304 -13.255,16.519c-6.934,4.214 -15.295,6.321 -25.084,6.321c-13.323,0 -24.063,-4.01 -32.22,-12.031c-8.158,-8.022 -12.1,-19.51 -11.828,-34.464l148.46,-0c2.719,-25.016 0.476,-47.312 -6.73,-66.889c-7.205,-19.577 -19.237,-35.008 -36.095,-46.292c-16.858,-11.284 -38.339,-16.926 -64.442,-16.926c-21.752,-0 -40.853,4.69 -57.304,14.071c-16.45,9.381 -29.161,22.704 -38.134,39.97c-8.973,17.266 -13.46,37.863 -13.46,61.791c0,22.568 4.419,42.281 13.256,59.139c8.837,16.858 21.616,29.977 38.338,39.358c16.723,9.381 36.776,14.071 60.159,14.071Zm-2.855,-177.01c11.149,0 20.393,3.059 27.735,9.177c7.341,6.118 11.284,15.159 11.828,27.122l-80.348,0c1.359,-11.148 5.642,-19.985 12.847,-26.51c7.206,-6.526 16.518,-9.789 27.938,-9.789Z"
            style={{ fill: themeValue, fillRule: "nonzero" }}
          />
          <path
            id="s"
            d="M638.832,327.647c32.357,0 57.712,-5.71 76.066,-17.13c18.354,-11.42 27.53,-29.637 27.53,-54.653c0,-13.867 -2.447,-25.083 -7.341,-33.648c-4.894,-8.565 -11.556,-15.227 -19.985,-19.985c-8.429,-4.758 -18.014,-8.361 -28.754,-10.808c-10.74,-2.447 -21.821,-4.351 -33.241,-5.71c-14.682,-1.904 -25.423,-4.283 -32.22,-7.138c-6.798,-2.855 -10.197,-7.817 -10.197,-14.886c0,-5.439 2.379,-9.857 7.138,-13.256c4.758,-3.399 11.896,-5.098 21.412,-5.098c9.517,-0 16.994,2.107 22.432,6.322c5.439,4.214 8.294,9.992 8.566,17.334l67.296,-0c-0.544,-22.025 -9.449,-39.155 -26.715,-51.39c-17.266,-12.236 -40.446,-18.354 -69.539,-18.354c-29.638,-0 -53.634,5.914 -71.987,17.742c-18.354,11.828 -27.531,29.705 -27.531,53.633c0,16.858 4.079,29.842 12.236,38.95c8.157,9.109 19.033,15.703 32.629,19.782c13.595,4.078 28.414,7.341 44.456,9.788c14.411,1.904 24.947,4.079 31.609,6.526c6.662,2.447 9.993,8.021 9.993,16.722c-0,5.982 -2.651,10.672 -7.954,14.071c-5.302,3.399 -13.391,5.098 -24.267,5.098c-10.061,0 -18.558,-2.107 -25.491,-6.321c-6.934,-4.215 -10.537,-11.352 -10.808,-21.413l-68.113,0c-0.272,22.024 8.565,39.834 26.511,53.429c17.946,13.596 43.369,20.393 76.269,20.393Z"
            style={{ fill: themeValue, fillRule: "nonzero" }}
          />
          <g id="i">
            <rect
              id="i-base"
              x="778.728"
              y="103.326"
              width="68.928"
              height="220.243"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
            <rect
              id="i-dot"
              x="778.728"
              y="26.24"
              width="68.928"
              height="51.39"
              style={{ fill: themeValue, fillRule: "nonzero" }}
            />
          </g>
          <path
            id="g"
            d="M990.406,395.76c23.112,-0 43.233,-3.195 60.363,-9.585c17.13,-6.39 30.453,-16.994 39.97,-31.813c9.517,-14.819 14.275,-34.872 14.275,-60.159l-0,-190.877l-66.481,-0l-2.447,32.628l-3.671,0c-4.35,-11.148 -12.168,-20.053 -23.452,-26.715c-11.284,-6.661 -24.539,-9.992 -39.766,-9.992c-25.831,-0 -46.632,8.701 -62.402,26.103c-15.771,17.402 -23.656,43.777 -23.656,79.124c0,35.348 7.885,61.791 23.656,79.329c15.77,17.537 36.435,26.306 61.994,26.306c15.227,0 28.55,-3.398 39.97,-10.196c11.42,-6.798 19.306,-15.771 23.656,-26.919l3.671,0l-0,34.668c-0,12.508 -3.195,22.229 -9.585,29.162c-6.39,6.934 -17.742,10.4 -34.056,10.4c-10.06,0 -18.082,-1.903 -24.063,-5.71c-5.982,-3.806 -9.517,-8.7 -10.605,-14.682l-66.481,-0c1.36,20.664 10.673,37.319 27.939,49.962c17.266,12.644 40.989,18.966 71.171,18.966Zm4.486,-137.856c-12.507,-0 -22.568,-4.283 -30.181,-12.848c-7.614,-8.565 -11.42,-22.092 -11.42,-40.582c-0,-18.217 3.806,-31.609 11.42,-40.174c7.613,-8.565 17.674,-12.847 30.181,-12.847c13.596,-0 23.656,4.078 30.182,12.236c6.525,8.157 10.196,17.401 11.012,27.734l-0,26.103c-0.544,10.332 -4.147,19.645 -10.808,27.938c-6.662,8.293 -16.79,12.44 -30.386,12.44Z"
            style={{ fill: themeValue, fillRule: "nonzero" }}
          />
          <path
            id="n"
            d="M1150.69,323.569l68.928,-0l-0,-123.581c-0,-11.42 3.603,-21.753 10.808,-30.997c7.206,-9.245 17.742,-13.868 31.609,-13.868c13.052,0 22.432,4.079 28.142,12.236c5.71,8.157 8.565,20.665 8.565,37.523l0,118.687l68.928,-0l0,-133.778c0,-22.024 -3.399,-39.63 -10.196,-52.817c-6.798,-13.188 -16.043,-22.772 -27.735,-28.754c-11.691,-5.982 -24.879,-8.973 -39.562,-8.973c-14.955,-0 -28.55,3.535 -40.786,10.604c-12.235,7.07 -21.072,17.538 -26.51,31.405l-3.263,0l-2.447,-37.93l-66.481,-0l-0,220.243Z"
            style={{ fill: themeValue, fillRule: "nonzero" }}
          />
        </g>
        <g id="Bytes">
          <path
            id="l-square-bracket"
            d="M1419.47,391.681l115.832,-0l-0,-51.798l-51.39,0l-0,-287.54l51.39,0l-0,-52.205l-115.832,-0l0,391.543Z"
            style={{ fill: themeValue, fillRule: "nonzero" }}
          />
          <path
            id="B"
            d="M1569.16,323.569l141.119,-0c34.804,-0 61.654,-7.07 80.552,-21.209c18.897,-14.139 28.346,-35.619 28.346,-64.441c-0,-16.859 -4.351,-31.473 -13.052,-43.845c-8.701,-12.372 -21.48,-20.325 -38.338,-23.86l-0,-3.263c13.595,-4.078 24.199,-12.031 31.813,-23.859c7.613,-11.828 11.42,-25.22 11.42,-40.174c-0,-23.112 -7.546,-41.67 -22.637,-55.673c-15.09,-14.003 -37.998,-21.005 -68.724,-21.005l-150.499,0l-0,297.329Zm70.967,-178.642l0,-63.218l58.732,0c11.964,0 21.412,2.515 28.346,7.545c6.933,5.031 10.4,12.848 10.4,23.452c0,9.517 -2.923,17.266 -8.769,23.248c-5.846,5.982 -15.294,8.973 -28.346,8.973l-60.363,0Zm0,123.173l0,-69.744l63.626,0c12.508,0 22.5,2.787 29.978,8.361c7.477,5.575 11.216,14.479 11.216,26.715c-0,10.333 -3.399,18.694 -10.197,25.083c-6.797,6.39 -17.81,9.585 -33.036,9.585l-61.587,0Z"
            style={{ fill: themeValue, fillRule: "nonzero" }}
          />
          <path
            id="y"
            d="M1846.91,391.681l40.378,-0c13.323,-0 25.423,-1.699 36.299,-5.098c10.877,-3.399 20.597,-9.653 29.162,-18.762c8.565,-9.109 15.703,-21.956 21.413,-38.542l82.795,-225.953l-73.822,-0l-33.037,110.529l-5.302,22.024l-2.039,0l-6.118,-22.024l-35.076,-110.529l-75.862,-0l83.203,202.705l-2.855,8.157c-2.719,7.341 -6.593,12.712 -11.623,16.11c-5.031,3.399 -11.896,5.099 -20.597,5.099l-26.919,-0l0,56.284Z"
            style={{ fill: themeValue, fillRule: "nonzero" }}
          />
          <path
            id="t"
            d="M2158.51,323.569l50.167,-0l-0,-55.469l-25.695,0c-5.166,0 -9.245,-1.02 -12.236,-3.059c-2.991,-2.039 -4.486,-6.05 -4.486,-12.032l-0,-94.215l42.417,0l-0,-55.468l-42.417,-0l-0,-64.442l-68.928,6.118l-0,58.324l-37.931,-0l-0,55.468l37.931,0l-0,106.859c-0,11.692 1.699,21.888 5.098,30.589c3.399,8.701 9.517,15.431 18.354,20.189c8.836,4.759 21.412,7.138 37.726,7.138Z"
            style={{ fill: themeValue, fillRule: "nonzero" }}
          />
          <path
            id="e1"
            serifid="e"
            d="M2337.15,327.647c18.762,0 35.62,-3.127 50.574,-9.38c14.955,-6.254 27.055,-14.955 36.3,-26.103c9.245,-11.148 14.683,-24.2 16.314,-39.155l-64.849,0c-1.904,6.798 -6.322,12.304 -13.256,16.519c-6.933,4.214 -15.294,6.321 -25.083,6.321c-13.323,0 -24.064,-4.01 -32.221,-12.031c-8.157,-8.022 -12.099,-19.51 -11.828,-34.464l148.461,-0c2.719,-25.016 0.475,-47.312 -6.73,-66.889c-7.205,-19.577 -19.237,-35.008 -36.095,-46.292c-16.859,-11.284 -38.339,-16.926 -64.442,-16.926c-21.752,-0 -40.854,4.69 -57.304,14.071c-16.45,9.381 -29.162,22.704 -38.135,39.97c-8.973,17.266 -13.459,37.863 -13.459,61.791c0,22.568 4.419,42.281 13.255,59.139c8.837,16.858 21.617,29.977 38.339,39.358c16.722,9.381 36.775,14.071 60.159,14.071Zm-2.855,-177.01c11.148,0 20.393,3.059 27.734,9.177c7.342,6.118 11.284,15.159 11.828,27.122l-80.348,0c1.36,-11.148 5.642,-19.985 12.848,-26.51c7.205,-6.526 16.518,-9.789 27.938,-9.789Z"
            style={{ fill: themeValue, fillRule: "nonzero" }}
          />
          <path
            id="s1"
            serifid="s"
            d="M2569.22,327.647c32.357,0 57.712,-5.71 76.066,-17.13c18.353,-11.42 27.53,-29.637 27.53,-54.653c-0,-13.867 -2.447,-25.083 -7.341,-33.648c-4.895,-8.565 -11.556,-15.227 -19.985,-19.985c-8.43,-4.758 -18.014,-8.361 -28.754,-10.808c-10.741,-2.447 -21.821,-4.351 -33.241,-5.71c-14.683,-1.904 -25.423,-4.283 -32.221,-7.138c-6.797,-2.855 -10.196,-7.817 -10.196,-14.886c-0,-5.439 2.379,-9.857 7.137,-13.256c4.759,-3.399 11.896,-5.098 21.413,-5.098c9.517,-0 16.994,2.107 22.432,6.322c5.438,4.214 8.293,9.992 8.565,17.334l67.297,-0c-0.544,-22.025 -9.449,-39.155 -26.715,-51.39c-17.266,-12.236 -40.446,-18.354 -69.54,-18.354c-29.637,-0 -53.633,5.914 -71.987,17.742c-18.353,11.828 -27.53,29.705 -27.53,53.633c-0,16.858 4.079,29.842 12.236,38.95c8.157,9.109 19.033,15.703 32.628,19.782c13.596,4.078 28.414,7.341 44.457,9.788c14.411,1.904 24.947,4.079 31.609,6.526c6.661,2.447 9.992,8.021 9.992,16.722c0,5.982 -2.651,10.672 -7.953,14.071c-5.302,3.399 -13.391,5.098 -24.267,5.098c-10.061,0 -18.558,-2.107 -25.492,-6.321c-6.933,-4.215 -10.536,-11.352 -10.808,-21.413l-68.112,0c-0.272,22.024 8.565,39.834 26.511,53.429c17.945,13.596 43.369,20.393 76.269,20.393Z"
            style={{ fill: themeValue, fillRule: "nonzero" }}
          />
          <path
            id="r-square-bracket"
            d="M2804.97,0.138l-115.832,-0l0,51.797l51.39,0l0,287.54l-51.39,0l0,52.206l115.832,-0l-0,-391.543Z"
            style={{ fill: themeValue, fillRule: "nonzero" }}
          />
        </g>
      </g>
    </svg>
  );
};

export default SVG_LOGO;
